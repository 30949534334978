<template>
<div>
  <TableLayout :withBreadcrumb="false">
 <!-- :permissions="['system:user:query']" -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="80px" inline>
      <el-form-item label="项目纪要" prop="name">
        <el-input v-model="searchForm.name" v-trim placeholder="请输入纪要资料名称" @keypress.enter.native="fetchListSummary"/>
      </el-form-item>
      <section>
        <el-button type="primary" icon="el-icon-search" @click="fetchListSummary">搜索</el-button>
      </section>
    </el-form>
    <template v-slot:table-wrap>
      <ul class="toolbar" v-if="projectBtns">
        <li >
          <FileUpload  v-model='upLoadFile' :max="1" :projectId="projectId" @input="fileChange($event,1)"></FileUpload>  
          <!-- 单文件上传 不然改逻辑 -->
        </li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="data"
        :default-sort = "{prop: 'createTime', order: 'descending'}"
        stripe
        border   
      >
        <el-table-column align="center" prop="name" label="纪要名称" min-width="100px" ></el-table-column>

        <el-table-column align="center" prop="size" label="大小" min-width="120px">
          <div  slot-scope="{row}">
            <span v-if="row.size / 1024 / 1024 >= 1">{{(row.size / 1024 / 1024).toFixed(2)+'M'}}</span>
            <span v-else>{{(row.size / 1024).toFixed(2)+'KB'}}</span>
          </div>
        </el-table-column>
        <el-table-column align="center" prop="operatorName" label="上传人" min-width="120px"></el-table-column>
        <el-table-column align="center" prop="operatorTime" label="上传时间" min-width="100px" ></el-table-column>
        <el-table-column align="center"
          label="操作"
          width="270"
          fixed="right"
        >
          <div  slot-scope="{row}">
            <el-button type="text"><a :href="row.path" download="">下载</a></el-button>
            <el-button v-if="row.isUpdate"  type="text"  @click="deleteEnclosureById(row.id)" >删除</el-button>
          </div>
        </el-table-column>
      </el-table>
    </template>
    <AddProjectDataType ref="addProjectDataType" @success="fetchListSummary"/>
  </TableLayout>


  <div style="margin-top:40px;"></div>

  <TableLayout :withBreadcrumb="false"> 
 <!-- :permissions="['system:user:query']" -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="80px" inline>
      <el-form-item label="项目照片" prop="name">
        <el-input v-model="searchForm.name" v-trim placeholder="请输入照片名称" @keypress.enter.native="fetchListPicture"/>
      </el-form-item>
      <section>
        <el-button type="primary" icon="el-icon-search" @click="fetchListPicture">搜索</el-button>
      </section>
    </el-form>
    <template v-slot:table-wrap>
      <ul class="toolbar" v-if="projectBtns ">
        <li >
          <FileUpload  v-model='upLoadPic' :max="1" :projectId="projectId" limitType='image/jpeg' @input="fileChange($event,2)"></FileUpload>  
          <!-- 单文件上传 不然改逻辑 -->
        </li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="dataPic"
        :default-sort = "{prop: 'createTime', order: 'descending'}"
        stripe
        border   
      >
        <el-table-column align="center" prop="name" label="图片名称" min-width="100px" >
          <div  slot-scope="{row}" >
            <span v-if="row.name">{{row.name}}</span>
          </div>
        </el-table-column>
        <el-table-column align="center" prop="path" label="缩略图" min-width="50px" >
          <div  slot-scope="{row}" style="display:flex;align-items:center;justify-content:center">
            <img v-if="row.path" :src="row.path" style="width:20px;height:20px;cursor:pointer" @click="dialogVisible=true;imgUrl=row.path" />
          </div>
        </el-table-column>

        <el-table-column align="center" prop="size" label="大小" min-width="120px">
          <div  slot-scope="{row}">
            <span v-if="row.size / 1024 / 1024 >= 1">{{(row.size / 1024 / 1024).toFixed(2)+'M'}}</span>
            <span v-else>{{(row.size / 1024).toFixed(2)+'KB'}}</span>
          </div>
        </el-table-column>
        <el-table-column align="center" prop="operatorName" label="上传人" min-width="120px"></el-table-column>
        <el-table-column align="center" prop="operatorTime" label="上传时间" min-width="100px" ></el-table-column>
        <el-table-column align="center"
          label="操作"
          width="270"
          fixed="right"
        >
          <div  slot-scope="{row}">
            <!-- :href="row.path" target="_blank" -->
            <!-- <el-popover
              placement="top-start"
              width="200"
              trigger="hover">
              <img :src="row.path" alt="" srcset="" style="width:1000px;height:1000px"> -->
              <el-button type="text" slot="reference"><a @click="dialogVisible=true;imgUrl=row.path">查看</a></el-button>
            <!-- </el-popover> -->
            
            <el-button v-if="row.isUpdate"  type="text"  @click="deletePictureById(row.id)" >删除</el-button>
          </div>
        </el-table-column>
      </el-table>
      <el-dialog
        title="查看图片"
        :visible.sync="dialogVisible"
        width="40%"
        :before-close="()=>{dialogVisible=false}">
         <img :src="imgUrl" alt="" srcset="" style="width:100%;height:100%">
      </el-dialog>
    </template>
    <AddProjectDataType ref="addProjectDataType" @success="fetchListSummary"/>
  </TableLayout>

</div>
  
</template>

<script>
import Pagination from '@/components/common/Pagination'
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import AddProjectDataType from '@/components/bussiness/projectAddModal/AddProjectDataType'
import FileUpload from '../../common/FileUpload'
import {fetchListSummary,deleteEnclosureById,saveEnclosure,savePicture,deletePictureById,fetchListPicture} from '../../../api/bussiness/summary'
// import {} from '../../api/bussiness/projectData'


export default {
  name: 'project_data',
  extends: BaseTable,
  components: { AddProjectDataType, TableLayout, Pagination, FileUpload , },
   props: {
     projectBtns:{
      type:Boolean,
      default:true
    }
  },
  data () {
    return {
        searchForm: {
            name:''
        },
        upLoadFile:[],//上传的文件
        uploadPic:[],//上传的图片
        activeItem:0,
        data:[],
        dataPic:[],
        projectId:this.$route.query.pid,
        dialogVisible:false,
        imgUrl:"",
    }
  },
  computed: {

  },
  watch:{
    upLoadFile:{
      handler(val){
        console.log('----6767');
        if(val.length>0){
          let obj =[];
          val.map(i=>{
            obj = Object.assign({
              ...i,
              projectId:Number(this.projectId)
            })
          })
          this.saveEnclosure(obj)
        }
      },
      immediate:true
    },
    upLoadPic:{
      handler(val){
        if(val.length>0){
          let obj =[];
          val.map(i=>{
            obj = Object.assign({
              ...i,
              projectId:Number(this.projectId)
            })
          })
          console.log(obj,'---tupian');
          this.savePicture(obj)
        }
      },
      immediate:true
    }
  },
  created(){
    this.fetchListSummary()
    this.fetchListPicture()

  },
  methods: {
    fileChange(val,type){
      console.log(val,'----------------',type);
      if(val.length>0){
        let obj =[];
        val.map(i=>{
          obj = Object.assign({
            ...i,
            projectId:Number(this.projectId)
          })
        })
        if(type==1){
          this.saveEnclosure(obj)
        }else{
          this.savePicture(obj)
        }
        
      }

    },
    fetchListSummary(){
      fetchListSummary({projectId:this.$route.query.pid,name:this.searchForm.name}).then(res=>{
        console.log(res,'---会议纪要');
        this.data = res;
      }).catch(e => {
        this.$tip.apiFailed(e)
      })
    },
    fetchListPicture(){
      fetchListPicture({projectId:this.$route.query.pid,name:this.searchForm.name}).then(res=>{
        this.dataPic = res;
      }).catch(e => {
        this.$tip.apiFailed(e)
      })
    },
    saveEnclosure(list){
      saveEnclosure(list).then(res=>{
        console.log('成功后删除啥啥啥');
        this.upLoadFile = []
        this.fetchListSummary();
      }).catch(e=>{
        this.$tip.apiFailed(e)
      }).finally(()=>{
        this.upLoadFile = []
      })
    },
    savePicture(list){
      savePicture(list).then(res=>{
        this.upLoadPic = []
        this.fetchListPicture();
      }).catch(e=>{
        this.$tip.apiFailed(e)
      }).finally(()=>{
        this.upLoadPic = []
      })
    },

    deleteEnclosureById(id){
      this.$dialog.confirm('确定删除当前的资料吗？', '提示', {
        confirmButtonText: '确认',
        type: 'warning'
      }).then(() => {
      deleteEnclosureById({
        minutesId:id,
      }).then(res=>{
            this.$tip.apiSuccess('删除成功')
            this.fetchListSummary()
          }).catch(e=>{
            this.$tip.apiFailed(e)
          }).finally(()=>{
          })
        })
    },
    deletePictureById(id){
      this.$dialog.confirm('确定删除当前的图片吗？', '提示', {
        confirmButtonText: '确认',
        type: 'warning'
      }).then(() => {
      deletePictureById({
        pictureId:id,
      }).then(res=>{
            this.$tip.apiSuccess('删除成功')
            this.fetchListPicture()
          }).catch(e=>{
            this.$tip.apiFailed(e)
          }).finally(()=>{
          })
        })
    },
  }
}
</script>

<style lang="scss">

</style>
<style scoped lang="scss">
.tabs{
    .tab_tab{
        display: flex;
        justify-content: space-around;
        .tabItem{
           font-weight: 400; 
           cursor: pointer;
        }
        .activeItem{
            font-weight: bold;
        }
    }
}
.sumInfo{
  color: #333333;
  font-size: 18px;
  font-family: 'Microsoft YaHei';
  font-weight: bold;
  /deep/ .el-timeline-item__tail{
    border-left: 2px dashed #999999;
  }
  /deep/ .el-timeline-item__timestamp{
    color: #333333 ;
    font-weight: 400;
    font-size: 18px !important;
  }
}


</style>
