import request from '@/utils/request'

// 获取图片验证码
export function getCaptcha () {
  return request.get('/common/captcha')
}

//上传图片到oss
export function uploadImage(data) {
  return request.post('/common/upload',data)
}

// 查询配置项
export function querySettings () {
  return request.get('/settings/querySettings')
}

// 修改配置项
export function saveSettings (data) {
  return request.post('/settings/saveSettings',data)
}

//更新项目单项信息 （项目进展，项目状态）
export function updateSingleInfo(data){
  return request.post('/business/project/updateSingleInfo',data)
}

export function download(data){
  console.log(data);
  if(data.type==1){
    //下载所有项目概览
    return request.get('/business/project/downloadAllOverview',{params:data,download:true})
  }else{
    //下载所有项目详情进度表
    return request.get('/business/project/downloadAllDetail',{params:data,download:true})
  }
}
