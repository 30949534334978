<template>
  <div class="wrap">
    <div class="ScaleBox"
         ref="ScaleBox"
         style=""
        >
    <div style="margin:20px 20px 0 10px;">
      <el-button type="primary"  @click="$router.push('/showBoard')">去投屏页</el-button>
    </div>

    <div class="fullScreen" @click="fullScreen">全屏</div>
      <bigScreen id="bigScreen" style="margin:auto 0;background:#eee;width:1920px;height:auto" ref="bigScreen"></bigScreen>
    </div>
  </div>
</template>

<script>
import bigScreen from './bigScreen.vue'
import screenfull from "screenfull";

export default {
  name: "ScaleBox",
  components:{bigScreen},
  props: {
    width: {
      type: Number,
      default: 1920
    },
    height: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      scale: null
    };
  },
  mounted() {

    this.setScale();
    window.addEventListener("resize", this.setScale);
  },
  methods: {
    getScale() {
      const { width, height } = this;
      
      let contentWidth = window.getComputedStyle(document.getElementsByClassName('wrap')[0]).width
      let contentHeight = window.getComputedStyle(document.getElementsByClassName('wrap')[0]).height;//0

      console.log('-sjdhus-',contentWidth,contentHeight,Number(contentWidth.slice(0, -2)));
      let ww = Number(contentWidth.slice(0, -2)) / width;
      let wh = Number(contentHeight.slice(0, -2)) / height;
      return ww 
    },
    setScale() {
      this.scale = this.getScale();
      this.$refs.ScaleBox.style.setProperty("--scale", this.scale);
    },
    debounce(fn, delay) {
      let delays = delay || 500;
      let timer;
      return function() {
        let th = this;
        let args = arguments;
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(function() {
          timer = null;
          fn.apply(th, args);
        }, delays);
      };
    },
    fullScreen(){
      // 判断是否支持
      // if (!screenfull.enabled) {
      //   this.$message({
      //     message: "不支持全屏",  
      //     type: "warning"
      //   });
      //   return false;
      // }
      screenfull.toggle();
    },
  }
};
</script>

<style lang="scss" scoped>
.fullScreen{
  position: absolute;
  top: 0%;
  right: 20px;
  // #e4e4e4
  border-radius: 50%;
  padding: 40px;
  z-index: 22222;
  cursor: pointer;
  opacity: 0;
  &:hover{
    opacity:1;
  }
}
#ScaleBox {
  --scale: 1;
}
.wrap {
  background: #eee;

  width: 100%;
  height: 100%;
}
.ScaleBox {
  background: #eee;
  // transform: scale(var(--scale)) translate(-50%, -50%);
  transform: scale(var(--scale)) translate(-50%, 0%);

  display: flex;
  height: 100%;
  flex-direction: column;
  transform-origin: 0 0;
  position: absolute;
  left: 50%;
  // top: 50%;
  transition: 0.3s;
  z-index: 999;
}
</style>