<template>

    <div class="page" style="width:1920px;height:100%;">
      <el-row :span="24" class="pageTitle">
        <h1 style="text-align:center;margin:20px 0">陕旅集团数字文旅项目管理平台</h1>
      </el-row>
    <el-row class="row1">
      <el-col class="sumChart" :span="14" >
        <el-row class="sum" >
          <div class="colTitle">项目总览</div>
          <div class="sumItem" @click="toDetail({id:null},null)"><span class="sum_title">项目总数</span>{{numVo.allNum}}</div>
          <div class="sumItem"@click="toDetail({id:null},3)"><span class="sum_title">已完成数</span>{{numVo.allCompleteNum}}</div>
        </el-row>
        <el-row class="sum_tabs" >
          <el-col  class="sum_tabItem" v-for='(item,key) in projectNumVoList' :key='key' >
            <div class="item" @click="toDetail(item,null)">
              <span class="title">{{item.content}}</span>
              <span class="number">{{item.allNum}}</span>
            </div>
            <div class="item" @click="toDetail(item,3)">
              <span class="title">已完成数</span>
              <span class="number">{{item.allCompleteNum}}</span>
            </div>
          </el-col>

        </el-row>
        <el-row></el-row>
      </el-col>
      <el-col :span="10">
        <el-row class="pieChart">
          <div class="colTitle">项目分布总览</div>
          <div class="pies" id="pieArea">
            <div id='pie1' class="pieBlock"></div>
            <div id='pie2' class="pieBlock" ></div>
            <div id='pie3' class="pieBlock" ></div>
            <div id='pie4' class="pieBlock" ></div>
          </div>
        </el-row>
      </el-col>
    </el-row>

    <el-row  class="row2">
      <el-col class="projectList" :span='14'>
        <div>
        <div class="colTitle" style="margin-bottom:20px">项目列表</div>
        <vue-seamless-scroll v-if="projectList" :data="projectList" :class-option="optionCustomerInner" style="overflow:hidden;padding-top:10px">
        <el-row class="listItem" v-for="(item,index) in projectList" >
          <div class="leftTitle">
            <div class="top">
                <span class="name" @click="toProjectDetail(item)">{{item.projectName}}</span>
                <span class="id" v-if="item.projectNo">({{item.projectNo}})</span>
                <span style="color:rgb(236, 122, 109);margin-left:20px;font-weight:bold">{{item.score?item.score:0}}分</span>
            </div>
          </div>
          <div class="other">
              <div class="left">

                <div class="leftContent">
                    <div class="left_left">
                      <div class="middle">

                          <span v-if="item.typeName">{{item.typeName}}</span>
                          <span >{{item.statusName?item.statusName:'有问题'}}</span>
                          <span  v-if="item.overdueName">{{item.overdueName}}</span>
                          <!-- <span  v-if="item.projectLevel">{{item.projectLevel}}级</span> -->
                      </div>
                      <div class="bottom">计划工期:{{item.planBegin}}~{{item.planEnd}}</div>
                      </div>
                      <div class="left_right">
                      <div>实际开始：{{item.actualBegin?item.actualBegin:'-'}}</div>
                      <div>实际结束：{{item.actualEnd?item.actualEnd:'-'}}</div>
                      </div>
                </div>

            </div>

            <div class="right">
              <div class="progressItem">
                <span>工期进度</span>
                <el-progress color="#EC7A6D" :percentage="item.projectSpeed?item.projectSpeed:0"></el-progress>
              </div>
              <div class="progressItem">
                <span>里程碑进度</span>
                <el-progress  color="#EC7A6D"  :percentage="item.milepostPercentage?item.milepostPercentage:0"></el-progress>
              </div>
              <!-- <div class="progressItem">
                <span>自评进度</span>
                <el-progress  color="#EC7A6D" :percentage="100"></el-progress>
              </div> -->
            </div>
            <div class="end">
              <img class="avatar" :src="item.avatar" alt="">
              <span class="name">{{item.directorName}}</span>
            </div>

          </div>

        </el-row>
        </vue-seamless-scroll>
        </div>

      </el-col>
      <el-col :span='10'>
        <el-row class="barContent">
          <div class="colTitle">项目状态总览</div>
          <div id="statusBar" class="barBlock"></div>
        </el-row>
        <el-row class="barContent">
          <div class="colTitle">项目问题总览</div>
          <div id="questionBar" class="barBlock"></div>
        </el-row>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import * as echarts from 'echarts'
import screenfull from 'screenfull'
import { mapState } from 'vuex'
import { showScreen } from '../../api/manage/index'
import { querySettings } from '@/api/manage/rules'

export default {
  name: '',
  data () {
    return {
      setOrigin: true,

      optionCustomer: {
        // step: 0.5,
        // step:this.$store.state.setting.rollingSpeed?this.$store.state.setting.rollingSpeed:0.5,
        step: 0.1,
        limitMoveNum: 4,
        openTouch: false,
        waitTime: 1,
        direction: 2
        // navigation:true
        // singleWidth: 30
      },
      optionCustomerInner: {
        step: 0.5,
        limitMoveNum: 4,
        openTouch: false,
        waitTime: 1

        // waitTime:$state.setting.rollingSpeed?$state.setting.rollingSpeed:1
        // direction: 2,
      },
      animate: false,
      scale: null,
      sumData: {}, //
      numVo: {}, // 总数
      projectNumVoList: [], // 总数 已完成数
      questinList: {}, // 问题 bar
      statusList: {}, // 状态 bar
      projectList: []
      // source:[
      //       ['状态', '已暂停', '进行中', '未启动','已完成','已取消'],
      //       ['数字化转型项目', 43.3, 85.8, 93.7,34,55],
      //       ['基建', 83.1, 73.4, 55.1,78,2],
      //       ['运营', 86.4, 65.2, 82.5,23,0],
      //       ['其它', 72.4, 53.9, 39.1,99,-10],
      //   ]
    }
  },
  computed: {
    ...mapState({
      originW: state => state.setting.originW,
      originH: state => state.setting.originH

    }),
    xxx () {
      if (this.originW && this.setOrigin) {
        this.setScale()
        this.setOrigin = false
      }
    }
  },
  mounted () {
    this.querySettings()
    this.showScreen()

    // this.loop(this.block4);

    // if (this.loading) return
    // this.setScale()
    // if (!document.addEventListener) return
    // window.addEventListener('resize', this.debounce(this.setScale, 500, true))
    // document.addEventListener('DOMContentLoaded', this.debounce(this.setScale, 500, true), false)
  },
  methods: {
    querySettings () {
      querySettings().then(res => {
        console.log(res, '--')
        if (res.projectionScreen) {
          res.projectionScreen.projectRollingSpeed && Object.assign(this.optionCustomerInner, {
            step: res.projectionScreen.projectRollingSpeed ? Number(res.projectionScreen.projectRollingSpeed) : 0.5
          })
        }
      })
    },

    toDetail (item, projectSatatus) {
      const projectType = item.id
      // if (this.sumData.details) { // 是否可点击跳转
        this.$router.push({ path: '/manage/projectBoard', query: { projectSatatus: projectSatatus, projectType: projectType } })
      // } else {
      //   return this.$message({
      //     message: '对不起，您无点击权限',
      //     type: 'warning'
      //   })
      // }
    },
    toProjectDetail(item){
      if(!item.details){
        return this.$message({
          message: '对不起，您无点击权限',
          type: 'warning'
        })
      }
      this.$router.push('/detail?pid='+item.id)
    },
    showScreen () {
      showScreen().then(res => {
        console.log(res, '结果数')
        this.sumData = res
        console.log(res.numVo)
        this.numVo = res.numVo
        this.projectNumVoList = res.projectNumVoList ? res.projectNumVoList : []
        const questin = {
          title: '项目问题总览',
          questionListArray: [['问题', '未解决', '解决中', '已解决']],
          colorList: [
            { type: 'bar', barMinHeight: 1, itemStyle: { color: '#EE6666' } },
            { type: 'bar', barMinHeight: 1, itemStyle: { color: '#5470C6' } },
            { type: 'bar', barMinHeight: 1, itemStyle: { color: '#73C0DE' } }
          ]
        }
        const status = {
          title: '项目状态总览',
          statusListArray: [['状态', '预启动', '运行中', '已暂停', '已完成', '已取消']],
          colorList: [
            { type: 'bar', barMinHeight: 1, itemStyle: { color: '#EE6666' } },
            { type: 'bar', barMinHeight: 1, itemStyle: { color: '#5470C6' } },
            { type: 'bar', barMinHeight: 1, itemStyle: { color: '#73C0DE' } },
            { type: 'bar', barMinHeight: 1, itemStyle: { color: '#FAC858' } },
            { type: 'bar', barMinHeight: 1, itemStyle: { color: '#999999' } }
          ]
        }
        res.questionVoList.map(i => {
          console.log('问题key-------------------------', Object.keys(i))
          const item = Object.values(i)
          item.shift()
          questin.questionListArray.push(item)
        })

        res.statusVoList.map(i => {
          console.log('状态key-------------------------', Object.keys(i))
          const item = Object.values(i)
          item.shift()
          status.statusListArray.push(item)
        })

        this.statusList = status
        this.questinList = questin
        // this.numNo = res.numNo?res.numNo:{}
        // this.numNo = res.numNo?res.numNo:{}
        // this.numNo = res.numNo?res.numNo:{}
        // this.numNo = res.numNo?res.numNo:{}

        const pieList = []

        res.companyProjectList.map(i => {
          const curData = []
          i.data.map(item => {
            curData.push({
              value: item.companyProjectNum,
              companyId: item.companyId,
              name: item.companyName
            })
          })
          pieList.push({
            title: i.content,
            data: curData
          })
        })
        this.pieList = pieList
        this.pieCharts(this.pieList, res.details)

        this.barChart('statusBar', this.statusList.statusListArray, this.statusList.colorList, res.details)
        this.barChart('questionBar', this.questinList.questionListArray, this.questinList.colorList, res.details)
        // res.showProjectListVo.map(item=>{
        //   console.log(item.id%2,'取整');
        //   let per1 = Math.floor(item.id*1.5+50)
        //   let per2 = Math.floor(item.id*0.5+50)
        //   let per3 = Math.floor(item.id*0.5+20)
        //   let per4 = Math.floor(item.id*1.5+20)
        //   if(item.id%3==0){
        //     item.perOne = per1
        //     item.perTwo = per2
        //   }else if(item.id%3==1){
        //     item.perOne = per3
        //     item.perTwo = per1
        //   }else if(item.id%3==2){
        //     item.perOne = per2
        //     item.perTwo = per4
        //   }else{
        //     item.perOne = per2
        //     item.perTwo = per1
        //   }

        // })

        this.projectList = res.showProjectListVo
      })
    },
    fullScreen () {
      // 判断是否支持
      // if (!screenfull.enabled) {
      //   this.$message({
      //     message: "不支持全屏",
      //     type: "warning"
      //   });
      //   return false;
      // }
      screenfull.toggle()
    },
    /**
     * @description 获取窗口缩放比例
     */
    setScale () {
      const docEl = document.documentElement
      console.log('------', document.getElementsByClassName('page')[0].clientWidth)
      const page = document.getElementsByClassName('page')[0]
      // console.log('----asa--',document.getElementsByClassName('page').style);
      //  const heiht = docEl.clientHeight
      const width = docEl.clientWidth
      const heiht = page.clientHeight
      console.log(width, heiht)
      if (!width || !heiht) return

      let ww = width / 1920
      let wh = heiht / 866
      console.log('width,heiht,', ww, wh, ww / wh)

      if (this.originW) {
        console.log('改变数据')
        ww = this.originW / 1920
        wh = this.originH / 866
      }
      console.log('widtasah,heiht,', ww / wh, (ww / wh) / 2.215)
      const perc = 2.215 - (ww / wh)
      console.log(perc, '----')
      let per = 0
      per = perc < 0 ? ('-' + (perc * 10).toFixed(6) + '%') : perc == 0 ? '0%' : (perc).toFixed(0) + '%'
      console.log(per, 'aksiajskajsoa')
      // if(perc)

      const scale = ww < wh ? ww : wh
      // let scale = ww

      this.scale = scale.toFixed(6)
      console.log(this.scale)
      // this.$refs.ScaleBox.style.setProperty('--scale', this.scale)
      this.$nextTick(() => {
        this.$refs.ScaleBox.style.transform = `scale(${this.scale}) translate(0%, 0%)`
      })
    },
    /**
     * @description 节流
     */
    debounce (fn, delay) {
      const delays = delay || 500
      let timer
      return () => {
        const _this = this
        const args = arguments
        if (timer) {
          clearTimeout(timer)
        }
        timer = setTimeout(() => {
          timer = null
          fn.apply(_this, args)
        }, delays)
      }
    },

    loop (data) {
      if (data.length <= 4) {
        return
      }
      this.animate = true // 因为在消息向上滚动的时候需要添加css3过渡动画，所以这里需要设置true
      //  setTimeout(()=>{      //  这里直接使用了es6的箭头函数，省去了处理this指向偏移问题，代码也比之前简化了很多
      //          this.data.push(this.data[0]);  // 将数组的第一个元素添加到数组的
      //          this.data.shift();             //删除数组的第一个元素

      //          this.animate=false;  // margin-top 为0 的时候取消过渡动画，实现无缝滚动
      //  },500)
    },
    pieCharts (list, clickEnable) {
      console.log(list, '图标')
      list.map((i, index) => {
        if (i.data.length > 0) {
          var div = document.createElement('div')
          div.id = 'pie' + (index + 1)
          div.className = 'pieBlock'
          document.getElementById('pieArea').appendChild(div)
          this.pieChart('pie' + (index + 1), i, clickEnable)
        }
      })
    },
    barChart (id, source, colorList, clickEnable) {
      console.log(id, source)
      const that = this
      var div = document.createElement('div')
      div.id = id
      div.className = 'barBlock'
      document.getElementById(id).appendChild(div)
      var chartDom = document.getElementById(id)
      var myChart = echarts.init(chartDom)
      var option
      option = {
        legend: {
          textStyle: {
            fontSize: 10,
            color: '#333333'
          }
        },
        tooltip: {
          position: function (point, params, dom, rect, size) {
            // 固定在顶部
            return [point[0], '10%']
          }
        },
        dataset: {
          source: source
        },
        xAxis: { type: 'category' },
        yAxis: {
          splitLine: {
            show: false
          }
        },
        series: colorList
      }

      option && myChart.setOption(option)

      myChart.on('click', function (params) {
        // if (!clickEnable) { // 是否支持点击跳转
        //   //  that.$tip.apiFailed('')
        //   return that.$message({
        //     message: '对不起，您无点击权限',
        //     type: 'warning'
        //   })
        // }

        const status = source[0][params.componentIndex + 1]
        let projectSatatus = null
        const projectType = params.dataIndex + 1
        console.log(status)
        if (status == '预启动') {
          projectSatatus = 0
        } else if (status == '运行中') {
          projectSatatus = 1
        } else if (status == '已暂停') {
          projectSatatus = 2
        } else if (status == '已完成') {
          projectSatatus = 3
        } else if (status == '已取消') {
          projectSatatus = 4
        }
        console.log(projectSatatus)

        console.log(params.dataIndex, '--', params.componentIndex)// 0,1,2,3  //数字基建、数字体验、景区转向   未解决，解决中，已解决
        if (id == 'statusBar') {
          that.$router.push({ path: '/manage/projectBoard', query: { projectSatatus: projectSatatus, projectType: projectType } })
        } else if (id == 'questionBar') {

        }
      })
    },

    pieChart (id, data, clickEnable) {
      const that = this
      var chartDom = document.getElementById(id)
      var myChart = echarts.init(chartDom)
      var option
      option = {
        title: {
          text: data.title,
          left: 'center',
          top: '10px',
          textStyle: {
            color: '#333333',
            fontWeight: 500,
            fontSize: '16px',
            'font-family': ' Source Han Sans CN',
            'font-weight': 500

          }
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'horizontal',
          left: 'center',
          top: '155px',
          itemWidth: 10,
          itemHeight: 10,
          itemStyle: {

          },
          textStyle: {
            fontSize: 12,
            color: '#333333'
          }
        },

        series: [
          {
            name: '',
            type: 'pie',
            // radius: '50%',
            radius: ['25%', '50%'],
            center: ['50%', '45%'],
            // avoidLabelOverlap: false,
            itemStyle: {
              // borderRadius: 5,
              // borderColor: '#fff',
              // borderWidth: 1
            },
            label: {
              show: false
            },
            data: data.data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.25)'
              }
            }
          }
        ]
      }

      option && myChart.setOption(option)

      myChart.on('click', function (params) {
        // if (!clickEnable) { // 是否支持点击跳转
        //   return that.$message({
        //     message: '对不起，您无点击权限',
        //     type: 'warning'
        //   })
        // }
        console.log(id, data)
        console.log(params.dataIndex, '--', params.componentIndex)// 0,1,2,3  //数字基建、数字体验、景区转向   未解决，解决中，已解决

        const companyId = data.data[params.dataIndex].companyId
        console.log(data.data[params.dataIndex].name)
        let projectType = null
        if (id == 'pie1') {
        // 数字基建
          projectType = 1
        } else if (id == 'pie2') {
          projectType = 2
        } else if (id == 'pie3') {
          projectType = 3
        } else if (id == 'pie4') {
          projectType = 4
        }

        that.$router.push({ path: '/manage/projectBoard', query: { companyId: companyId, projectType: projectType } })
      })
    }

  }
}
</script>

<style scoped lang="scss">
.anim{
    transition: all 0.5s;
    margin-left: -30px;
}
.pageTitle{
  background: #FFFFFF;
  box-shadow: 0px 0px 8px 0px rgba(33, 33, 33, 0.1);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  margin: 20px 0 20px 5px;
}

.page{
  background: #e4e4e4;
  margin: -10px 0;
}
.row1{
  height: 281px;
}
.row2{
  height: 498px;
  overflow: hidden;
}
  .colTitle{
    // height: 20px;
    color: white;
    background: #007AFF;
    border-radius: 1.5rem;
    display: inline-block;
    padding: 10px 20px;
    font-size: 20px;
    font-family: 'PingFang SC';
    font-weight: bold;
     position: relative;
     padding-left: 40px;
    &::before{
     position: absolute;
      top: 15px;
      left: 14px;
      display: inline-block;
      content: '';
      width: 20px;
      height: 4px;
      background: #fff;
      // padding-right: 6px;
      border-radius: 1.5em;
    }
    &::after{
     position: absolute;
      top: 23px;
      left: 14px;
      display: inline-block;
      content: '';
      width: 14px;
      height: 4px;
      background: #fff;
      // padding-right: 6px;
      border-radius: 1.5em;
    }
  }
.sumChart{
  height: 281px;
  font-size: 20px;
}
.sum{
  background: #FFFFFF;
  box-shadow: 0px 0px 8px 0px rgba(33, 33, 33, 0.1);
  border-radius: 6px;
  height: 61px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  margin-left:5px;
  padding-left: 20px;
  padding: 14px 20px;
  width: 1080px;
  .sumItem{
    flex:1;
    flex-shrink: 0;
    text-align: center;
    cursor: pointer;
    font-size: 27px;
    .sum_title{
        margin-right: 22px;
    }

  }

  .sum_title{

  }
  // padding: 5px 20px;
}
.sum_tabs{
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: space-between;
  // align-items: center;
  // width: 1089px;
  // height: 200px;

  .sum_tabItem{
    height:98px;
    width: 537px;
    flex-shrink: 0;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px 0px rgba(33, 33, 33, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    margin-top:12px;
    margin-left: 6px;
    .item{
      flex: 1;
      display: flex;
      flex-direction: column;
      text-align: center;
      cursor: pointer;
      .title{
        font-size: 15px;
        font-family: 'PingFang SC';
        font-weight: bold;
        color: #333333;
      }
      .number{
        font-size: 25px;
        font-family: 'PingFang SC';
        // font-weight: bold;
        color: #333333;
      }
    }
  }
}
.pieChart{
  height: 281px;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px 0px rgba(33, 33, 33, 0.1);
  padding: 14px 20px;
  border-radius: 17px;
  margin-left: -20px;

  .pies{
    height: 230px;
    display: flex;
    .pieBlock{
      width:25%;
      height: 100%;
      // border: 1px solid #def;
    }
  }
}

.projectList{
  background: #FFFFFF;
  box-shadow: 0px 0px 8px 0px rgba(33, 33, 33, 0.1);
  border-radius: 17px;
  padding: 14px 17px;
  width: 1080px;
  height: 468px;
  margin-left: 5px;
  margin-top: 10px;
  overflow: hidden;

  .listItem{
    height: 100px;
    margin: 10px 0;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px 0px rgba(33, 33, 33, 0.1);
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    padding: 11px 14px;
    .leftTitle{
      height: 48px;
      .top{
          .name{
              font-size: 20px;
              font-family: 'PingFang SC';
              font-weight: bold;
              color: #333333;
              cursor: pointer;
          }
          .id{
              font-size: 10px;
              font-family: 'PingFang SC';
              font-weight: 500;
              color: #999999;
              margin-left: 10px;
          }
        }
    }
    .other{
      display: flex;
      height: 60px;
      // margin-top: 10px;
      // justify-content: center;
      align-items: center;
    }
    .right{
        // flex: 1;
    }
    .end{
      display: flex;
      align-items: center;
      margin-left: 18px;
      .avatar{
        width: 44px;
        height: 44px;
        border-radius: 50%;
        margin-right: 15px;
      }
      .name{
        font-size: 16px;
        font-family:'PingFang SC';
        font-weight: 400;
        color: #333333;
        max-width: 74px;
      }
    }
  }
}

.projectList .left{
  // display: flex;
  // flex-direction: column;
  // align-items: flex-start;
  font-size: 10px;
//   padding: 10px;

  .leftContent{
        display: flex;
        margin: 10px 0;
        font-size: 10px;
        .left_left{
          width: 240px;
          height: 60px;

            .middle{
            display: flex;
            // font-size: 10px;
            font-family:'PingFang SC';
            font-weight: 500;
            color: #666666;
            margin: 10px 0;
            .projectName{
              cursor: pointer;
            }
            span{
                border-right:1px solid #ccc ;
                padding:0 10px;
                line-height: 10px;
                &:last-child{
                  border-right:0;

                }
            }
            }
            .bottom{
            // font-size: 6px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #666666;
            padding-left: 10px;
            }
        }
        .left_right{
          width: 128px;
            // font-size: 6px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #666666;
            margin:7px 17px;
            padding: 0 17px;
            border-left: 1px solid #ccc;
            border-right: 1px solid #ccc;
            line-height: 1.2rem;
        }
  }

}
.projectList  .right{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 6px;
  font-family: 'PingFang SC';
  font-weight: 500;
  color: #666666;
  border-right: 1px solid #ccc;
  margin-left: 18px;

  .progressItem{
    flex:1;
    width:400px;
    display: flex;
    align-items: center;
    font-size: 6px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #666666;
    margin-right: 18px;

    span{
      // float:lefts
      width:60px;
      text-align: right;
      margin-right: 4px;
    }
    // display: flex;
    .el-progress,.el-progress--line{
      flex-shrink: 0;
      flex: 1;

    }
    // flex-direction: column;
  }
}

.barContent{
  background: #FFFFFF;
  box-shadow: 0px 0px 8px 0px rgba(33, 33, 33, 0.1);
  border-radius: 17px;
  padding:14px 30px ;
  height: 228px;
  margin-top: 10px;
  margin-left: 15px;
  overflow: hidden;
  margin-right: -40px;
  .barBlock{
    width: 100%;
    height: 200px;
    // background: #def;
    margin-top: 10px;
  }
}

</style>
