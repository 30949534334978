<template>
  <TableLayout>
    <template v-slot:search-form>
      <div style="margin:10px ">
        <span v-for="(item,index) in itemOption" style="margin-right:10px">
          <el-button @click='changeButtons(item,index)' :type="active==index?'primary':''" style="width:136px">{{item.name}}</el-button>
        </span>
      </div>
    </template>
    <Project v-if="type==1"></Project>
    <Task v-if="type==2"></Task>
    <Milestone v-if="type==3"></Milestone>
    <Question v-if="type==4"></Question>

  </TableLayout>
</template>

<script>
import Project from '../../components/bussiness/index/project'
import Task from '../../components/bussiness/index/task'
import Question from '../../components/bussiness/index/question'
import Milestone from '../../components/bussiness/index/milestone'

import TableLayout from '@/layouts/TableLayout'
export default {
  name: 'df',
  components: {Project , TableLayout , Task , Question , Milestone },
  data(){
    return{
      type:1,//1,2,3,4 
      active:0,
      itemOption:[
        {name:'项目',activated:true,status:[
            {name:'已超期',checked:true},
            {name:'预启动',checked:false},
            {name:'已暂停',checked:false},
          ]},
          {name:'任务',activated:false,status:[
            {name:'未发布',checked:true},
            {name:'待启动',checked:false},
            {name:'暂停中',checked:false},
            {name:'已超期',checked:false},
          ]},
          {name:'里程碑',activated:false,status:[
            {name:'已超期',checked:true},
            // {name:'预启动',activated:false},
            // {name:'已暂停',activated:false},
          ]},
          {name:'问题',activated:false,status:[
            {name:'未处理',checked:true},
            {name:'处理中',checked:false},
          ]},
      ],
    }
  },
  watch:{
    checkList:{
      handler(val){
        let checkedList = this.itemOption[this.active].status.filter(i=>i.checked)
        console.log(checkedList,'watch,checklist');
      }
    }
  },
  methods: {
    changeButtons(item,index){
      this.type = index+1;
      this.active = index
      // this.itemOption[this.active].activated = false
      // this.itemOption[index].activated = true
      
      // this.checkList = []
    },
    // 查询数据
    handlePageChange () {
      this.tableData.list.splice(0, this.tableData.list.length)
      this.isWorking.search = true
      fetchTree()
        .then(records => {
          this.tableData.list = records
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
        .finally(() => {
          this.isWorking.search = false
        })
    }
  },
  created () {
    // this.config({
    //   module: '部门',
    //   api: '/system/department'
    // })
    // this.search()
  }
}
</script>

<style scoped lang="scss">
.table-layout {
  /deep/ .table-content {
    margin-top: 0;
  }
}
</style>
