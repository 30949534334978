<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
    :withFooter='false'
  >
    <!-- <el-form  ref="form" :rules="rules" label-width="120px" label-position="right" style="width:100%" > -->
 
      <el-slider v-model="value" :step="5" :min='0' :max='100'> </el-slider>
      <span v-if="value==100" style="color:red;font-size:12px;">进度100%即视为任务完成</span>
      <!-- <div style="margin:20px 0;">
        <span>进展描述</span>
        <el-input v-model="explains" type="textarea" placeholder="请输入进展描述" :rows="3" v-trim maxlength="500"/>
      </div> -->
      <section style="margin:10px;">
        <el-button type="primary" @click="confirm">确定</el-button>
        
      </section>
     
    <!-- </el-form> -->
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import {updateSpeedAndStatus} from '@/api/bussiness/task'

export default {
  name: 'showSpeed',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      value:0,
      explains:'',
      id:null,
    }
  },
  props:{
  },
  methods: {
    open (title, target) {
      console.log('---');
      console.log(target);
      this.title = title
      this.visible = true
      this.value = target.taskSpeed
      this.id = target.id

    },
    confirm () {
      console.log('----');
      this.$dialog.confirm('确定修改当前的任务进度吗？', '提示', {
        confirmButtonText: '确认修改',
        type: 'warning'
      })
        .then(() => {
      updateSpeedAndStatus({
        id:this.id,
        speed:this.value,
        status:this.value==100?3:null,
        explains:this.explains
      }).then(res=>{
            this.$tip.apiSuccess('修改成功')
            this.$emit('success')
          }).catch(e=>{
        this.$tip.apiFailed(e)
      }).finally(()=>{
         this.visible = false
      })
        })
    }
  },
  created () {
    // this.config({
    //   api: '/system/role'
    // })
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-form-item{
  display: flex;
}
/deep/ .el-form-item__content{
  margin-left: 0 !important;
  flex: 1;
}
</style>