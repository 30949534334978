<template>
  <TableLayout :permissions="['business:project:query']">
    <!-- 表格和分页 -->
    
    <el-form ref="checkForm" slot="search-check" :model="searchForm" label-width="100px" >
      <div style="float:right">
        <el-button type="primary" size='large' style="width:136px" @click="addProjectModalFun" v-permissions="['business:project:save']">新建项目</el-button>
      </div>
      <div>
        <el-button :type="searchForm.showType==0?'primary':''"  style="width:136px" @click="()=>{searchForm.showType=0;this.search()}">所有项目</el-button>
        <el-button :type="searchForm.showType==1?'primary':''"  style="width:136px" @click="()=>{searchForm.showType=1;this.search()}">我负责的</el-button>
        <el-button :type="searchForm.showType==2?'primary':''"  style="width:136px" @click="()=>{searchForm.showType=2;this.search()}">我参与的</el-button>
        <!-- <el-button  style="width:136px">按部门查看</el-button>
        <el-select style="margin:0 10px"></el-select> -->
      </div>
      <el-form-item label="项目状态" prop="account">
        
        <el-checkbox-group v-model="searchForm.status">
          <el-checkbox  :label="0">预启动</el-checkbox>
          <el-checkbox  :label="1">进行中</el-checkbox>
          <el-checkbox  :label="4">已取消</el-checkbox>
          <el-checkbox  :label="2">已暂停</el-checkbox>
          <el-checkbox  :label="3">已完成</el-checkbox>
        </el-checkbox-group>
        
      </el-form-item>
      <el-form-item label="项目类型" prop="realname">
        <el-checkbox-group v-model="searchForm.projectTypes">
          <el-checkbox v-for="item in projectTypes" :label="item.id">{{item.content}}</el-checkbox>
          <!-- <el-checkbox>数字化基建项目</el-checkbox>
          <el-checkbox>数字化运营项目</el-checkbox> -->
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="项目是否超期" prop="mobile">
        <el-checkbox-group v-model="searchForm.overdueStatus">
          <el-checkbox :label="false">未超期</el-checkbox>
          <el-checkbox :label="true">已超期</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>

    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline >
     
      <el-form-item label="关键词" prop="keyword" >
        <el-input  v-model="searchForm.keyword" placeholder="请输入项目名称或项目编号或负责人" v-trim maxlength="50"/>
      </el-form-item>
      <el-form-item label="计划工期" prop="time">
        <div >
          <el-date-picker  v-model="dateArray" type="daterange"  value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            <!-- <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="searchForm.planBegin" :disabledDate="(time)=>{return time.getTime()<new Date(searchForm.planEnd).getTime()}"></el-date-picker> 至
            <el-date-picker type="date" value-format="yyyy-MM-dd" :disabledDate="(time)=>{return time.getTime()>new Date(searchForm.planBegin).getTime()}" placeholder="选择日期" v-model="searchForm.planEnd" ></el-date-picker> -->
         </div>
      </el-form-item>
      <section>
        <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <template v-slot:table-wrap>
      
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        row-key="id"
        @row-click="todetail"
        stripe
        border
        default-expand-all
        @selection-change=""
      >
        <el-table-column type="selection" fixed="left" width="55"></el-table-column>
        <el-table-column prop="projectNo" label="项目编号" fixed="left" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="projectName" label="项目名称" fixed="left" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="projectTypeName" label="项目类型" fixed="left" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="planDuration" label="计划工期" fixed="left" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="actualDuration" label="实际工期" fixed="left" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="projectStatusName" label="项目状态" fixed="left" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="overdue" label="是否超期" fixed="left" min-width="100px" align="center">
          <div slot-scope="{row}">
            {{row.overdue?'是':'否'}}
          </div>
        </el-table-column>
        <el-table-column prop="score" label="得分" fixed="left" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="createDate" label="创建时间" fixed="left" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="directorName" label="负责人" fixed="left" min-width="100px" align="center"></el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination>
    </template>
    <AddProject  ref='projectAddModal' @success="handlePageChange(tableData.pagination.pageIndex)"></AddProject>
    <!-- <UserChooseWindow></UserChooseWindow> -->
    <!-- <transfer-one></transfer-one> -->
  </TableLayout>
</template>

<script>
import TableLayout from '@/layouts/TableLayout'
import { mapState, mapMutations } from 'vuex'

import BaseTable from '@/components/base/BaseTable'
import OperaDepartmentWindow from '@/components/system/department/OperaDepartmentWindow'
import DepartmentUserWindow from '@/components/system/department/DepartmentUserWindow'
import AddProject from '../../components/bussiness/projectAddModal/AddProject'
import UserChooseWindow from '@/components/common/UserChooseWindow.vue'
import TransferOne from '../../components/transfer/transferOne.vue'
import Pagination from '@/components/common/Pagination'

export default {
  name: '',
  extends: BaseTable,
  components: { DepartmentUserWindow, OperaDepartmentWindow, TableLayout,AddProject,UserChooseWindow,TransferOne,Pagination},
  computed:{
     ...mapState({
      projectTypes: state=>state.setting.projectTypes
    }),
  },
  data(){
   
     return{
      addProjectModal:false,
      active:0,
      checkList:[],
      dateArray:'',

      // 搜索
      searchForm: {
        // currUserId: 0,//当前登录人编号
        // departmentId: null,//部门编号
        // departmentIds: [],//可查看的所有部门列表 后台用
        // productIds: [],//自定义的产品列表<后台使用>

        keyword:null,//项目名称/项目编号/负责人（模糊搜索）
        overdueStatus: [],//项目是否超期
        planBegin: null,
        planEnd: null,
        status: [],//0-预启动 1-进行中 2-已暂停 3-已完成 4-已取消
        projectTypes: [],//项目类型 前台缓存
        showType: 0,//展示类型 0-所有项目 1-我负责的 2-我参与的 3-按部门查看
        showDeleted:false,//默认值

      },

      projectAddModalVisible:false
      
      
      
    }
  },
  watch:{
    checkList:{
      handler(val){
        let checkedList = this.itemOption[this.active].status.filter(i=>i.checked)
        console.log(checkedList,'watch,checklist');
      }
    },
    dateArray:{
      handler(val,old){
        console.log(val,old);
        if(val!=old){
          if(val){
            this.searchForm.planBegin = val[0]
            this.searchForm.planEnd = val[1]
          }else{
            this.searchForm.planBegin = ''
            this.searchForm.planEnd = ''
          }
        }
      }
    },
  },
  methods: {
    addProjectModalFun(){
      // this.projectAddModalVisible = true
      // setTimeout(()=>{
      this.$refs.projectAddModal.open('新建项目');

      // },500)

    },
    reset(){
      this.dateArray = ''
      this.searchForm= {
        keyword:null,//项目名称/项目编号/负责人（模糊搜索）
        overdueStatus: [],//项目是否超期
        planBegin: null,
        planEnd: null,
        status: [],//0-预启动 1-进行中 2-已暂停 3-已完成 4-已取消
        projectTypes: [],//项目类型 前台缓存
        showType:this.searchForm.showType
        // showType: 0,//展示类型 0-所有项目 1-我负责的 2-我参与的 3-按部门查看

      }
      this.search()
    },
    changeButtons(item,index){
      this.itemOption[this.active].activated = false
      this.itemOption[index].activated = true
      this.active = index
      this.checkList = []
    },
    handleCheckedStatus(value){
      console.log(value,'checked change status ');
      this.itemOption[this.active].status.map((item,index)=>{
        console.log();
        if(value.findIndex(item.name)>-1){
          item.checked = true
        }else{
          item.checked = false
        }
      })
    },
    //项目详情
    todetail(row, column, event){
      console.log(row);
      this.$router.push({
        path:'/detail',
        query:{
          pid:row.id,
          // 取消4 完成3 删除17
          // status:(row.projectStatus==3 || row.projectStatus==4 ||row.projectStatus==17)?false:true
        }
      })
    },
  },
  created () {
    this.config({
      module: '项目',
      api: '/bussiness/project'
    })
    this.search()
  }
}
</script>

<style scoped lang="scss">
.table-layout {
  /deep/ .table-content {
    margin-top: 0;
  }
}
</style>
