<template>
  <GlobalWindow
    :visible.sync="visible"
    :confirm-working="isWorking"
    title="添加人员"
    @confirm="confirm"
    @cancel="cancel"
  >
    <!-- width="582px" -->
<!--  -->
    <p class="tip" v-if="role != null">为项目 <em>{{role.name}}</em> 添加人员</p>
    <el-transfer
      ref="permissionTransfer"
      v-model="selectedIds"
      filterable
      :titles="['未添加人员', '已添加人员']"
      :props="{
        key: 'id',
        label: 'label'
      }"
      :data="data">
    </el-transfer>
      <!-- :filter-method="filterPermissions" -->

  </GlobalWindow>
</template>

<script>
import GlobalWindow from '@/components/common/GlobalWindow'
import { createRolePermission } from '@/api/system/role'
import { fetchAll } from '@/api/system/role'
// import { toProjectUsers } from '@/api/system/user'
import { toProjectUsers , inProjectUsers } from '@/api/system/department'
import {addUser} from '../../../api/bussiness/person'

export default {
  name: 'PermissionConfigWindow',
  components: { GlobalWindow },
  data () {
    return {
      visible: false,
      isWorking: false,
      // 角色对象
      role: null,
      // 权限列表
      permissions: [],
      // 已选中的权限ID
      selectedIds: [],
      data:[]
    }
  },
  methods: {
    /**
     * @role 角色对象
     */
    open (title,target) {
      this.title = title
      console.log(target,'-----');
      let exsitedUsers = []
      if(target.length>0){
        target.map(i=>{
          exsitedUsers.push(i.userId)
        })
      }
      this.visible = true
        // 传入数据
      if (this.$refs.permissionTransfer) {
        this.$refs.permissionTransfer.clearQuery('left')
        this.$refs.permissionTransfer.clearQuery('right')
      }
      this.toProjectUsers(exsitedUsers);

    // toProjectUsers({
    //       capacity: 10,
    //       model: {username: "", realname: "", rootDeptId: null, positionId: null, mobile: ""},
    //       page: 1,
    //       sorts: [{property: "CREATE_TIME", direction: "DESC"}]
    //     })
    //     .then(records => {
    //       this.data = []
    //       if(records.records.length>0){
    //         this.__fillData(this.data, records.records)
    //       }
    //     })
    //     .catch(e => {
    //       this.$tip.apiFailed(e)
    //     })
    },
    toProjectUsers(exsitedUsers){
      toProjectUsers()
        .then(records => {
          console.log('树的record',records);
          this.data = []
            let data = []

          if(records.length>0){
            records.map(item=>{
              console.log(item,'---');
              if(item.users&&item.users.length>0){
                data.push(...item.users)
              }
              if(item.children&&item.children.length>0){
                item.children.map(ii=>{
                  if(ii.users){
                    data.push(...ii.users)
                  }
                  
                  if(ii.children&&ii.children.length>0){
                    ii.children.map(i=>{
                      if(i.users){
                        data.push(...i.users)
                      }
                      
                    })
                  }
                })
              }
            })
            // disabled: i % 4 === 0
            data.map(i=>{
              console.log('--人员列表',);
            })
            // data.push(record)
            // this.__fillData(this.data, records.records)
          }

          console.log('处理后的data',data);
          data.map(i=>{
            i.label = i.username
            i.disabled = exsitedUsers.findIndex(eu=>eu==i.id)==-1?false:true
          })
          this.data  = data
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
    },
        // 填充部门树
    __fillData (list, pool) {
      console.log(list,pool,'修改树结构');
      for (const user of pool) {
        if (user.id === this.excludeId) {
          continue
        }
        const deptNode = {
          ...user,
          id: user.id,
          name:user.name,
          label: user.username+'-'+user.account,
          // user:JSON.stringify(user)
        }
        list.push(deptNode)
        if (user.children != null && user.children.length > 0) {
          deptNode.children = []
          this.__fillData(deptNode.children, user.children)
          if (deptNode.children.length === 0) {
            deptNode.children = undefined
          }
        }
      }

      console.log(this.data,'---');
    },
    // 确认选择权限
    confirm () {
      this.isWorking = true
      console.log(this.selectedIds,'-------------sdswdw');
      console.log(this.selectedIds.toString(),'-------------sdswdw');


      addUser({
        projectId:this.$route.query.pid,
        // userIds:this.selectedIds.toString(),
        userIds:this.selectedIds,
      }).then(res=>{
        this.isWorking = false;
        this.$tip.apiSuccess('添加成功')
        this.$emit('success')
      }).finally(() => {
        this.cancel();
      })

      // createRolePermission({
      //   roleId: this.role.id,
      //   permissionIds: this.selectedIds
      // })
      //   .then(() => {
      //     this.$tip.apiSuccess('权限配置成功，用户重新登录后生效')
      //     this.visible = false
      //     this.$emit('success')
      //   })
      //   .catch(e => {
      //     this.$tip.apiFailed(e)
      //   })
        // .finally(() => {
        //   this.isWorking = false
        // })
    },
    // 搜索权限
    filterPermissions (query, item) {
      const lowerCaseQuery = query.toLowerCase()
      // return item.code.toLowerCase().indexOf(lowerCaseQuery) > -1 || item.name.toLowerCase().indexOf(lowerCaseQuery) > -1
    },
    cancel(){
      this.selectedIds = [];
      this.visible = false;
      this.isWorking = false;
      
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables.scss";
.global-window {
  .tip {
    em {
      font-style: normal;
      color: $primary-color;
      font-weight: bold;
    }
  }
  .tip-warn {
    margin: 4px 0 12px 0;
    font-size: 12px;
    color: #999;
    i {
      color: orange;
      margin-right: 4px;
      font-size: 14px;
      position: relative;
      top: 1px;
    }
  }
}
</style>
