<template>
<GlobalWindow
    :title="title"
    :visible.sync="visible"
    v-if="visible"
    @confirm="confirm"
    :withFooter='false'
  >
    <!-- :confirm-working="isWorking" -->
<el-row class="sumInfo">
  <el-col :span="24" class="left">

    <div class="statusBlock">
        <div class="statusItem">
            <div class="label">状态</div>
            <div class="content">{{data.weeklyStatusStr}}</div>
        </div>
    </div>

    <div class="infoBlock">
        <div class="left">
            <img :src="data.url" alt="">
            <div class="leftContent">
                <div class="name">{{data.creatorName}}</div>
                <!-- <div class="special">不知道是个啥</div> -->
            </div>
        </div>
        <div class="right">
            <div>创建时间:{{data.createDate}}</div>
            <div>所属项目:{{data.projectNo}} {{data.belongProjectName}}</div>
        </div>
    </div>

    <div class="block" >
        <div class="blockTitle">
            <i class="el-icon-reading"></i>
            <span class="">基本信息</span>
        </div>
        <div class="blockContent contentBlocks">
            <div class="contentLine">
                <div class="label">报告对象:</div>
                <div class="content">{{data.mainDeliveryName}}</div>
            </div>
            <div class="contentLine">
                <div class="label">创建时间:</div>
                <div class="content">{{data.createDate}}</div>
            </div>
            <div class="contentLine">
                <div class="label">提交日期:</div>
                <div class="content">{{data.submitTime?data.submitTime:'-'}}</div>
            </div>
            <div class="contentLine">
                <div class="label">抄送对象:</div>
                <div class="content" > <span v-for="(item,key) in data.userVoList" :key='key'>{{item.name}}<span v-if="key<data.userVoList.length-1">、</span></span></div>
            </div>
            <div class="contentLine">
                <div class="label">报告内容:</div>
                <div class="content">{{data.content}}</div>
            </div>
        </div>
    </div>

    <div class="block" >
        <div class="blockTitle">
            <i class="el-icon-reading"></i>
            <span class="">附件信息</span>
            <!-- <el-button type="primary">上传附件</el-button> -->
            <!-- <FileUpload v-model='data.enclosureVoList'></FileUpload> -->
        </div>
        <div class="blockContent contentTable">
          <!-- deleteEnclosure -->
          <FileUploadTable :data="data.enclosureVoList" @delete="deleteEnclosure"></FileUploadTable>
            <!-- <el-table border :data="data.enclosureVoList">
                <el-table-column prop="name" label="资料名称" align="center"></el-table-column>
                <el-table-column prop="size" label="大小" align="center"></el-table-column>
                <el-table-column prop="operatorTime" label="操作时间" align="center"></el-table-column>
                <el-table-column  label="操作" align="center">
                    <div slot-scope="{row}" class="flexAround">
                        <a >下载</a>
                        <a  v-if="row.delete" @click="">删除</a>
                    </div>
                </el-table-column>

            </el-table> -->
        </div>
    </div>

    <div class="block"  >
        <div class="blockTitle">
        <i class="el-icon-reading"></i>
        <span class="">回复情况</span>
        </div>
        <div class="blockContent contentLine">
        <el-timeline  class="timeLine" v-if="data.recordVoList.length>0">
            <el-timeline-item
            placement="top"
            v-for="(activity, index) in data.recordVoList"
            :key="index"
            icon="el-icon-time"
            size="large"
            color="#999999"
            :timestamp="activity.operatorTime">
            <div class="content">
              <div style="display:flex;align-items:center;">
                <img class="avator" :src="activity.operatorImageUrl" alt="">
                <span>{{activity.operatorName}}</span>
              </div>
                <div class="info">
                <!-- <div class="name">{{activity.operatorName}}</div> -->
                <!-- <div class="dept">行政部</div> -->
                <div class="content">
                  <div>
                    <span style="display:block" v-for="i in activity.content.split('<br />')">{{i}}</span>
                    <!-- {{activity.content}} -->
                    </div>
                  <div style="text-align:right" v-if="activity.delete"><i class="el-icon-delete"  style="cursor:pointer"  @click="deleteFollowUpByWeeklyId(activity.id)"></i></div>
                </div>
                </div>

            </div>
            </el-timeline-item>
        </el-timeline>
        <el-input type="textarea" maxlength="30" show-word-limit v-model="content" v-trim placeholder="请输入内容" v-buttons="[...data.buttons,14]"></el-input>
        <el-button style="margin:20px 0" type="primary" @click="creatWeeklyReply" :disabled="content.length<=0" v-buttons="[...data.buttons,14]">回复</el-button>
        <!-- :loading="loading" -->

        </div>
    </div>

  </el-col>
  <el-col  class="pageDetailBtn" >
    <el-button v-if="data.buttons.findIndex(i=>i==7)!=-1" @click="$refs.editWeekLog.open('编辑周报',data)">编辑</el-button>
    <el-button v-if="data.buttons.findIndex(i=>i==11)!=-1" @click="confirm(6,'确定要提交当前周报吗?')">提交</el-button>
    <el-button v-if="data.buttons.findIndex(i=>i==9)!=-1"  @click="deleteWeeklyById(data.id)" type="danger">删除</el-button>
    <el-button v-if="data.buttons.findIndex(i=>i==13)!=-1"  @click="confirm(5,'确定要撤回当前周报吗?')">撤回</el-button>

    <!-- <el-button v-buttons="[...data.buttons,7]" @click="$refs.editWeekLog.open('编辑周报',data)">编辑</el-button>
    <el-button v-buttons="[...data.buttons,11]" @click="confirm(6)">提交</el-button>
    <el-button v-buttons="[...data.buttons,9]"  @click="deleteWeeklyById(data.id)" type="danger">删除</el-button>
    <el-button v-buttons="[...data.buttons,13]"  @click="deleteWeeklyById(data.id)">撤回</el-button>  -->
  </el-col>

</el-row>
<AddWeekLog ref="editWeekLog" @success="open('周报详情',{id:data.id})"/>

</GlobalWindow>
</template>

<script>
import * as echarts from 'echarts'
import GlobalWindow from '@/components/common/GlobalWindow'
import { queryWeeklyInfoById, creatWeeklyReply, deleteFollowUpByWeeklyId, deleteEnclosure, deleteWeeklyById, create } from '@/api/bussiness/weekLog'
import BaseOpera from '@/components/base/BaseOpera'
import AddWeekLog from '@/components/bussiness/projectAddModal/AddWeekLog'
import FileUpload from '../../common/FileUpload'
import FileUploadTable from '../../common/FileUploadTable'

const status = [
  { code: 5, content: '待递交' },
  { code: 6, content: '已提交' },
  { code: 7, content: '已回复' }
]
const buttons = [
  { code: 7, content: '编辑' },
  { code: 8, content: '发布' },
  { code: 9, content: '删除' },
  { code: 10, content: '达成' },
  { code: 11, content: '递交' },
  { code: 12, content: '未达成' },
  { code: 13, content: '撤回' },
  { code: 14, content: '回复' },
  { code: 15, content: '删除回复' },
  { code: 16, content: '删除附件' }
]

export default {
  name: 'week_log_detail',
  extends: BaseOpera,
  components: { GlobalWindow, AddWeekLog, FileUpload ,FileUploadTable},
  data () {
    return {
      data: {},
      content: '',
      visible: false,
      lineActive: 0, // 默认项目级动态

      form: {
        id: null,
        weeklyStatus: null
      }

    }// return
  },
  watch: {
    'data.weeklyName': {
      handler () {
        this.title = '周报:' + this.data.weeklyName
      }
    }
  },

  mounted () {

  },
  methods: {
    open (title, target) {
      this.title = title
      this.visible = true
      console.log(target, '---')
      this.form.id = target.id
      this.queryWeeklyInfoById(target.id)
    },
    confirm (weeklyStatus, message) {
      this.form.weeklyStatus = weeklyStatus
      this.$dialog.confirm(message, '提示', {
        confirmButtonText: '确定',
        type: 'warning'
      }).then(() => {
        create({
          weeklyStatus: weeklyStatus,
          id: this.form.id
        }).then(res => {
          this.$tip.apiSuccess('更新成功')
          this.queryWeeklyInfoById(this.form.id)
        }).catch(e => {
          this.$tip.apiFailed(e)
        }).finally(() => {
        })
      })
    },
    queryWeeklyInfoById (id) {
      queryWeeklyInfoById({
        id: id
      }).then(res => {
        console.log(res, '周报详情')
        this.data = res || {}
        this.data.buttons = this.data.buttons ? this.data.buttons : []
        // this.data = {
        //   ...this.data,
        //   id:target.id
        // }
      })
    },

    creatWeeklyReply () {
      creatWeeklyReply({
        content: this.content,
        weeklyId: this.data.id
      }).then(res => {
        console.log(res)
        this.$tip.apiSuccess('跟进添加成功')
        this.content = ''
        this.lineActive = 1
        this.queryWeeklyInfoById(this.data.id)
      }).catch(e => {

      })
    },
    deleteFollowUpByWeeklyId (id) {
      deleteFollowUpByWeeklyId({ id: id }).then(res => {
        this.queryWeeklyInfoById(this.data.id)
      }).catch(e => {
        this.$tip.apiFailed(e)
      })
    },
    deleteWeeklyById (id) {
      this.$dialog.deleteConfirm('确定要删除周报吗').then(res => {
        deleteWeeklyById({ id: id }).then(res => {
          this.$tip.apiSuccess('删除成功')
          this.$emit('success', 1)
          this.visible = false
        }).catch(e => {
          this.$tip.apiFailed(e)
        })
      })
    },
    deleteEnclosure (e) {
      console.log(e, '任务附件')
      deleteEnclosure({ id: e.data.id }).then(res => {
        this.$tip.apiSuccess('删除成功')
        this.queryWeeklyInfoById(this.data.id)
        this.$emit('success')
      }).catch(e => {
        this.$tip.apiFailed(e)
      })
    },

  }
}
</script>

<style lang="scss">

</style>
<style scoped lang="scss">
.sumInfo{
  color: #333333;
  font-size: 14px;
  font-family: 'Microsoft YaHei';
  font-weight: bold;
  padding: 20px;
}
.statusBlock{
    display: flex;

    align-items: center;
    .statusItem{
        display: flex;
        align-items: center;
        margin-right: 30px;
        .label{
            margin-right: 10px;
        }
        .content{
            background: #5470C6;
            border-radius: 1.5rem;
            padding: 4px 20px;
            color: #fff;
        }
    }
}
.infoBlock{
    display: flex;
    align-items: center;
    margin-top: 40px;
    .left{
        display: flex;
        align-items: center;
        border-right:2px solid #CCCCCC;
        margin-right: 12px;
        img{
            width: 60px;
            height: 60px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 12px;
            background: #edc;

        }
        .leftContent{
            margin-right: 12px;
            .name{
                font-size: 18px;
            }
            .special{
                font-weight: 400;
            }
        }

    }
    .right{
        font-weight: 400;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        div{
            line-height: 1.5rem;
        }
    }
}
.infoLine{
    margin: 20px 0 0 0;
    padding-bottom: 10px;
    border-bottom:1px solid #CCCCCC ;
}

.block{
  border-bottom: 1px solid #CCCCCC;
  padding: 12px 14px;
  .blockTitle{
    i{
      color: #1A69FC;
      font-weight: bold;
      margin-right: 3px;
    }
    span{

    }
  }
  .blockContent{
  }

}
.block .contentBlocks{
    .contentLine{
        display: flex;
        margin: 10px 0px;
        .label{
            flex-shrink: 0;
            margin-right: 10px;
        }
        .content{
            font-weight: 400;
        }
    }
}
.block .contentChart{
  display: flex;
  .chartItem{
    flex: 1;
    flex-shrink: 0;
  }
}
.block .contentSimp{
    margin: 20px;
    .blockItem{
      margin-bottom: 12px;
      .title{

      }
      .value{
        color: #FF5555;
        margin-right:6px ;
      }
      .unit{
        font-weight: 400;
      }
    }
}

.block .contentTable{
  margin:20px 0;
}
.block >.contentLine{
  margin: 10px 20px;
  .tabs{
    font-weight: 400;
    span{
      margin-right: 10px;
      cursor: pointer;
    }
    .tabAct{
      font-weight:bold
    }
  }
  .timeLine{
    margin-top: 10px;
    min-height: 100px;
    max-height: 500px;
    overflow-x: auto;
    padding-left: 10px;

  &::-webkit-scrollbar {
    width: 0px;
  }
    .content{
      // margin-top: 29px;
      .avator{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 12px;
      }
      .info{
        .name{
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
        }
        .dept{
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }
        .content{
          margin-top: 10px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          display: flex;
          div{
            flex: 1;
            flex-shrink: 0;
          }
        }
      }
    }
  }
}
</style>
