<template>
<GlobalWindow
    :title="title"
    :visible.sync="visible"
    v-if="visible"
    :withFooter='false'
  >
<el-row class="sumInfo" >
  <el-col :span="24" class="left">

    <div class="statusBlock">
        <div class="statusItem">
            <div class="label">状态</div>
            <div class="content">{{data&&data.taskStatusName}}</div>
        </div>
        <!-- <div class="statusItem">
            <div class="label">工期进度</div>
            <div class="content">{{data.taskSpeed}}%</div>
        </div> -->
        <div class="statusItem">
            <div class="label">自评进度</div>
            <div class="content">{{data.taskSpeed?data.taskSpeed:0}}%</div>
        </div>
    </div>

    <div class="infoBlock">
        <div class="left">
            <img :src="data.directorAvatar" alt="">
            <div class="leftContent">
                <div class="name">{{data.directorName}}</div>
                <!-- <div class="special">不知道是个啥</div> -->
            </div>
        </div>
        <div class="right">
            <div>操作时间:{{data.createDate}}</div>
            <div>所属项目:{{data.projectNo}} {{data.projectName}}</div>
        </div>
    </div>
    <div class="infoLine">详情</div>

    <div class="block" >
        <div class="blockTitle">
            <i class="el-icon-reading"></i>
            <span class="">基本信息</span>
        </div>
        <div class="blockContent contentBlocks">
            <div class="contentLine">
                <div class="label">任务类型:</div>
                <div class="content">{{data.taskTypeName}}</div>
            </div>
            <div class="contentLine">
                <div class="label">优先级:</div>
                <div class="content">{{data.taskLevel}}</div>
            </div>
            <div class="contentLine">
                <div class="label">备注:</div>
                <div class="content">{{data.taskExplain}}</div>
            </div>
        </div>
    </div>

    <div class="block" >
        <div class="blockTitle">
            <i class="el-icon-reading"></i>
            <span class="">任务工期</span>
        </div>
        <div class="blockContent contentBlocks">
            <div class="contentLine">
                <div class="label">计划工期:</div>
                <div class="content">{{data.planDuration}}</div>
            </div>
            <div class="contentLine">
                <div class="label">实际工期:</div>
                <div class="content">{{data.actualDuration}}</div>
            </div>
        </div>
    </div>

    <div class="block" >
        <div class="blockTitle">
            <i class="el-icon-reading"></i>
            <span class="">计划安排</span>
        </div>
        <div class="blockContent contentBlocks">
            <div class="contentLine">
                <div class="label">负责人:</div>
                <div class="content">{{data.directorName}}</div>
            </div>
            <div class="contentLine">
                <div class="label">参与人:</div>
                <div class="content">{{data.participateUserNames}}</div>
            </div>
            <div class="contentLine">
                <div class="label">计划工期:</div>
                <div class="content">{{data.planDuration}}</div>
            </div>
            <div class="contentLine">
                <div class="label">总计天数:</div>
                <div class="content">{{data.planDurationDays}}天</div>
            </div>
        </div>
    </div>

    <div class="block" >
        <div class="blockTitle">
            <i class="el-icon-reading"></i>
            <span class="">任务扣分</span>
        </div>
        <div class="blockContent contentBlocks">
            <div class="contentLine">
                <div class="label">扣除分数:</div>
                <div class="content">{{data.deductScore}}</div>
            </div>

        </div>
    </div>
    <div class="block" >
        <div class="blockTitle">
            <!-- <span :class="iconClass"></span> -->
            <span class="">添加跟进</span>
        </div>
        <div class="blockContent contentBlocks">
            <div class="" style="margin:20px 0">
              <!-- contentLine -->
                <div class="textArea">
                  <!-- v-buttons="[...data.operates,9]" -->
                   <!-- v-buttons="[...data.operates,9]" -->
                  <el-input type="textarea" maxlength="30" show-word-limit v-model="content" v-trim placeholder="请输入内容" ></el-input>
                  <el-button style="margin:20px 0" type="primary" @click="addTaskRecord" :disabled="content.length<=0">回复</el-button>
                </div>
            </div>

        </div>
    </div>

    <div class="block" >
        <div class="blockTitle">
            <i class="el-icon-reading"></i>
            <span class="">附件信息</span>
            <!-- <el-button type="primary">上传附件</el-button> -->
        </div>
        <div class="blockContent contentTable">
          <FileUploadTable :data="data.enclosures" @delete="deleteEnclosure"></FileUploadTable>

            <!-- <el-table border>
                <el-table-column prop="date1" label="资料名称" align="center"></el-table-column>
                <el-table-column prop="date2" label="类型" align="center"></el-table-column>
                <el-table-column prop="date3" label="大小" align="center"></el-table-column>
                <el-table-column prop="date4" label="上传时间" align="center"></el-table-column>
                <el-table-column prop="action" label="操作" align="center">
                    <div slot="action">
                        <a>下载</a>
                        <a>删除</a>
                    </div>
                </el-table-column>

            </el-table> -->
        </div>
    </div>

    <div class="block" >
        <div class="blockTitle">
        <i class="el-icon-reading"></i>
        <span class="">项目级动态</span>
        </div>
        <div class="blockContent contentLine">
            <div class="tabs">
                <span :class="lineActive==0?'tabAct':'' " @click='lineActive=0'>动态</span>
                <span :class="lineActive==1?'tabAct':'' " @click='lineActive=1'>跟进</span>
            </div>

        <el-timeline  class="timeLine" v-if="lineActive==0&&data.dynamicRecords&&data.dynamicRecords.length>0">
            <el-timeline-item
            placement="top"
            v-for="(activity, index) in data.dynamicRecords"
            :key="index"
            icon="el-icon-time"
            size="large"
            color="#999999"
            :timestamp="activity.operatorTime">
            <div class="content">
              <div style="display:flex;align-items:center;">
                <img class="avator" :src="activity.imageUrl" alt="">
                <span>{{activity.operatorName}}</span>
              </div>
                <div class="info">
                <div class="content">
                  <div>
                    <!-- {{activity.explains}} -->
                    <span style="display:block" v-for="i in activity.explains.split('<br />')">{{i}}</span>
                  </div>
                  <!-- <div style="text-align:right" v-if="activity.delete"><i class="el-icon-delete" @click="deleteFollowUpByQuestionId(activity.id)"></i></div> -->
                </div>
                </div>

            </div>
            </el-timeline-item>
        </el-timeline>

        <el-timeline  class="timeLine" v-if="lineActive==1&&data.allRecords&&data.allRecords.length>0">
            <el-timeline-item
            placement="top"
            v-for="(activity, index) in data.allRecords"
            :key="index"
            icon="el-icon-time"
            size="large"
            color="#999999"
            :timestamp="activity.operatorTime">
            <div class="content">
              <div style="display:flex;align-items:center;">
                <img class="avator" :src="activity.imageUrl" alt="">
                <span>{{activity.operatorName}}</span>
              </div>
                <div class="info">
                <div class="content">
                  <span style="display:block" v-for="i in activity.explains.split('<br />')">{{i}}</span>
                  <!-- <div>{{activity.explains}}</div> -->
                  <div style="text-align:right" v-if="activity.delete"><i class="el-icon-delete"  style="cursor:pointer"  @click="deleteRecord(activity.id)"></i></div>
                </div>
                </div>

            </div>
            </el-timeline-item>
        </el-timeline>

        </div>
    </div>
  </el-col>
  <el-col  class="pageDetailBtn">
        <!-- <el-button v-if="data.buttons.findIndex(i=>i==7)!=-1" @click="$refs.editWeekLog.open('编辑周报',data)">编辑</el-button> -->

        <el-button v-if="data.operates.findIndex(i=>i==7)!=-1"   @click="$refs.addTask.open('编辑任务',{id:data.id})">编辑</el-button>
        <el-button v-if="data.operates.findIndex(i=>i==8)!=-1"   @click="updateSpeedAndStatus(14)">发布</el-button>
        <el-button v-if="data.operates.findIndex(i=>i==17)!=-1"   @click="updateSpeedAndStatus(1)">启动</el-button>
        <el-button v-if="data.operates.findIndex(i=>i==2)!=-1"   @click="updateSpeedAndStatus(2)">暂停</el-button>
        <el-button v-if="data.operates.findIndex(i=>i==4)!=-1"   @click="updateSpeedAndStatus(3)">完成</el-button>
        <el-button v-if="data.operates.findIndex(i=>i==3)!=-1"   @click="updateSpeedAndStatus(1)">重新启动</el-button>
        <el-button v-if="data.operates.findIndex(i=>i==9)!=-1" type="danger"  @click="deleteById()">删除</el-button>

    <!-- <el-button   @click="$refs.addTask.open('编辑任务',{id:data.id})">编辑</el-button>
    <el-button   @click="updateSpeedAndStatus(14)">发布</el-button>
    <el-button   @click="updateSpeedAndStatus(1)">启动</el-button>
    <el-button   @click="updateSpeedAndStatus(2)">暂停</el-button>
    <el-button   @click="updateSpeedAndStatus(3)">完成</el-button>
    <el-button   @click="updateSpeedAndStatus(1)">重新启动</el-button>
    <el-button   @click="deleteById()">删除</el-button> -->
  </el-col>

  <AddTask ref="addTask" :fromObjId="$route.query.pid" @success="queryTaskShowById(data.id)"/>

</el-row>
</GlobalWindow>
</template>

<script>
import * as echarts from 'echarts'
import GlobalWindow from '@/components/common/GlobalWindow'
import { queryTaskShowById, updateSpeedAndStatus, addTaskRecord, deleteRecord, deleteEnclosure, deleteById } from '@/api/bussiness/task'
import AddTask from '@/components/bussiness/projectAddModal/AddTask'
import FileUploadTable from '../../common/FileUploadTable'

export default {
  name: 'project_task_detail',
  components: { GlobalWindow, FileUploadTable, AddTask },
  data () {
    return {
      data: null,
      content: '', // 跟进
      visible: false,
      activities: [
        { content: '活动按期开始', timestamp: '2018-04-15', name: '郭宝宝', dept: '行政部' },
        { content: '通过审核', timestamp: '2018-04-13', name: '郭宝宝', dept: '行政部' },
        { content: '创建成功', timestamp: '2018-04-11', name: '郭宝宝', dept: '行政部' },
        { content: '创建成功', timestamp: '2018-04-11', name: '郭宝宝', dept: '行政部' },
        { content: '创建成功', timestamp: '2018-04-11', name: '郭宝宝', dept: '行政部' },
        { content: '创建成功', timestamp: '2018-04-11', name: '郭宝宝', dept: '行政部' },
        { content: '创建成功', timestamp: '2018-04-11', name: '郭宝宝', dept: '行政部' },
        { content: '创建成功', timestamp: '2018-04-11', name: '郭宝宝', dept: '行政部' },
        { content: '创建成功', timestamp: '2018-04-11', name: '郭宝宝', dept: '行政部' }
      ],
      lineActive: 0// 默认项目级动态

    }// return
  },
  computed: {
    // 确认按钮文案
    confirmButtonText () {
      return this.allowCopy ? '复制' : '确定'
    },
    // 格式化后的内容
    formattedContent () {
      let content = this.content
      if (this.analyse) {
        try {
          content = JSON.stringify(JSON.parse(this.content), null, 2)
        } catch (e) {
        }
      }
      return content
    }
  },
  mounted () {

  },
  methods: {
    open (title, target) {
      this.title = title
      this.visible = true
      console.log('target,--', target)
      this.queryTaskShowById(target.id)
    },
    queryTaskShowById (id) {
      queryTaskShowById({ taskId: id }).then(data => {
        console.log(data, '任务详情')
        this.data = data
      }).catch(e => {
        this.$tip.apiFailed(e)
      })
    },
    deleteEnclosure (e) {
      console.log(e, '任务附件')
      deleteEnclosure({ enclosureId: e.data.id }).then(res => {
        this.$tip.apiSuccess('删除成功')
        this.queryTaskShowById(this.data.id)
        this.$emit('success')
      }).catch(e => {
        this.$tip.apiFailed(e)
      })
    },
    addTaskRecord () {
      addTaskRecord({
        record: this.content,
        taskId: this.data.id
      }).then(res => {
        console.log(res)
        this.$tip.apiSuccess('跟进添加成功')
        this.content = ''
        this.lineActive = 1
        this.queryTaskShowById(this.data.id)
      }).catch(e => {
        this.$tip.apiFailed(e)
      })
    },
    deleteRecord (id) {
      this.$dialog.confirm('确定删除当前的跟进吗？', '提示', {
        confirmButtonText: '删除',
        type: 'warning'
      }).then(() => {
        deleteRecord({
          recordId: id
        }).then(res => {
          this.$tip.apiSuccess('删除成功')
          this.queryTaskShowById(this.data.id)
        }).catch(e => {
          this.$tip.apiFailed(e)
        }).finally(() => {
        })
      })
    },
    updateSpeedAndStatus (status) {
      this.$dialog.confirm('确定修改当前的任务状态吗？', '提示', {
        confirmButtonText: '确认修改',
        type: 'warning'
      }).then(() => {
        updateSpeedAndStatus({
          id: this.data.id,
          status: status
        }).then(res => {
          this.$tip.apiSuccess('修改成功')
          this.queryTaskShowById(this.data.id)
          this.$emit('success')
        }).catch(e => {
          this.$tip.apiFailed(e)
        }).finally(() => {
        })
      })
    },
    deleteById () {
      this.$dialog.confirm('确定删除当前的任务吗？', '提示', {
        confirmButtonText: '删除',
        type: 'error'
      }).then(() => {
        deleteById({ taskId: this.data.id }).then(res => {
          this.$emit('success')
          this.visible = false
        }).catch(e => {
          this.$tip.apiFailed(e)
        })
      })
    }
  }
}
</script>

<style lang="scss">

</style>
<style scoped lang="scss">
.sumInfo{
  color: #333333;
  font-size: 14px;
  font-family: 'Microsoft YaHei';
  font-weight: bold;
  padding: 20px;
}
.statusBlock{
    display: flex;

    align-items: center;
    .statusItem{
        display: flex;
        align-items: center;
        margin-right: 30px;
        .label{
            margin-right: 10px;
        }
        .content{
            background: #5470C6;
            border-radius: 1.5rem;
            padding: 4px 20px;
            color: #fff;
        }
    }
}
.infoBlock{
    display: flex;
    align-items: center;
    margin-top: 40px;
    .left{
        display: flex;
        align-items: center;
        border-right:2px solid #CCCCCC;
        margin-right: 12px;
        img{
            width: 60px;
            height: 60px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 12px;
            background: #edc;

        }
        .leftContent{
            margin-right: 12px;
            .name{
                font-size: 18px;
            }
            .special{
                font-weight: 400;
            }
        }

    }
    .right{
        font-weight: 400;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        div{
            line-height: 1.5rem;
        }
    }
}
.infoLine{
    margin: 20px 0 0 0;
    padding-bottom: 10px;
    border-bottom:1px solid #CCCCCC ;
}

.block{
  border-bottom: 1px solid #CCCCCC;
  padding: 12px 14px;
  .blockTitle{
    i{
      color: #1A69FC;
      font-weight: bold;
      margin-right: 3px;
    }
    span{

    }
  }
  .blockContent{
  }

}
.block .contentBlocks{
    .contentLine{
        display: flex;
        margin: 10px 0px;
        .label{
            flex-shrink: 0;
            margin-right: 10px;
        }
        .content{
            font-weight: 400;
        }
    }
}
.block .contentChart{
  display: flex;
  .chartItem{
    flex: 1;
    flex-shrink: 0;
  }

}
.block .contentSimp{
    margin: 20px;
    .blockItem{
      margin-bottom: 12px;
      .title{

      }
      .value{
        color: #FF5555;
        margin-right:6px ;
      }
      .unit{
        font-weight: 400;
      }
    }
}

.block .contentTable{
  margin:20px 0;
}
.block >.contentLine{
  margin: 10px 20px;
  .tabs{
    font-weight: 400;
    span{
      margin-right: 10px;
      cursor: pointer;
    }
    .tabAct{
      font-weight:bold
    }
  }
  .timeLine{
    margin-top: 10px;
    min-height: 100px;
    max-height: 500px;
    overflow-x: auto;
    padding-left: 10px;

  &::-webkit-scrollbar {
    width: 0px;
  }
    .content{
    //   display: flex;
      margin-top: 29px;
      .avator{
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-right: 12px;
      }
      .info{
        .name{
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
        }
        .dept{
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }
        .content{
          margin-top: 29px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }
}
</style>
