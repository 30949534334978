<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
    :withFooter='false'
  >
    <!-- <el-form  ref="form" :rules="rules" label-width="120px" label-position="right" style="width:100%" > -->
 
      <el-slider v-model="value" :step="5" :min='0' :max='95'> </el-slider>
      <span style="color:red;font-size:12px;">自评进度将同步到工期进度</span>
      <section style="margin:10px;">
        <el-button type="primary" @click="confirm">确定</el-button>
        
      </section>
     
    <!-- </el-form> -->
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import {updateSpeedAndStatus} from '@/api/bussiness/project'

export default {
  name: 'showSpeed',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      value:0,
    }
  },
  props:{
    speed:{
      default:0,
    },
    id:{
      default:null
    }
  },
  methods: {
    open (title, target) {
      this.title = title
      this.visible = true
      this.value = this.speed
    },
    confirm () {
      console.log('----');
      this.$dialog.confirm('确定修改当前的自评进度吗？', '提示', {
        confirmButtonText: '确认修改',
        type: 'warning'
      })
        .then(() => {
      updateSpeedAndStatus({
        id:this.id,
        speed:this.value
      }).then(res=>{
            this.$tip.apiSuccess('修改成功')
            this.$emit('success')
          }).catch(e=>{
        this.$tip.apiFailed(e)
      }).finally(()=>{
         this.visible = false
      })
        })
      
    }
  },
  created () {
    // this.config({
    //   api: '/system/role'
    // })
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-form-item{
  display: flex;
}
/deep/ .el-form-item__content{
  margin-left: 0 !important;
  flex: 1;
}
</style>