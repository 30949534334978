<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    v-if="visible"

    :withFooter='false'
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="120px" label-position="right" style="width:100%" >
      <!-- <h2>新建任务</h2> -->
      <el-form-item label="变更类型" prop="type" :rules="{ required: true, message: '请选择变更类型', trigger: 'blur' }">
        {{data.type==1?'项目信息变更':'负责人变更'}}
      </el-form-item>
      <el-form-item label="项目名称" prop="name" >
        {{data.projectName}}
      </el-form-item>
      <el-form-item label="变更说明" prop="explains" :rules="{ required: true, message: '请输入变更说明', trigger: 'blur' }">
        {{data.explains}}
      </el-form-item>

      <!-- <h1>变更内容</h1> -->

      <div v-if="data.type==1">
        <!-- 项目变更 -->

        <h2>基础信息</h2>

        <el-table v-if="data.baseInfos" :data="data.baseInfos" stripe border >
          <el-table-column align="center" prop="itemName" label="变更项" min-width="120px">

          </el-table-column>
          <el-table-column align="center" prop="before" label="变更前" min-width="120px"></el-table-column>

          <el-table-column align="center" prop="after" label="变更后" min-width="100px" ></el-table-column>
        </el-table>
       <h2>项目预算</h2>
        <div class="content">
          <div class="item">
            <div class="left">变更项</div>
            <div class="middle">变更前</div>
            <div class="right">变更后</div>
          </div>
          <div class="item" v-for="(item ,index) in data.expenditures">
            <div class="left">{{item.itemName}}</div>
            <div class="middle">{{item.before}}</div>
            <div class="right">
              {{item.after}}
              </div>
          </div>
        </div>
        <h2>附件信息</h2>
          <FileUploadTable :data='data.enclosures'></FileUploadTable>
      </div>
      <div v-else>
        <div class="content">
          <div class="item">
            <div class="left">变更项</div>
            <div class="middle">变更前</div>
            <div class="right">变更后</div>
          </div>
          <div class="item" v-for="(item ,index) in data.directors">
            <div class="left">{{item.itemName}}</div>
            <div class="middle">{{item.before}}</div>
            <div class="right">
              {{item.after}}
              </div>
          </div>
        </div>
      </div>

      <section  class="pageDetailBtn">
        <!-- <el-button v-if="data.buttons.findIndex(i=>i==7)!=-1" @click="$refs.editWeekLog.open('编辑周报',data)">编辑</el-button> -->

        <!-- <el-button v-if="data.buttons.findIndex(i=>i==9)!=-1" type="danger" @click="confirm">删除</el-button>   -->
      </section>

    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import CompanySelect from '../../common/CompanySelect'
import LeaderSelect from '../../common/LeaderSelectProject'
import FileUpload from '../../common/FileUpload'
import FileUploadTable from '../../common/FileUploadTable'

import { projectDetailShow, deleteModify } from '@/api/bussiness/record'
// import{projectDetailQuery} from '@/api/bussiness/project'

import { mapState, mapMutations } from 'vuex'

export default {
  name: 'OperaRoleWindow',
  extends: BaseOpera,
  components: { GlobalWindow, LeaderSelect, CompanySelect, FileUploadTable, FileUpload },
  computed: {

    ...mapState({
      setting: state => state.setting
    })

  },
  watch: {

  },
  data () {
    return {
      oldData: null,
      newData: null,
      data: null,
      // 表单数据
      form: {
        baseInfos: [ // 基本信息变更
          {
            after: '',
            before: '',
            itemId: 0,
            itemName: ''
          }
        ],
        directors: [ // 负责人变更
          {
            after: 'asa',
            before: '牟谋星',
            itemId: 0,
            itemName: '项目负责人'
          }
        ],
        expenditures: [ // 项目预算变更
          {
            after: '',
            before: '',
            itemId: 0,
            itemName: ''
          }
        ],
        explains: '',
        ossPath: '', // 变更附件
        projectId: 0,
        type: 1
      },
      // 验证规则
      rules: {
        code: [
          { required: true, message: '请输入角色编码' }
        ],
        name: [
          { required: true, message: '请输入角色名称' }
        ]
      }
    }
  },
  methods: {
    open (title, target) {
      this.title = title
      this.visible = true

      projectDetailShow({ modifyId: target.id }).then(res => {
        console.log(res, '变更前')
        this.data = res
        // this.oldData = res
        // this.newData = JSON.parse(JSON.stringify(res))
      }).catch(e => {
        this.$tip.apiFailed(e)
      })
    },
    confirm () {
      this.$dialog.deleteConfirm('确定要删除当前变更记录吗?')
        .then(() => {
          deleteModify({
            id: this.data.id
          }).then(() => {
            this.$tip.apiSuccess('删除成功')
            this.$emit('success')
          })
            .catch(e => {
              this.$tip.apiFailed(e)
            })
            .finally(() => {
              this.visible = false
            })
        })
        .catch(() => {})
    }
  },
  created () {
    // this.config({
    //   api: '/system/role'
    // })
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-form-item{
  display: flex;
}
/deep/ .el-form-item__content{
  margin-left: 0 !important;
  flex: 1;
}
h2{
  font-size: 14px;
  font-family:'Microsoft YaHei';
  font-weight: 400;
  color: #333333;
  padding-bottom: 12px;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 12px;
  margin-top: 12px;
  font-weight: bold;
}
h1{
  font-size: 16px;
  font-family:'Microsoft YaHei';
  font-weight: 400;
  color: #333333;
  padding-bottom: 12px;
  margin-bottom: 12px;
}

.content{
  .item{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    border: 1px solid #ccc;
    border-top: 0;

    &:first-child{
    border: 1px solid #ccc;
    }
    .left{
      flex-shrink: 0;
      flex: 1;
      padding: 20px 0;
      width:calc(100% / 3);

      border-right: 1px solid #ccc;
    }
    .middle{
      flex-shrink: 0;
      flex:1;
      width:calc(100% / 3);

      padding: 20px 0;
      border-right: 1px solid #ccc;
    }
    .right{
      width:calc(100% / 3);
      flex-shrink: 0;
    }
  }
}
</style>
