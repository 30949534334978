<template>
<div class="curpage">
  <div class="line">
    <div class="label">姓名</div>
    <div class="content">{{data.username}}</div>
    <div class="action"></div>
  </div>
  <div class="line">
    <div class="label">头像</div>
    <div class="content">
      <div class="img"><ImageUpload v-model='data.avatar' :size='48'></ImageUpload></div>
      <!-- <img :src="data.avatar" alt=""> -->
    </div>
    <!-- <div class="action"><el-button  type="primary" size="mini" @click="">更换</el-button> </div> -->
  </div>
  <div class="line" v-if="data.companyName">
    <div class="label">关联公司</div>
    <div class="content">{{data.companyName}}</div>
    <div class="action"></div>
  </div>
  <div class="line">
    <div class="label">登录手机号</div>
    <div class="content">{{data.account}}</div>
    <div class="action"><el-button type="primary" size="mini"  @click="openDialog">更换</el-button></div>
  </div>



  <el-dialog title="更换手机号" :visible.sync="visible">
    <div id="login">
    <div class="login">
       <div class="img-box"></div>
       <div class="box-login">

            <el-form ref="loginForm" :model="form" :rules="rules" label-width="140px" >

                    <el-form-item label="新手机号" prop="mobile">
                        <el-input type="text" maxlength="11" suffix-icon="el-icon-mobile-mobile" placeholder="手机号" v-model="form.mobile"/>
                    </el-form-item>
                    <el-form-item label="密码" prop="password" required>
                      <el-input v-model="form.password" type="password" placeholder="请输入新密码" maxlength="30" show-password></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="验证码" prop="verificationCode">
                         <div style="display:flex">
                              <el-input type="text" maxlength="6" suffix-icon="el-icon-lock" placeholder="验证码" v-model="form.verificationCode"/>
                              <el-button style="margin-left:20px" :disabled="disabled" @click="getCode">{{valiBtn}}</el-button>
                          </div>
                    </el-form-item> -->
                    <el-form-item>
                               <el-button type="primary" @click.prevent="onSubmit('loginForm')">确认</el-button>
                    </el-form-item>
              </el-form>
        </div>
      </div>
    </div>

  </el-dialog>

</div>
</template>

<script>
import { queryById, updateAvatar, verificationCode, resetAccount } from '../../api/bussiness/person'
import ImageUpload from '../../components/common/ImageUpload'

export default {
  name: '',
  components: { ImageUpload },
  data () {
    var checkPhone = (rule, value, callback) => {
      const reg = /^0?(13[0-9]|15[012356789]|18[0-9]|14[578]|16[6]|17[035768]|19[19])[0-9]{8}$/
      if (reg.test(value)) {
        return callback()
      }
      callback('请输入合法的手机号')
    }
    return {
      data: {},
      visible: false,
      form: {
        mobile: null,
        password: ''
      },
      autoLogin: false,
      rules: {
        mobile: [{ validator: checkPhone, required: true, trigger: 'blur' }],
        verificationCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      },
      dialogVisible: false, // 对话框显示和隐藏
      valiBtn: '获取验证码',
      disabled: false
    }
  },
  mounted () {
    this.queryById()
  },
  watch: {
    'data.avatar': {
      handler (val, old) {
        console.log('-----', val, old)
        if (val && old && val != old) {
          this.updateAvatar()
        }
      },
      immediate: true
    }
  },
  methods: {
    queryById () {
      queryById().then(res => {
        console.log(res, '----个人中心----')
        this.data = res || []
      }).catch(e => {
        this.$tip.apiFailed(e)
      })
    },
    updateAvatar () {
      updateAvatar({ ossPath: this.data.avatar }).then(res => {
        this.$tip.apiSuccess('头像更换成功')
        this.queryById()
        this.$store.commit('setUserInfo', {
          ...this.$store.state.init.userInfo,
          avatar: this.data.avatar
        })
      }).catch(e => {
        this.$tip.apiFailed(e)
      })
    },
    openDialog () {
      this.visible = true
    },
    // 获取验证码 并只验证手机号 是否正确
    getCode () {
      this.$refs.loginForm.validateField('mobile', (err) => {
        if (err) {
          console.log('未通过')
        } else {
          console.log('已通过')
          this.tackBtn() // 验证码倒数60秒
          verificationCode({
            mobile: Number(this.form.mobile)
          }).then(res => {
            this.$tip.apiSuccess('验证码发送成功')
          }).catch(e => {
            this.$tip.apiFailed(e)
          })
        }
      })
    },
    tackBtn () { // 验证码倒数60秒
      let time = 60
      const timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer)
          this.valiBtn = '获取验证码'
          this.disabled = false
        } else {
          this.disabled = true
          this.valiBtn = time + '秒后重试'
          time--
        }
      }, 1000)
    },
    onSubmit (formName) { // 点击登录 验证手机& 验证码是否符合条件
      this.$refs[formName].validate((valid) => { // 为表单绑定验证功能
        if (valid) {
          resetAccount({
            id: this.data.id,
            newAccount: this.form.mobile,
            password: this.form.password
          }).then(res => {
            this.$tip.apiSuccess('手机号更换成功,建议重新登录')
            this.form.mobile = null
            this.form.password = null
            this.queryById()
          }).catch(e => {
            this.$tip.apiFailed(e)
          }).finally(() => {
            this.visible = false
          })
          // let fd = new FormData();
          //     fd.append('PhoneNumber', this.form.mobile);
          //     fd.append('VCode', this.form.verificationCode);
          // this.$axios({
          //     method: 'POST', data: fd, url: `/api/sgsaccount/adminlogin`,
          // }).then(res => {
          //     console.log(res);
          //     if (res.data.EID == 0) {
          //         var token = res.data.Data[0].token;
          //         localStorage.setItem('token',token);
          //         this.$message({showClose: true,message: '登录成功',type: 'success'})
          //         this.$router.push({path:'/indexx'});
          //     } else {
          //         this.$message({showClose: true,message: res.data.Err,type: 'warning'})
          //     }
          // });
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.curpage{
  margin:20px 60px;
  font-size: 16px;
  font-family: 'PingFang SC';

  .line{
    display: flex;
    align-items: center;
    margin: 20px 0;
    .label{
      font-weight: bold;
      color: #333333;
      margin-right: 10px;
    }
    .content{
      font-weight: 400;
      margin-right: 10px;
      .img,img{
        width: 48px;
        height: 48px;
        border-radius: 50%;
        overflow: hidden;
      }
    }
  }
}
</style>
