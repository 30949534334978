<template>
<div  id='app'>
  <div style="padding:10px;margin:0 10px;">
    <router-view/>
  </div>

</div>
</template>
<script>
import printJS from 'print-js'
export default {
  data(){
    return {
      tableData: [{
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄',
          tag: '家'
        }, {
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1517 弄',
          tag: '公司'
        }, {
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1519 弄',
          tag: '家'
        }, {
          date: '2016-05-03',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1516 弄',
          tag: '公司'
        }]
    }
  },
  created(){

  },
  mounted(){
    console.log(this.$route,'--routeroute-');
  },
  methods:{
    print(){
      printJS({ printable: 'print', type: 'html', header: '这是标题内容' })
    },
    resetDateFilter() {
        this.$refs.filterTable.clearFilter('date');
      },
      clearFilter() {
        this.$refs.filterTable.clearFilter();
      },
      formatter(row, column) {
        return row.address;
      },
      filterTag(value, row) {
        return row.tag === value;
      },
      filterHandler(value, row, column) {
        const property = column['property'];
        return row[property] === value;
      },
    printJson(){
      //通过getdata调用后台接口获取数据封装到res
      // const res = await getdata();
      let list = [
        {
          field1:'前阿沙和尚',
          field2:'前阿沙和尚',
          field3:'前阿沙和尚',
          field4:'前阿前阿沙和尚前阿沙和尚前阿沙和尚前阿沙和尚前阿沙和尚前阿沙和尚前阿沙和尚前阿沙和尚前阿沙和尚前阿沙和尚沙和尚',
          field5:'前阿沙和尚',
          field6:'前阿沙和尚',
          field7:'https://s0.2mdn.net/simgad/18401367853043225152',
          // 
        }
      ]
      let data = []
      for (let i=0; i < list.length; i++) {
          data.push({
          field1: list[i].field1,
          field2: list[i].field2,
          field3: list[i].field3,
          field4: list[i].field4,
          field5: list[i].field5,
          field6: list[i].field6,
          field7: list[i].field7,
        })
      }
      printJS({
        printable: data,
        properties: [
          {
            field: 'field1',
            displayName: '第一个字段',
            columnSize: 1
          },
          {
            field: 'field2',
            displayName: '第二个字段',
            columnSize: 1
          },
          {
            field: 'field3',
            displayName: '第三个字段',
            columnSize: 1
          },
          {
            field: 'field4',
            displayName: '第四个字段',
            columnSize: 1
          },
          {
            field: 'field5',
            displayName: '第5个字段',
            columnSize: 1
          },
          {
            field: 'field6',
            displayName: '第6个字段',
            columnSize: 1
          },
          {
            field: 'field7',
            displayName: '第7个字段',
            columnSize: 1
          }
        ],
        type: 'json',
        header: '标题',
        // 样式设置
        gridStyle: 'border: 2px solid #3971A5;',
        gridHeaderStyle: 'color: red;  border: 2px solid #3971A5;'
      })
    }
  }
}
</script>


<style lang="scss">
// 引入全局样式
@import "assets/style/style";
// 引入系统内置图标
@import "assets/icons/system/index";

#app{
  // padding: 10px 10px;
  // background:#F1F1F1;
  background: #e4e4e4;
}
a{
  text-decoration: none;
}
</style>
<style>
@import "assets/icons/ext/index.css";
</style>
