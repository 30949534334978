<template>
  <div

  >
  <!-- 废弃 -->
    <!-- <el-form :model="form" ref="form" :rules="rules" label-width="120px" label-position="right" style="width:100%" >
      <h2>基础信息</h2>
      <el-form-item label="项目类型" prop="code" required>
        <el-input v-model="form.code" placeholder="请输入" v-trim maxlength="50"/>
      </el-form-item>
      <el-form-item label="项目编号" prop="name" required>
        <el-input v-model="form.name" placeholder="请输入" v-trim maxlength="50"/>
      </el-form-item>
      <el-form-item label="项目名称" prop="remark">
        <el-input v-model="form.remark" type="textarea" placeholder="请输入" :rows="3" v-trim maxlength="500"/>
      </el-form-item>

      <el-form-item label="负责人" prop="code" required>
        <el-input v-model="form.code" placeholder="请输入" v-trim maxlength="50"/>
      </el-form-item>
      <el-form-item label="项目级别" prop="name" required>
        <el-input v-model="form.name" placeholder="请输入" v-trim maxlength="50"/>
      </el-form-item>
      <el-form-item label="项目参与人" prop="remark">
        <el-input v-model="form.remark" type="textarea" placeholder="请输入" :rows="3" v-trim maxlength="500"/>
      </el-form-item>
      <!-- <el-form-item label="所属部门" prop="deper" required>
        <el-input v-model="form.code" placeholder="请输入" v-trim maxlength="50"/>
      </el-form-item> -->

      <el-form-item label="关联公司" prop="deper" required>
        <DepartmentSelect v-model="form.companyId" placeholder="请选择所属部门" clearable/>
      </el-form-item>

      <el-form-item label="计划工期" prop="name" required>
        <el-input v-model="form.name" placeholder="请输入" v-trim maxlength="50"/>
      </el-form-item>
      <el-form-item label="总计天数" prop="remark">
        <el-input v-model="form.remark" type="textarea" placeholder="请输入" :rows="3" v-trim maxlength="500"/>
      </el-form-item>
      <el-form-item label="项目说明" prop="code" required>
        <el-input v-model="form.code" placeholder="请输入" v-trim maxlength="50"/>
      </el-form-item>
      <el-form-item label="备注" prop="name" required>
        <el-input v-model="form.name" placeholder="请输入" v-trim maxlength="50"/>
      </el-form-item>
      <h2>项目预算</h2>
      <el-form-item label="预计总收入" prop="remark">
        <el-input v-model="form.remark" type="textarea" placeholder="请输入" :rows="3" v-trim maxlength="500"/>
      </el-form-item>
      <el-form-item label="差旅成本" prop="remark">
        <el-input v-model="form.remark" type="textarea" placeholder="请输入" :rows="3" v-trim maxlength="500"/>
      </el-form-item>
      <el-form-item label="材料成本" prop="remark">
        <el-input v-model="form.remark" type="textarea" placeholder="请输入" :rows="3" v-trim maxlength="500"/>
      </el-form-item>
      <el-form-item label="外包成本" prop="remark">
        <el-input v-model="form.remark" type="textarea" placeholder="请输入" :rows="3" v-trim maxlength="500"/>
      </el-form-item>
      <el-form-item label="行政成本" prop="remark">
        <el-input v-model="form.remark" type="textarea" placeholder="请输入" :rows="3" v-trim maxlength="500"/>
      </el-form-item>
      <el-form-item label="设备成本" prop="remark">
        <el-input v-model="form.remark" type="textarea" placeholder="请输入" :rows="3" v-trim maxlength="500"/>
      </el-form-item>
      <el-form-item label="销售成本" prop="remark">
        <el-input v-model="form.remark" type="textarea" placeholder="请输入" :rows="3" v-trim maxlength="500"/>
      </el-form-item>
      <el-form-item label="公关成本" prop="remark">
        <el-input v-model="form.remark" type="textarea" placeholder="请输入" :rows="3" v-trim maxlength="500"/>
      </el-form-item>
      <el-form-item label="人力成本" prop="remark">
        <el-input v-model="form.remark" type="textarea" placeholder="请输入" :rows="3" v-trim maxlength="500"/>
      </el-form-item>

      <el-form-item label="宣传成本" prop="remark">
        <el-input v-model="form.remark" type="textarea" placeholder="请输入" :rows="3" v-trim maxlength="500"/>
      </el-form-item>
      <el-form-item label="预计总支出" prop="remark">
        <el-input v-model="form.remark" type="textarea" placeholder="请输入" :rows="3" v-trim maxlength="500"/>
      </el-form-item>

      <el-form-item label="附件" prop="remark">
        <el-input v-model="form.remark" type="textarea" placeholder="请输入" :rows="3" v-trim maxlength="500"/>
      </el-form-item>
    </el-form> -->
  </div>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import DepartmentSelect from '@/components/common/DepartmentSelect'

export default {
  name: 'AddProject',
  extends: BaseOpera,
  components: { GlobalWindow , DepartmentSelect ,  },
  data () {
    return {
      // 原角色码
      originRoleCode: '',
      // 表单数据
      form: {
        id: null,
        code: '',
        name: '',
        remark: ''
      },
      // 验证规则
      rules: {
        code: [
          { required: true, message: '请输入角色编码' }
        ],
        name: [
          { required: true, message: '请输入角色名称' }
        ]
      }
    }
  },
  methods: {
    open (title, target) {
      this.title = title
      this.visible = true
      // 新建
      if (target == null) {
        this.$nextTick(() => {
          this.$refs.form.resetFields()
          this.form[this.configData['field.id']] = null
        })
        return
      }
      // 编辑
      this.$nextTick(() => {
        this.originRoleCode = target.code
        for (const key in this.form) {
          this.form[key] = target[key]
        }
      })
    },
    confirm () {
      if (this.form.id == null || this.form.id === '') {
        this.__confirmCreate()
        return
      }
      if (this.originRoleCode === this.form.code) {
        this.__confirmEdit()
        return
      }
      // 修改了角色编码
      this.$dialog.confirm('检测到您修改了角色编码，角色编码修改后前后端均可能需要调整代码，确认修改吗？', '提示', {
        confirmButtonText: '确认修改',
        type: 'warning'
      })
        .then(() => {
          this.__confirmEdit()
        })
    }
  },
  created () {
    // this.config({
    //   api: '/system/role'
    // })
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-form-item{
  display: flex;
}
/deep/ .el-form-item__content{
  margin-left: 0 !important;
  flex: 1;
}
</style>