import request from '@/utils/request'

// 获取图片验证码
export function getCaptcha () {
  return request.get('/common/captcha')
}

//上传资料
export function saveEnclosure (data) {
  return request.post('/business/project/saveEnclosure',data)
}


// 查询资料列表
export function fetchList (data) {
  return request.get('/business/project/queryEnclosure',{params:data})
}
// 删除资料列表
export function deleteEnclosureById (data) {
  return request.get('/business/project/deleteEnclosureById',{params:data})
}

// 修改资料列表
export function updateEnclosureType (data) {
  return request.get('/business/project/updateEnclosureType',{params:data})
}
