<template>
  <TableLayout :permissions="['business:task:query']">
    <!-- :permissions="['business:task:query']" -->

    <!-- 表格和分页 -->
    <el-form ref="checkForm" slot="search-check" :model="searchForm" label-width="100px" >
      <!-- <div style="float:right">
        <el-button type="primary" size='large' style="width:136px">新建任务</el-button>
      </div> -->
      <el-form-item label="任务状态" prop="status">
        <el-radio v-model="status" :label="-1">全部</el-radio>
        <el-radio v-model="status" :label="13">未发布</el-radio>
        <el-radio v-model="status" :label="14">待启动</el-radio>
        <el-radio v-model="status" :label="1">进行中</el-radio>
        <el-radio v-model="status" :label="2">已暂停</el-radio>
        <el-radio v-model="status" :label="3">已完结</el-radio>
        <el-radio v-model="status" :label="15">已超期</el-radio>
        <el-radio v-model="status" :label="16">未超期</el-radio>
      </el-form-item>

      <!-- <el-checkbox-group v-model="checkList">
    <el-checkbox label="复选框 A"></el-checkbox>
    <el-checkbox label="复选框 B"></el-checkbox>
    <el-checkbox label="复选框 C"></el-checkbox>
    <el-checkbox label="禁用" disabled></el-checkbox>
    <el-checkbox label="选中且禁用" disabled></el-checkbox>
  </el-checkbox-group> -->

      <el-form-item label="任务类型" prop="taskTypes">
        <el-checkbox-group v-model="searchForm.taskTypes">
          <el-checkbox :label="1" :key="1">设计任务</el-checkbox>
          <el-checkbox :label="2" :key="2" >开发任务</el-checkbox>
          <el-checkbox :label="3" :key="3" >调研任务</el-checkbox>
          <el-checkbox :label="4" :key="4" >实施任务</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="80px" inline>
      <el-form-item label="任务名称" prop="taskName">
        <el-input v-model="searchForm.taskName" v-trim placeholder="请输入任务名称" @keypress.enter.native="search"/>
      </el-form-item>
      <el-form-item label="负责人" prop="taskName">
        <LeaderSelect v-model="searchForm.directors" placeholder="请选择报告对象" :fromObjId="searchForm.projectId" :multiple='true'></LeaderSelect>
      </el-form-item>
      <section>
        <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <template v-slot:table-wrap>
      <ul class="toolbar" v-if="projectBtns">
        <!-- v-permissions="['system:user:create', 'system:user:delete']" -->
         <!-- v-permissions="['system:user:create']" -->
        <li v-permissions="['business:task:save']"><el-button icon="el-icon-plus" type="primary" @click="$refs.addTask.open('新建任务')">新建任务</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        @row-click="(row)=>{$refs.taskDetail.open('任务详情',row)}"

        stripe
        border

      >
        <!-- :default-sort = "{prop: 'createTime', order: 'descending'}"
        @sort-change="handleSortChange" -->
        <!-- @selection-change="handleSelectionChange" -->
        <!-- <el-table-column type="selection" width="55" ></el-table-column> -->

        <el-table-column align="center" prop="taskName" label="任务名称" min-width="100px" fixed="left"></el-table-column>
        <el-table-column align="center" prop="directorName" label="负责人" min-width="120px"></el-table-column>
        <el-table-column align="center" prop="creatorName" label="发布者" min-width="120px"></el-table-column>
        <el-table-column align="center" prop="taskTypeName" label="任务类型" min-width="120px"></el-table-column>
        <el-table-column align="center" prop="taskStatusName" label="状态" min-width="120px"></el-table-column>
                <el-table-column align="center" prop="actualDays" label="实际结束天数" min-width="120px">
          <div slot-scope="{row}">
            <span >{{row.actualDays!=null?row.actualDays:'-'}}</span>
          </div>
        </el-table-column>
        <el-table-column align="center" prop="overdueDays" label="超期天数" min-width="120px">
          <div slot-scope="{row}">
            <span >{{row.overdueDays!=null?row.overdueDays:'-'}}</span>
          </div>
        </el-table-column>

        <el-table-column align="center" prop="planBegin" label="计划开始日期" min-width="100px" ></el-table-column>
        <el-table-column align="center" prop="planEnd" label="计划结束日期" min-width="100px" ></el-table-column>

        <!-- v-if="containPermissions(['system:user:update', 'system:user:createUserRole', 'system:user:resetPwd', 'system:user:delete'])" -->
        <el-table-column align="center"
          label="操作"
          width="270"
          fixed="right"
          v-if="projectBtns"
        >
          <div  slot-scope="{row}">
            <!-- v-if="isAdmin || (row.id !== userInfo.id && row.roles.findIndex(r => r.code === adminCode) === -1)" -->
            <!-- v-permissions="['system:user:delete']"
            v-permissions="['system:user:delete']" -->
             <el-button  v-if="row.operate" type="text"  @click.stop="(x)=>{$refs.addTask.open('编辑任务',{id:row.id})}" >编辑</el-button>
            <el-button v-if="row.operate && (row.taskStatus!=13 &&row.taskStatus!=14)" type="text"  @click.stop="(x)=>{$refs.taskSpeed.open('填写进度',row)}" >填写进度</el-button>
            <el-button v-if="row.operate && (row.taskStatus!=13 &&row.taskStatus!=14)  " type="text"  @click.stop="updateSpeedAndStatus(row)" >完成</el-button>
            <!-- <el-button v-if="row.isUpdate" type="text"  @click.stop="(x)=>{$refs.taskSpeed.open('填写进度',row)}" >填写进度</el-button>
            <el-button v-if="row.isUpdate&&row.taskStatus!=3" type="text"  @click.stop="updateSpeedAndStatus(row)" >完成</el-button> -->

          </div>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination>
    </template>
    <!-- 新建/修改 -->
    <AddTask ref="addTask" @success="successCallback"/>
    <TaskDetail ref='taskDetail'  @success="successCallback"></TaskDetail>
    <TaskSpeed ref='taskSpeed'   @success="successCallback"></TaskSpeed>

  </TableLayout>
</template>

<script>
import Pagination from '@/components/common/Pagination'
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import AddTask from '@/components/bussiness/projectAddModal/AddTask'
import TaskDetail from '@/components/bussiness/projectDetail/TaskDetail'
import TaskSpeed from '@/components/bussiness/projectAddModal/TaskSpeed'
import LeaderSelect from '../../common/LeaderSelectProject'

import { queryTaskShowById, updateSpeedAndStatus, addTaskRecord, deleteFollowUpByQuestionId, deleteEnclosure, deleteById } from '@/api/bussiness/task'

import DepartmentSelect from '../../common/DepartmentSelect'

export default {
  name: 'project_task',
  extends: BaseTable,
  components: { TableLayout, Pagination, DepartmentSelect, AddTask, TaskDetail, TaskSpeed, LeaderSelect },
  props: {
    projectBtns:{
      type:Boolean,
      default:true
    }
  },
  data () {
    return {
      status: -1,
      searchForm: {
        status: [1, 2, 3, 13, 14, 15, 17],
        taskTypes: [],
        taskName: null,
        directors: null,
        projectId: this.$route.query.pid
      },
      tableData: {
        list: []
      }
    }
  },
  computed: {

  },
  watch: {
    status: {
      handler (val) {
        if (val) {
          if (val == -1) {
            this.searchForm.status = [1, 2, 3, 13, 14, 15, 17]
          } else {
            this.searchForm.status = [val]
          }
        }
      }
    }
  },
  created () {
    this.config({
      module: '任务',
      api: '/bussiness/task'

    })
    this.search()
  },
  methods: {
    updateSpeedAndStatus (row) {
      this.$dialog.confirm('确定修改当前的任务状态为已完成？', '提示', {
        confirmButtonText: '确认修改',
        type: 'warning'
      }).then(() => {
        updateSpeedAndStatus({
          id: row.id,
          status: 3
        }).then(res => {
          this.$tip.apiSuccess('修改成功')
          this.successCallback()
        }).catch(e => {
          this.$tip.apiFailed(e)
        }).finally(() => {
        })
      })
    },
    successCallback(){
      this.search();
      this.$emit('success')
    }
  }
}
</script>

<style lang="scss">

</style>
<style scoped lang="scss">
// @import "@/assets/style/variables.scss";
// 列表头像处理
.table-column-avatar {
  img {
    width: 48px;
  }
}
.sumInfo{
  color: #333333;
  font-size: 18px;
  font-family: 'Microsoft YaHei';
  font-weight: bold;
  /deep/ .el-timeline-item__tail{
    border-left: 2px dashed #999999;
  }
  /deep/ .el-timeline-item__timestamp{
    color: #333333 ;
    font-weight: 400;
    font-size: 18px !important;
  }
}

</style>
