<template>
<GlobalWindow
    :title="title"
    :visible.sync="visible"
    v-if="visible"
    :withFooter='false'
  >
    <!-- :confirm-working="isWorking" -->
<el-row class="sumInfo">
  <el-col :span="24" class="left">

    <div class="statusBlock">
        <div class="statusItem">
            <div class="label">状态</div>
            <div class="content">{{data.milepostStatusStr}}</div>
        </div>
    </div>

    <div class="infoBlock">
        <div class="left">
            <img :src="data.directorImage" alt="">
            <div class="leftContent">
                <div class="name">{{data.directorName}}</div>
                <!-- <div class="special">不知道是个啥</div> -->
            </div>
        </div>
        <div class="right">
            <div>操作时间:{{data.createDate}}</div>
            <div>所属项目:{{data.projectNo}} {{data.projectName}}</div>
        </div>
    </div>
    <div class="infoLine">详情</div>

    <div class="block" >
        <div class="blockTitle">
            <span :class="iconClass"></span>
            <span class="">基本信息</span>
        </div>
        <div class="blockContent contentBlocks">
            <div class="contentLine">
                <div class="label">里程碑名称:</div>
                <div class="content">{{data.milepostName}}</div>
            </div>
            <div class="contentLine">
                <div class="label">里程碑说明:</div>
                <div class="content">{{data.milepostExplain}}</div>
            </div>
        </div>
    </div>

    <div class="block" >
        <div class="blockTitle">
            <span :class="iconClass"></span>
            <span class="">计划安排</span>
        </div>
        <div class="blockContent contentBlocks">
          <div class="contentLine">
                <div class="label">负责人:</div>
                <div class="content">{{data.directorName}}</div>
            </div>
            <div class="contentLine">
                <div class="label">计划达成日期:</div>
                <div class="content">{{data.planEnd}}</div>
            </div>
            <div class="contentLine">
                <div class="label">实际达成日期:</div>
                <div class="content">{{data.completeDate}}</div>
            </div>
        </div>
    </div>

    <div class="block" >
        <div class="blockTitle">
            <span :class="iconClass"></span>
            <span class="">里程碑扣分</span>
        </div>
        <div class="blockContent contentBlocks">
            <div class="contentLine">
                <div class="label">扣除分数:</div>
                <div class="content">{{data.deductScore}}</div>
            </div>

        </div>
    </div>
    <div class="block" >
        <div class="blockTitle">
            <span :class="iconClass"></span>
            <span class="">添加跟进</span>
        </div>
        <div class="blockContent contentBlocks">
            <div class="" style="margin:20px 0">
              <!-- contentLine -->
                <div class="textArea">
                  <!-- v-buttons="[...data.operates,9]" -->
                   <!-- v-buttons="[...data.operates,9]" -->
                  <el-input type="textarea" maxlength="30" show-word-limit v-model="content" v-trim placeholder="请输入内容" ></el-input>
                  <el-button style="margin:20px 0" type="primary" @click="creatMilepostRecord" :disabled="content.length<=0">回复</el-button>
                </div>
            </div>

        </div>
    </div>

    <div class="block" >
        <div class="blockTitle">
            <span :class="iconClass"></span>
            <span class="">附件信息</span>
            <!-- <el-button type="primary">上传附件</el-button> -->
        </div>
        <div class="blockContent contentTable">
          <file-upload-table :data="data&&data.files" @delete="deleteFile"></file-upload-table>
            <!-- <el-table border :data="data.files">
                <el-table-column prop="name" label="资料名称" align="center"></el-table-column>
                <el-table-column prop="size" label="大小" align="center"></el-table-column>
                <el-table-column prop="operatorTime" label="操作时间" align="center"></el-table-column>
                <el-table-column  label="操作" align="center">
                    <div slot-scope="{row}" class="flexAround">
                        <a >下载</a>
                        <a  v-if="row.delete" @click="">删除</a>
                    </div>
                </el-table-column>
            </el-table> -->
        </div>
    </div>

    <div class="block" >
        <div class="blockTitle">
        <span :class="iconClass"></span>
        <span class="">里程碑动态</span>
        </div>
        <div class="blockContent contentLine">
            <div class="tabs">
                <span :class="lineActive==0?'tabAct':'' " @click='lineActive=0'>动态</span>
                <span :class="lineActive==1?'tabAct':'' " @click='lineActive=1'>跟进</span>
            </div>

        <el-timeline  class="timeLine" v-if="lineActive==0&&data.records&&data.records.length>0">
            <el-timeline-item
            placement="top"
            v-for="(activity, index) in data.records"
            :key="index"
            icon="el-icon-time"
            size="large"
            color="#999999"
            :timestamp="activity.operatorTime">
            <div class="content">
              <div style="display:flex;align-items:center;">
                <img class="avator" :src="activity.imageUrl" alt="">
                <span>{{activity.operatorName}}</span>
              </div>
                <div class="info">
                <div class="content">
                  <div>
                    <span style="display:block"  v-for="i in activity.explains.split('<br />')">{{i}}</span>
                    <!-- {{activity.explains}} -->
                    </div>
                  <!-- <div style="text-align:right" v-if="activity.delete"><i class="el-icon-delete" @click="deleteRecord(activity.id)"></i></div> -->
                </div>
                </div>

            </div>
            </el-timeline-item>
        </el-timeline>

        <el-timeline  class="timeLine" v-if="lineActive==1&&data.allRecords&&data.allRecords.length>0">
            <el-timeline-item
            placement="top"
            v-for="(activity, index) in data.allRecords"
            :key="index"
            icon="el-icon-time"
            size="large"
            color="#999999"
            :timestamp="activity.operatorTime">
            <div class="content">
              <div style="display:flex;align-items:center;">
                <img class="avator" :src="activity.imageUrl" alt="">
                <span>{{activity.operatorName}}</span>
              </div>
                <div class="info">
                <div class="content">
                  <!-- <div>{{activity.explains}}</div> -->
                  <span style="display:block"  v-for="i in activity.explains.split('<br />')">{{i}}</span>
                  <div style="text-align:right" v-if="activity.delete"><i class="el-icon-delete" style="cursor:pointer" @click="deleteRecord(activity.id)"></i></div>
                </div>
                </div>

            </div>
            </el-timeline-item>
        </el-timeline>
        </div>
    </div>

  </el-col>
  <el-col class="pageDetailBtn" v-if="data.operates">
    <!-- <el-button v-if="data.buttons.findIndex(i=>i==7)!=-1" @click="$refs.addQuestion.open('编辑问题',data)">编辑</el-button> -->

    <el-button v-if="data.operates.findIndex(i=>i==7)!=-1"   @click="$refs.addMilestone.open('编辑里程碑',data)">编辑</el-button>
    <el-button v-if="data.operates.findIndex(i=>i==10)!=-1"    @click="completedMilepostById(data.id)">达成</el-button>
    <el-button v-if="data.operates.findIndex(i=>i==9)!=-1" type="danger" @click="deleteMilepostById(data.id)">删除</el-button>

    <!-- <el-button  @click="$refs.addMilestone.open('编辑里程碑',data)">编辑</el-button>
    <el-button   @click="completedMilepostById(data.id)">达成</el-button>
    <el-button v-buttons="[...data.operates,9]"  @click="deleteMilepostById(data.id)">删除</el-button> -->
  </el-col>

    <AddMilestone ref="addMilestone" @success="successCallback"/>

</el-row>
</GlobalWindow>
</template>

<script>
import * as echarts from 'echarts'
import GlobalWindow from '@/components/common/GlobalWindow'
import AddMilestone from '@/components/bussiness/projectAddModal/AddMilestone'

import { queryMilepostShowById, creatMilepostRecord, deleteRecord, deleteEnclosure, deleteMilepostById, completedMilepostById } from '@/api/bussiness/milestone'
import FileUploadTable from '../../common/FileUploadTable'

// import BaseOpera from '@/components/base/BaseOpera'

export default {
  name: 'project_milestone',
  components: { GlobalWindow, AddMilestone, FileUploadTable },
  data () {
    return {
      data: {},
      content: '',
      iconClass: 'iconOO',
      visible: false,
      lineActive: 0// 默认项目级动态

    }// return
  },
  computed: {
    // 确认按钮文案
    confirmButtonText () {
      return this.allowCopy ? '复制' : '确定'
    },
    // 格式化后的内容
    formattedContent () {
      let content = this.content
      if (this.analyse) {
        try {
          content = JSON.stringify(JSON.parse(this.content), null, 2)
        } catch (e) {
        }
      }
      return content
    }
  },
  mounted () {

  },
  methods: {
    open (title, target) {
      this.title = title
      this.visible = true
      console.log(target, '---')
      this.queryMilepostShowById(target.id)
    },
    queryMilepostShowById (id) {
      queryMilepostShowById({
        milepostId: id
      }).then(res => {
        console.log(res, '问题详情')
        this.data = res || {}
        if (this.data.operates) {
          this.data.operates = this.data.operates ? this.data.operates : []
        }
      })
    },

    creatMilepostRecord () {
      creatMilepostRecord({
        content: this.content,
        milepostId: this.data.id
      }).then(res => {
        console.log(res)
        this.$tip.apiSuccess('跟进添加成功')
        this.content = ''
        this.lineActive = 1
        this.queryMilepostShowById(this.data.id)
      }).catch(e => {
        this.$tip.apiFailed(e)
      })
    },
    deleteRecord (id) {
      deleteRecord({ id: id }).then(res => {
        if (res) {
          this.$tip.apiSuccess('跟进删除成功')
          this.queryMilepostShowById(this.data.id)
          this.$emit('success', 1)
        } else {
          this.$tip.apiFailed('删除失败,请联系管理员')
        }
      }).catch(e => {
        console.log(e, '---asa--s-as--a-s')
        this.$tip.apiFailed(e)
      })
    },
    deleteMilepostById (id) {
      this.$dialog.deleteConfirm('确定要删除里程碑吗' + id).then(res => {
        deleteMilepostById({ milepostId: id }).then(res => {
          this.$tip.apiSuccess('删除成功')
          this.$emit('success', 1)
          this.visible = false
        }).catch(e => {
          this.$tip.apiFailed(e)
        })
      })
    },
    completedMilepostById (id) {
      this.$dialog.confirm('确定要达成里程碑吗' + id).then(res => {
        completedMilepostById({ milepostId: id }).then(res => {
          this.$tip.apiSuccess('成功')
          this.$emit('success', 1)
          this.visible = false
        }).catch(e => {
          this.$tip.apiFailed(e)
        })
      })
    },
    deleteFile (e) {
      console.log(e, '--删除')// e.data,e.index
      deleteEnclosure({ milepostId: e.data.id }).then(res => {
        this.$tip.apiSuccess('删除成功')
        this.queryMilepostShowById(this.data.id)
        this.$emit('success')
      }).catch(e => {
        this.$tip.apiFailed(e)
      })
    },
    successCallback(){
      this.queryMilepostShowById(this.data.id)
      this.$emit('success')
    }

  }
}
</script>

<style lang="scss">

</style>
<style scoped lang="scss">
.sumInfo{
  color: #333333;
  font-size: 14px;
  font-family: 'Microsoft YaHei';
  font-weight: bold;
  padding: 20px;
}
.statusBlock{
    display: flex;

    align-items: center;
    .statusItem{
        display: flex;
        align-items: center;
        margin-right: 30px;
        .label{
            margin-right: 10px;
        }
        .content{
            background: #5470C6;
            border-radius: 1.5rem;
            padding: 4px 20px;
            color: #fff;
        }
    }
}
.infoBlock{
    display: flex;
    align-items: center;
    margin-top: 40px;
    .left{
        display: flex;
        align-items: center;
        border-right:2px solid #CCCCCC;
        margin-right: 12px;
        img{
            width: 60px;
            height: 60px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 12px;
            background: #edc;

        }
        .leftContent{
            margin-right: 12px;
            .name{
                font-size: 18px;
            }
            .special{
                font-weight: 400;
            }
        }

    }
    .right{
        font-weight: 400;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        div{
            line-height: 1.5rem;
        }
    }
}
.infoLine{
    margin: 20px 0 0 0;
    padding-bottom: 10px;
    border-bottom:1px solid #CCCCCC ;
}

.block{
  border-bottom: 1px solid #CCCCCC;
  padding: 12px 14px;
  .blockTitle{
    span{

    }
  }
  .blockContent{
  }

}
.block .contentBlocks{
    .contentLine{
        display: flex;
        margin: 10px 0px;
        .label{
            flex-shrink: 0;
            margin-right: 10px;
        }
        .content{
            font-weight: 400;
        }
    }
}
.block .contentChart{
  display: flex;
  .chartItem{
    flex: 1;
    flex-shrink: 0;
  }

}
.block .contentSimp{
    margin: 20px;
    .blockItem{
      margin-bottom: 12px;
      .title{

      }
      .value{
        color: #FF5555;
        margin-right:6px ;
      }
      .unit{
        font-weight: 400;
      }
    }
}

.block .contentTable{
  margin:20px 0;
}
.block >.contentLine{
  margin: 10px 20px;
  .tabs{
    font-weight: 400;
    span{
      margin-right: 10px;
      cursor: pointer;
    }
    .tabAct{
      font-weight:bold
    }
  }
  .timeLine{
    margin-top: 10px;
    min-height: 100px;
    max-height: 500px;
    overflow-x: auto;
    padding-left: 10px;

  &::-webkit-scrollbar {
    width: 0px;
  }
    .content{
    //   display: flex;
      margin-top: 29px;
      .avator{
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-right: 12px;
      }
      .info{
        .name{
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
        }
        .dept{
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }
        .content{
          margin-top: 29px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          display: flex;
          div{
            flex: 1;
            flex-shrink: 0;
          }
        }
      }
    }
  }
}
</style>
