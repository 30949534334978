<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
    :withFooter='false'
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="120px" label-position="right" style="width:100%" >
      <h2>新建任务</h2>
      <!-- <el-form-item label="所属项目" prop="code" required>
        一堆堆项目名称
      </el-form-item> -->
      <el-form-item label="发布者" prop="creator" >
        {{form.username}}
      </el-form-item>
      <el-form-item label="任务名称" prop="taskName" :rules="{ required: true, message: '请输入任务名称', trigger: 'blur' }">
        <el-input v-model="form.taskName"  placeholder="请输入任务名称" :rows="3" v-trim maxlength="500"/>
      </el-form-item>

      <el-form-item label="任务类型" prop="taskType" :rules="{ required: true, message: '请选择任务类型', trigger: 'blur' }">
        <el-select v-model="form.taskType" placeholder="请选择">
          <el-option
            v-for="item in taskType"
            :key="item.id"
            :label="item.label"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="紧急程度" prop="taskLevel">
        <el-select v-model="form.taskLevel" placeholder="请选择">
          <el-option
            v-for="item in pointLevel"
            :key="item.id"
            :label="item.label"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="任务说明" prop="taskExplain">
        <el-input v-model="form.taskExplain" type="textarea" placeholder="请输入" :rows="3" v-trim maxlength="500"/>
      </el-form-item>

      <h2>计划安排</h2>

      <el-form-item label="任务负责人" prop="director" :rules="{ required: true, message: '请选择报告对象', trigger: 'blur' }">
        <LeaderSelect v-if="$route.query.pid" v-model="form.director" placeholder="请选择报告对象" :fromObjId="$route.query.pid"  :multiple='false'></LeaderSelect>

      </el-form-item>
      <el-form-item label="任务参与人" prop="participateUserIds" >
        <LeaderSelect v-if="$route.query.pid" v-model="form.participateUserIds" placeholder="请选择报告对象" :fromObjId="$route.query.pid"  :defaultChecked="form.director"  :multiple='true'></LeaderSelect>

      </el-form-item>
      <el-form-item label="计划工期" required>
        <div >
          <el-date-picker  style="width:100% !important" v-model="dateArray" type="daterange"  value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            <!-- <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="form.planBegin" :disabledDate="(time)=>{return time.getTime()<new Date(form.planEnd).getTime()}"></el-date-picker> 至
            <el-date-picker type="date" value-format="yyyy-MM-dd" :disabledDate="(time)=>{return time.getTime()>new Date(form.planBegin).getTime()}" placeholder="选择日期" v-model="form.planEnd" ></el-date-picker> -->
         </div>
      </el-form-item>
      <el-form-item label="总计天数" prop="planDays" :rules="{ required: true, message: '请填写计划工期', trigger: 'blur' }">
        {{form.planDays}}
      </el-form-item>

      <h2>附件信息</h2>
      <el-form-item label="上传文件" prop="enclosureFiles" >
        <div>
          <FileUpload v-model='form.enclosureFiles'></FileUpload>
          <FileUploadTable :data='form.enclosureFiles' @delete="(e)=>{this.form.enclosureFiles.splice(e.index,1)}"></FileUploadTable>
        </div>
      </el-form-item>

      <section>
        <el-button type="primary" @click="confirm(13)">保存</el-button>
        <el-button v-if="taskStatus==null" type="primary" @click="confirm(14)">保存并发布</el-button>
      </section>

    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import LeaderSelect from '../../common/LeaderSelectProject'
import FileUpload from '../../common/FileUpload'
import FileUploadTable from '../../common/FileUploadTable'
import { taskType, pointLevel } from '../../../utils/basicData'
import { queryTaskEditById } from '@/api/bussiness/task'

export default {
  name: 'addTask',
  extends: BaseOpera,
  components: { GlobalWindow, LeaderSelect, FileUpload, FileUploadTable },
  data () {
    return {
      taskType: taskType,
      pointLevel: pointLevel,
      valueDate: '', // 时间范围
      // 原角色码
      originRoleCode: '',
      dateArray: '', // 时间
      taskStatus:null,
      // 表单数据
      form: {
        id: null,

        creator: null,
        director: null,
        files: [],
        participateUserIds: [],
        projectId:null,
        // "enclosureName": "",
        // "enclosurePath": "",
        // "enclosureSize": 0,

        planBegin: '',
        planEnd: '',
        planDays: '',
        projectId: this.$route.query.pid,
        taskExplain: '',
        taskLevel: null,
        taskName: '',
        taskStatus: null, //
        taskType: null
      },
      // 验证规则
      rules: {
        code: [
          { required: true, message: '请输入角色编码' }
        ],
        name: [
          { required: true, message: '请输入角色名称' }
        ]
      }
    }
  },
  watch: {
    dateArray: {
      handler (val, old) {
        console.log(val, old)
        if (val != old) {
          if (val) {
            this.form.planBegin = val[0]
            this.form.planEnd = val[1]
          } else {
            this.form.planBegin = ''
            this.form.planEnd = ''
            this.form.planDays = ''
          }
        }
      }
    },
    'form.planBegin': {
      handler (val, olv) {
        console.log(val, '--a-sa-')
        if (val) {
          if (this.form.planEnd) {
            const diff = (new Date(this.form.planEnd).getTime() - new Date(this.form.planBegin).getTime()) / 1000
            const dayDiff = Math.floor(diff / 86400) + 1
            console.log(dayDiff, '----几天')
            this.form.planDays = dayDiff
            this.dateArray = [this.form.planBegin, this.form.planEnd]
          } else {
            this.form.planDays = ''
          }
        }
      },
      deep: true
    },
    'form.planEnd': {
      handler (val, olv) {
        if (val) {
          if (this.form.planBegin) {
            const diff = (new Date(this.form.planEnd).getTime() - new Date(this.form.planBegin).getTime()) / 1000
            const dayDiff = Math.floor(diff / 86400) + 1 // 时间段
            this.form.planDays = dayDiff
            this.dateArray = [this.form.planBegin, this.form.planEnd]
          } else {
            this.form.planDays = ''
          }
        }
      },
      deep: true
    }
  },
  methods: {
    open (title, target) {
      this.title = title
      this.visible = true
      // 新建
      if (target == null) {
        this.$nextTick(() => {
          this.$refs.form.resetFields()
          this.form = {
            id: null,
            creator: null,
            director: null,
            files: [],
            participateUserIds: [],
            planBegin: '',
            planEnd: '',
            planDays: '',
            projectId: this.$route.query.pid,
            taskExplain: '',
            taskLevel: null,
            taskName: '',
            taskStatus: null, //
            taskType: null
          }
          this.form[this.configData['field.id']] = null

          this.form.creator = this.$store.state.init.userInfo.id
          this.form.username = this.$store.state.init.userInfo.username
          this.form.projectId = this.$route.query.pid
        })
        return
      }
      queryTaskEditById({
        taskId: target.id
      }).then(res => {
        if (res) {
          this.$nextTick(() => {
            for (const key in this.form) {
              this.form[key] = res[key]
            }
            this.form.username = res.creatorName
            if (res.enclosures) {
              res.enclosures.map(i => {
                i.delete = true
              })
            }
            this.form.enclosureFiles = res.enclosures
            this.taskStatus = res.taskStatus?res.taskStatus:null
          })
        }
      }).catch(e => {
        this.$tip.apiFailed(e)
      })
      // // 编辑
      // this.$nextTick(() => {
      //   console.log(target,'----编辑任务');
      //   for (const key in this.form) {
      //     this.form[key] = target[key]
      //   }
      // })
    },

    confirm (taskStatus) {
      
      if(this.taskStatus!=null){
        this.form.taskStatus = this.taskStatus
      }else{
        this.form.taskStatus = taskStatus
      }
      this.__confirmCreate()
      // if (this.form.id == null || this.form.id === '') {

      //   return
      // }else{
      //     this.__confirmEdit()
      //     return
      // }

      // // 修改了角色编码
      // this.$dialog.confirm('检测到您修改了角色编码，角色编码修改后前后端均可能需要调整代码，确认修改吗？', '提示', {
      //   confirmButtonText: '确认修改',
      //   type: 'warning'
      // })
      //   .then(() => {
      //     this.__confirmEdit()
      //   })
    }
  },
  created () {
    this.config({
      module: '任务',
      api: '/bussiness/task'
    })
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-form-item{
  display: flex;
}
/deep/ .el-form-item__content{
  margin-left: 0 !important;
  flex: 1;
}
h2{
  font-size: 14px;
  font-family:'Microsoft YaHei';
  font-weight: 400;
  color: #333333;
  padding-bottom: 12px;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 12px;
}
</style>
