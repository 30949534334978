<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    v-if="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="120px" label-position="right" style="width:100%" >
      <h2>基础信息</h2>
      <el-form-item label="项目类型" prop="projectType"  :rules="{ required: true, message: '请选择项目类型', trigger: 'blur' }">
        <!-- <el-input v-model="form.code" placeholder="请输入" v-trim maxlength="50"/> -->
        <el-select v-model="form.projectType" placeholder="请选择">
          <el-option
            v-for="item in setting.projectTypes"
            :key="item.id"
            :label="item.content"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
       <el-form-item label="项目编号" prop="projectNo" v-if="setting.projectNo.switchs&&!setting.projectNo.auto">
        <el-input v-model="form.projectNo" placeholder="请输入" v-trim maxlength="50"/>
      </el-form-item>
      <el-form-item label="项目名称" prop="projectName"  :rules="{ required: true, message: '请输入项目名称', trigger: 'blur' }">
        <el-input v-model="form.projectName"  placeholder="请输入"  v-trim maxlength="50"/>
      </el-form-item>

      <el-form-item label="负责人" prop="director"  :rules="{ required: true, message: '请选择负责人', trigger: 'blur' }">
        {{form.username}}
        <!-- <RoleSelect></RoleSelect> -->
        <!-- <LeaderSelect v-model="form.director"  @row="getLeaderInfo"></LeaderSelect> -->
        <!-- <el-input v-model="form.director" placeholder="请输入" v-trim maxlength="50"/> -->
      </el-form-item>
      <el-form-item label="项目级别" prop="projectLevel"  v-if="setting.tableShow.projectLevel">
        <el-select v-model="form.projectLevel" placeholder="请选择" clearable>
          <el-option
            v-for="item in setting.projectLevels"
            :key="item.id"
            :label="item.content"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="项目参与人" prop="participateUserIds"  v-if="setting.tableShow.projectStakeholder">
        <LeaderSelect v-model="form.participateUserIds" :defaultChecked="form.director" :multiple='true'></LeaderSelect>

      </el-form-item>
      <!-- <el-form-item label="所属部门" prop="departmentId" >
        <div>{{form.department}}</div>
      </el-form-item> -->
      <el-form-item label="关联公司" prop="companyId"  :rules="{ required: true, message: '请选择关联公司', trigger: 'blur' }">
        <CompanySelect v-model="form.companyId" placeholder="请选择用户关联子公司" :inline="false" clearable/>
      </el-form-item>
       <el-form-item label="计划工期"  :rules="{ required: true, message: '请选择计划工期', trigger: 'blur' }">
         <div style="display:flex;">
            <el-date-picker style="width:100% !important" v-model="dateArray" type="daterange"  value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            <!-- <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="form.planBegin" :disabledDate="(time)=>{return time.getTime()<new Date(form.planEnd).getTime()}"></el-date-picker> 至
            <el-date-picker type="date" value-format="yyyy-MM-dd" :disabledDate="(time)=>{return time.getTime()>new Date(form.planBegin).getTime()}" placeholder="选择日期" v-model="form.planEnd" ></el-date-picker> -->
         </div>

      </el-form-item>
      <el-form-item label="总计天数" prop="planDays"  :rules="{ required: true, message: '请填写计划工期', trigger: 'blur' }">
        {{form.planDays+'天'}}
      </el-form-item>
      <el-form-item label="项目说明" prop="explains"  v-if="setting.tableShow.projectExplain">
        <el-input v-model="form.explains" placeholder="请输入" v-trim maxlength="50"/>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="form.remark" placeholder="请输入" v-trim maxlength="50"/>
      </el-form-item>
      <h2>项目预算</h2>
      <el-form-item label="预计总收入" prop="estimateIncome"  v-if="setting.tableShow.estimatedTotalRevenue">
        <el-input type="number" v-model.trim="form.estimateIncome"  placeholder="请输入"  v-trim maxlength="50"/>
      </el-form-item>

      <div v-if="setting.tableShow.estTtExpendAndType &&form.expenditures&& form.expenditures.length>0">
        <div v-for=" (item,index) in form.expenditures" :key='index'>
          <el-form-item :label="item.label" :prop='item.label'>
            <el-input type="number" v-model.trim="form.expenditures[index].plan" placeholder="请输入"   v-trim maxlength="50"/>
          </el-form-item>
        </div>
      </div>

      <el-form-item label="预计总支出" prop="estimateExpenditure" v-if="setting.tableShow.estTtExpendAndType">
        {{form.estimateExpenditure}}
        <!-- <el-input v-model.trim="form.estimateExpenditure"  placeholder="请输入"  v-trim maxlength="50"/> -->
      </el-form-item>

      <!-- <el-form-item label="附件" prop="enclosure">
        <FileUpload></FileUpload>
      </el-form-item>  -->
      <el-form-item label="附件"   v-if="setting.tableShow.enclosure">
        <div  >
          <FileUpload v-model='form.enclosures'></FileUpload>
          <FileUploadTable :data='form.enclosures' @delete="(e)=>{this.form.enclosures.splice(e.index,1)}"></FileUploadTable>
        </div>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import { mapState, mapMutations } from 'vuex'
import RoleSelect from '../../common/RoleSelect'
import LeaderSelect from '../../common/LeaderSelectProject'
import { projectDetailQuery } from '@/api/bussiness/project'

import FileUpload from '../../common/FileUpload'
import FileUploadTable from '../../common/FileUploadTable'

import CompanySelect from '../../common/CompanySelect'

// import
export default {
  name: 'AddProject',
  extends: BaseOpera,
  components: { GlobalWindow, RoleSelect, LeaderSelect, FileUpload, CompanySelect, FileUploadTable },
  computed: {
    ...mapState({
      setting: state => state.setting
    })
  },
  watch: {
    setting: {
      handler (val) {
        console.log(val, '--ala-', this.setting)
        if (val.tableShow.estTtExpendAndType && this.form.id == null) {
          console.log(val.expenditureTypes, '-----支出类型')
          if (val.expenditureTypes) {
            this.form.expenditures = []
            val.expenditureTypes.map(item => {
              this.form.expenditures.push({
                type: item.id,
                label: item.content,
                plan: null
              })
            })
          }

          //
        }
        console.log(this.form.expenditures, '------数据变换')
      },
      deep: true,
      immediate: true
    },
    form: {
      handler (val) {
        let count = 0
        if (this.form.expenditures) {
          this.form.expenditures.map(i => {
            count = count + Number(i.plan)
          })
        }

        this.form.estimateExpenditure = count

        if(this.form.enclosures){
          this.form.enclosures.map(i => {
            i.delete = true
          })
        }
      },
      deep: true
    },
    dateArray: {
      handler (val, old) {
        console.log(val, old)
        if (val != old) {
          if (val) {
            this.form.planBegin = val[0]
            this.form.planEnd = val[1]
          } else {
            this.form.planBegin = ''
            this.form.planEnd = ''
            this.form.planDays = ''
          }
        }
      }
    },
    'form.planBegin': {
      handler (val, olv) {
        if (val) {
          if (this.form.planEnd) {
            const diff = (new Date(this.form.planEnd).getTime() - new Date(this.form.planBegin).getTime()) / 1000
            const dayDiff = Math.floor(diff / 86400) + 1
            console.log(dayDiff, '----几天')
            this.form.planDays = dayDiff
            this.dateArray = [this.form.planBegin, this.form.planEnd]
          } else {
            this.form.planDays = ''
          }
        } else {
          this.form.planDays = ''
        }
      },
      deep: true,
      immediate: true
    },
    'form.planEnd': {
      handler (val, olv) {
        if (val) {
          if (this.form.planBegin) {
            const diff = (new Date(this.form.planEnd).getTime() - new Date(this.form.planBegin).getTime()) / 1000
            const dayDiff = Math.floor(diff / 86400) + 1
            console.log(dayDiff, '----几天')
            this.form.planDays = dayDiff
            this.dateArray = [this.form.planBegin, this.form.planEnd]
          } else {
            this.form.planDays = ''
          }
        }
      },
      deep: true,
      immediate: true// 解决 编辑项目时，planDays返回为空的问题
    }
  },
  data () {
    return {
      // visible:false,
      // 原角色码
      originRoleCode: '',
      searchForm: {
        account: '', // 名字
        realname: '', // 姓名
        rootDeptId: null, // 部门ID
        positionId: null, // 岗位ID
        mobile: '' // 手机号码
      },
      dateArray: '', // 计划工期
      // 表单数据
      form: {
        id: null,
        projectType: null, // 项目类型
        projectNo: null, // 项目编号
        projectName: null, // 项目名称
        director: null, // 负责人
        projectLevel: null, // 项目级别
        participateUserIds: [], // 参与人
        departmentId: null, // 所属部门
        planBegin: '', // 计划时间
        planEnd: '',
        planDays: '',
        explains: null, // 项目说明
        remark: null, // 备注

        estimateIncome: null, // 预计收入
        expenditures: [], // 支出项

        estimateExpenditure: null, // 预计总支出
        enclosures: null, // 附件
        companyId: null// 关联公司
      },
      fileList: [
      ],
      // 验证规则
      rules: {
        code: [
          { required: true, message: '请输入角色编码' }
        ],
        name: [
          { required: true, message: '请输入角色名称' }
        ]
      }
    }
  },
  methods: {
    open (title, target) {
      this.title = title
      this.visible = true
      // 新建
      if (target == null) {
        this.$nextTick(() => {
          // this.$refs.form.resetFields()
          this.form = {
            id: null,
            projectType: null, // 项目类型
            projectNo: null, // 项目编号
            projectName: null, // 项目名称
            director: null, // 负责人
            projectLevel: null, // 项目级别
            participateUserIds: [], // 参与人
            departmentId: null, // 所属部门
            planBegin: '', // 计划时间
            planEnd: '',
            planDays: '',
            explains: null, // 项目说明
            remark: null, // 备注

            estimateIncome: null, // 预计收入
            expenditures: [], // 支出项

            estimateExpenditure: null, // 预计总支出
            enclosures: null, // 附件
            companyId: null// 关联公司
          }
          this.form[this.configData['field.id']] = null
          this.form.username = this.$store.state.init.userInfo.username
          this.form.director = this.$store.state.init.userInfo.id

          this.$store.state.setting.expenditureTypes.map(item => {
            this.form.expenditures.push({
              type: item.id,
              label: item.content,
              plan: null
            })
          })
        })
        return
      }
      // 编辑
      this.$nextTick(() => {
        projectDetailQuery({ projectId: target.id }).then(target => {
          console.log(target);
          if(target.enclosures){
            target.enclosures.map(item => {
              console.log(item);
              item =  Object.assign({
                ...item,
                delete:true
              })

              console.log(item,'--sd-s-d-s-d-s-d-');
            })
          }
          for (const key in this.form) {
            this.form[key] = target[key]
          }
          this.form.director = target.directorId
          this.form.username = target.directorName
          // this.form.participateUserIds = this.form.participateUserIds.filter(i=>i!=target.directorId)
          const expenditures = []
          if (target.expenditure) {
            target.expenditure.map(item => {
              expenditures.push({
                type: item.type,
                label: item.name,
                plan: item.plan
              })
            })
          }

          console.log(this.form.enclosures,'---------------附件');
          
          this.form.expenditures = expenditures
        })
      })
    },
    confirm () {
      this.__confirmCreate()
    },
    getLeaderInfo (e) {
      console.log('用户信息', e)
      // if(e.department){
      //   console.log('修改了部门');
      //   this.form.departmentId = e.department.id;
      //   this.form.department = e.department.name;
      // }else{
      //   this.form.departmentId = null;
      //   this.form.department = null;
      // }
    }
  },
  created () {
    console.log('this  setting', this.$store.state.setting)
    this.config({
      api: '/bussiness/project'
    })
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-form-item{
  display: flex;
}
/deep/ .el-form-item__content{
  margin-left: 0 !important;
  flex: 1;
}
h2{
  font-size: 14px;
  font-family:'Microsoft YaHei';
  font-weight: 400;
  color: #333333;
  padding-bottom: 12px;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 12px;
}
</style>
