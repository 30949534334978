<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
    :withFooter='true' 
  >

    <el-form :model="form" ref="form" :rules="rules" label-width="120px" label-position="right" style="width:100%" >
 
      <el-form-item label="周报名称" prop="weeklyName" :rules="{ required: true, message: '请输入周报名称', trigger: 'blur' }">
        <el-input v-model="form.weeklyName" placeholder="填写报告名称" v-trim maxlength="50"/>
      </el-form-item>
      <el-form-item label="报告人" prop="creator" >
        {{form.username}}
        <!-- <el-input v-model="form.username" placeholder="请输入" v-trim maxlength="50" disabled/> -->
      </el-form-item>
      <el-form-item label="报告对象" prop="mainDelivery" :rules="{ required: true, message: '请选择报告对象', trigger: 'blur' }">
        <LeaderSelect v-model="form.mainDelivery" placeholder="请选择报告对象" :exclude-id="form.creator"></LeaderSelect>

        <!-- <el-input v-model="form.remark" type="textarea" placeholder="请输入里程碑名称" :rows="3" v-trim maxlength="500"/> -->
      </el-form-item>
      <el-form-item label="抄送对象" prop="userId" >
        <!-- :rules="{ required: true, message: '请选择抄送对象', trigger: 'blur' }" -->
        <LeaderSelect v-model="form.userId" placeholder="请选择抄送对象" :exclude-id="form.mainDelivery" :multiple='true'></LeaderSelect>

        <!-- <el-input v-model="form.remark" type="textarea" placeholder="填写里程碑说明" :rows="3" v-trim maxlength="500"/> -->
      </el-form-item>

      <el-form-item label="报告内容" prop="content" :rules="{ required: true, message: '请输入报告内容', trigger: 'blur' }">
        <el-input type="textarea" v-model="form.content" placeholder="填写报告内容" v-trim maxlength="50"/>
      </el-form-item>
      

      <el-form-item label="上传文件"  >
        <div>
          <FileUpload v-model='form.enclosureDtoList'></FileUpload>
          <FileUploadTable :data='form.enclosureDtoList' @delete="(e)=>{this.form.enclosureDtoList.splice(e.index,1)}"></FileUploadTable>
        </div>
      </el-form-item>

      
     
    </el-form>
    <div slot="footer">
      <el-button @click="confirm(5)"  type="primary">保存</el-button>
      <el-button @click="confirm(6)" type="primary">保存并提交</el-button> 
    </div>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import LeaderSelect from '../../common/LeaderSelectProject'
import FileUpload from '../../common/FileUpload'
import FileUploadTable from '../../common/FileUploadTable'


export default {
  name: 'OperaRoleWindow',
  extends: BaseOpera,
  components: { GlobalWindow , LeaderSelect ,  FileUpload , FileUploadTable ,  },
  data () {
    return {
      // 原角色码
      // originRole: '',
      // 表单数据
      form: {
        id: null,
        weeklyName: '',
        creator:'',
        projectId: '',
        mainDelivery:null,
        userId: [],
        enclosureDtoList:[],
        content:'',
        weeklyStatus:1,//1保存 2 保存提交  3 

      },
      // 验证规则
      rules: {
        weeklyName:[
          { required: true, message: '请输入周报名称' }
        ],
        // code: [
        //   { required: true, message: '请输入角色编码' }
        // ],
        // name: [
        //   { required: true, message: '请输入角色名称' }
        // ]
      }
    }
  },
  methods: {
    open (title, target) {
      this.title = title
      this.visible = true
      // 新建
      if (target == null) {
        this.$nextTick(() => {
          this.$refs.form.resetFields()
          this.form = {
              id: null,
              weeklyName: '',
              creator:'',
              projectId: '',
              mainDelivery:null,
              userId: [],
              enclosureDtoList:[],
              content:'',
              weeklyStatus:1,//1保存 2 保存提交  3 
            }
          this.form[this.configData['field.id']] = null
          this.form.creator = this.$store.state.init.userInfo.id
          this.form.username = this.$store.state.init.userInfo.username
          this.form.projectId = this.$route.query.pid
        })
        return
      }
      // 编辑
      console.log(target,'周报编辑内容');
      this.$nextTick(() => {
        this.originRoleCode = target.code
        let userList = []
        if(target.userVoList){
          target.userVoList.map(i=>{
            userList.push(i.id)
          })
        }
        for (const key in this.form) {
          this.form[key] = target[key]
          this.form.username = target.creatorName
          this.form.userId = userList
          this.form.enclosureDtoList = target.enclosureVoList
          
        }
      })
    },
    confirm (weeklyStatus) {
      this.form.weeklyStatus = weeklyStatus;
      this.__confirmCreate()
    }
  },
  created () {
    console.log(this.$route.query.pid,'周报route');
    // if(!this.form.username){
    //   this.$tip.apiFailed('当前用户信息获取失败')
    // }
    this.config({
      api: '/bussiness/weekLog'
    })
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-form-item{
  display: flex;
}
/deep/ .el-form-item__content{
  margin-left: 0 !important;
  flex: 1;
}
a{
  cursor: pointer;
}
</style>