<template>
  <div class="wrap">
    <div class="ScaleBox"
         ref="ScaleBox"
         :style="{
          width,
          height
        }">
    <div class="fullScreen" @click="fullScreen">全屏</div>
        <bigScreen style="margin:auto 0"></bigScreen>
    </div>
  </div>
</template>

<script>
import bigScreen from './manage/bigScreen'
import screenfull from "screenfull";

export default {
  name: "ScaleBox",
  components:{bigScreen},
  props: {
    width: {
      type: Number,
      default: 1920
    },
    height: {
      type: Number,
      default: 866
    }
  },
  data() {
    return {
      scale: null
    };
  },
  mounted() {
    this.setScale();
    window.addEventListener("resize", this.setScale);
  },
  methods: {
    getScale() {
      const { width, height } = this;
      let ww = window.innerWidth / width;
      let wh = window.innerHeight / height;
      return ww < wh ? ww : wh;
    },
    setScale() {
      this.scale = this.getScale();
      this.$refs.ScaleBox.style.setProperty("--scale", this.scale);
    },
    debounce(fn, delay) {
      let delays = delay || 500;
      let timer;
      return function() {
        let th = this;
        let args = arguments;
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(function() {
          timer = null;
          fn.apply(th, args);
        }, delays);
      };
    },
    fullScreen(){
      // 判断是否支持
      // if (!screenfull.enabled) {
      //   this.$message({
      //     message: "不支持全屏",  
      //     type: "warning"
      //   });
      //   return false;
      // }
      screenfull.toggle();
    },
  }
};
</script>

<style lang="scss" scoped>

.fullScreen{
  position: absolute;
  top: 0%;
  right: 20px;
  background: #def;
  border-radius: 50%;
  padding: 40px;
  z-index: 22222;
  cursor: pointer;
  opacity: 0;
  &:hover{
    opacity:1;
  }
}
#ScaleBox {
  --scale: 1;
}
.wrap {
  background: #e4e4e4;
  width: 100%;
  // height: 5000px;
  height: 8000px;
}
.ScaleBox {
  transform: scale(var(--scale)) translate(-50%, 0%);
  display: flex;
  height: 100%;
  flex-direction: column;
  transform-origin: 0 0;
  position: absolute;
  left: 50%;
  // top: 50%;
  transition: 0.3s;
  z-index: 999;
}
</style>