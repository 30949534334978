<template>
  <TableLayout :withBreadcrumb="false">
 <!-- :permissions="['system:user:query']" -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="80px" inline>
      <el-form-item label="项目资料" prop="name">
        <el-input v-model="searchForm.name" v-trim placeholder="请输入项目资料" @keypress.enter.native="fetchList"/>
      </el-form-item>
      <section>
        <el-button type="primary" icon="el-icon-search" @click="fetchList">搜索</el-button>
        <!-- <el-button @click="reset">重置</el-button> -->
      </section>
    </el-form>
    <template v-slot:table-wrap>
      <ul class="toolbar" v-if="projectBtns">
         <!-- -->
        <li  v-if="data.isUpdate">
          <!-- <el-button icon="el-icon-plus" type="primary" @click="">上传资料</el-button> -->
          <FileUpload  v-model='upLoadFile' :max="1" :projectId="projectId"></FileUpload>  
          <!-- 单文件上传 不然改逻辑 -->
          <!-- v-model='form.enclosureEntityList' -->
        </li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="data.enclosureList"
        :default-sort = "{prop: 'createTime', order: 'descending'}"
        stripe
        border
       
      >
       <!-- @sort-change="handleSortChange"
        @selection-change="handleSelectionChange"
        <el-table-column type="selection" width="55" ></el-table-column> -->
        <el-table-column align="center" prop="name" label="资料名称" min-width="100px" ></el-table-column>
        <el-table-column align="center" prop="typeName" label="类型" min-width="120px"></el-table-column>
        <el-table-column align="center" prop="belonging" label="所属" min-width="120px"></el-table-column>

        <el-table-column align="center" prop="size" label="大小" min-width="120px">
          <div  slot-scope="{row}">
            <span v-if="row.size / 1024 / 1024 >= 1">{{(row.size / 1024 / 1024).toFixed(2)+'M'}}</span>
            <span v-else>{{(row.size / 1024).toFixed(2)+'KB'}}</span>
          </div>
        </el-table-column>
        <el-table-column align="center" prop="operatorName" label="上传人" min-width="120px"></el-table-column>
        <el-table-column align="center" prop="operatorTime" label="上传时间" min-width="100px" ></el-table-column>
 <!-- v-if="containPermissions(['system:user:update', 'system:user:createUserRole', 'system:user:resetPwd', 'system:user:delete'])" -->
        <el-table-column align="center"
          label="操作"
          width="270"
          fixed="right"
        >
          <div  slot-scope="{row}">
            <el-button v-if="row.isUpdate"  type="text"  @click="$refs.addProjectDataType.open('设置资料类型',{id:row.id,tableTitle:row.tableTitle})" >设置类型</el-button>
            <el-button type="text"><a :href="row.path" download="">下载</a></el-button>
            <el-button v-if="row.isUpdate"  type="text"  @click="deleteEnclosureById(row.id,row.tableTitle)" >删除</el-button>
          </div>
        </el-table-column>
      </el-table>
      <!-- <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination> -->
    </template>
    <!-- 新建/修改 -->
    <AddProjectDataType ref="addProjectDataType" @success="fetchList"/>
  </TableLayout>
</template>

<script>
import Pagination from '@/components/common/Pagination'
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import AddProjectDataType from '@/components/bussiness/projectAddModal/AddProjectDataType'
import FileUpload from '../../common/FileUpload'
import {fetchList,deleteEnclosureById,updateEnclosureType,saveEnclosure} from '../../../api/bussiness/projectData'
// import {} from '../../api/bussiness/projectData'


export default {
  name: 'project_data',
  extends: BaseTable,
  components: { AddProjectDataType, TableLayout, Pagination, FileUpload , },
   props: {
     projectBtns:{
      type:Boolean,
      default:true
    }
  },
  data () {
    return {
        searchForm: {
            name:''
        },
        upLoadFile:[],//上传的文件
        activeItem:0,
        data:[],
        projectId:this.$route.query.pid
    }
  },
  computed: {

  },
  watch:{
    upLoadFile:{
      handler(val){
        if(val.length>0){
          let list =[];
          val.map(i=>{
            list.push({
              name:i.name,
              path:i.path,
              size:i.size,
              projectId:this.projectId
            })
          })
          this.saveEnclosure(list)
        }
      },
      immediate:true
    }
  },
  created(){
    this.fetchList()

  },
  methods: {
    fetchList(){
      fetchList({projectId:this.$route.query.pid,name:this.searchForm.name}).then(res=>{
        console.log(res,'---资料');
        this.data = res;
      }).catch(e => {
        this.$tip.apiFailed(e)
      })
    },
    saveEnclosure(list){
      saveEnclosure(list).then(res=>{
        console.log('成功后删除啥啥啥');
        this.upLoadFile = []
        this.fetchList();
      }).catch(e=>{
        this.$tip.apiFailed(e)
      }).finally(()=>{
        this.upLoadFile = []
      })
    },

    deleteEnclosureById(id,tableTitle){
      this.$dialog.confirm('确定删除当前的资料吗？', '提示', {
        confirmButtonText: '确认',
        type: 'warning'
      }).then(() => {
      deleteEnclosureById({
        id:id,
        tableTitle:tableTitle
      }).then(res=>{
            this.$tip.apiSuccess('删除成功')
            this.fetchList()
          }).catch(e=>{
            this.$tip.apiFailed(e)
          }).finally(()=>{
          })
        })
    },
  }
}
</script>

<style lang="scss">

</style>
<style scoped lang="scss">
.tabs{
    .tab_tab{
        display: flex;
        justify-content: space-around;
        .tabItem{
           font-weight: 400; 
           cursor: pointer;
        }
        .activeItem{
            font-weight: bold;
        }
    }
}
.sumInfo{
  color: #333333;
  font-size: 18px;
  font-family: 'Microsoft YaHei';
  font-weight: bold;
  /deep/ .el-timeline-item__tail{
    border-left: 2px dashed #999999;
  }
  /deep/ .el-timeline-item__timestamp{
    color: #333333 ;
    font-weight: 400;
    font-size: 18px !important;
  }
}


</style>
