<template>
  <div class="wrap">
    <div class="center">
      <div class="imgs">

      </div>
      <div class="login">
        <h1>项目管理系统</h1>
        <!-- <div style="height:150px;background:#edc;margin-top:-80px"></div> -->
        <div class="info-input">
          <el-input v-model="account " placeholder="请输入账号(手机号)" prefix-icon="el-icon-user-solid" maxlength="50" v-trim/>
          <el-input v-model="password" placeholder="请输入密码" type="password" prefix-icon="eva-icon-password" maxlength="30" show-password/>
          <!-- <div class="captcha-input">
            <el-input v-model="captcha.value" placeholder="图片验证码" prefix-icon="eva-icon-shield" maxlength="4" @keypress.enter.native="login"/>
            <img v-if="!captcha.loading" :src="captcha.uri" @click="refreshCaptcha">
            <span v-else><i class="el-icon-loading"></i></span>
          </div> -->
        </div>
        <el-button :loading="loading" @click="login">登&nbsp;&nbsp;录</el-button>
      </div>
      <div class="fixed">
        <a href="https://beian.miit.gov.cn" target="_blank" style="color:#555555">备案号：陕ICP备19004372号-8</a>
      </div>
    </div>

  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { getCaptcha, loginByPassword } from '@/api/system/common'

export default {
  name: 'Login',
  data () {
    return {
      loading: false,
      account : '',
      password: '',
      // 验证码
      captcha: {
        loading: false,
        value: '',
        uuid: '',
        uri: ''
      }
    }
  },
  methods: {
    ...mapMutations(['setUserInfo']),
    // 登录
    login () {
      // window.location.href = '/'
      // return;
      if (this.loading) {
        return
      }
      if (!this.__check()) {
        return
      }
      this.loading = true
      loginByPassword({
        account : this.account.trim(),
        password: this.password,
        // code: this.captcha.value.trim(),
        // uuid: this.captcha.uuid
      })
        .then((res) => {
          console.log(res,'登陆成功');
          // return 
          window.location.href = '/'
        })
        .catch(e => {
          this.refreshCaptcha()
          this.$tip.apiFailed(e)
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 刷新验证码
    refreshCaptcha () {
      this.captcha.loading = true
      getCaptcha()
        .then(data => {
          this.captcha.uri = data.image
          this.captcha.uuid = data.uuid
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
        .finally(() => {
          setTimeout(() => {
            this.captcha.loading = false
          }, 150)
        })
    },
    // 登录前验证
    __check () {
      if (this.account .trim() === '') {
        this.$tip.error('请输入账号')
        return false
      }
      if (this.password === '') {
        this.$tip.error('请输入密码')
        return false
      }
      // if (this.captcha.value.trim() === '') {
      //   this.$tip.error('请输入图片验证码')
      //   return false
      // }
      return true
    }
  },
  created () {
    // this.refreshCaptcha()
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables.scss";
.fixed{
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  text-align: center;
  
}
$input-gap: 30px;
.wrap {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/images/loginbg.png");
  background-repeat: no-repeat;
  background-size: auto 180%;
  background-clip: content-box;
  background-position: center;
  position: relative;
  .center{
    position: absolute;
    width: 510px;
    height:auto;
    left: calc(50% - 255px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .imgs{
      height: 310px;
      width: 260px; 
      background-image: url("../assets/images/logo.png");
      background-repeat: no-repeat;
      background-size: auto 100%;
      background-clip: content-box;
      background-position: center;
      margin-bottom: 20px;
    }
  }

  // 右边登录
  .login {
    height: 100%;
    width: 38%;
    
    max-width: 560px;
    min-width: 460px;
    flex-shrink: 0;
    text-align: center;
    background: #fff;
    padding: 20px 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    box-shadow: 0px 4px 49px 0px rgba(27, 100, 74, 0.1);
    border-radius: 20px;
    h1 {
      font-size: 36px;
      font-weight: 500;
      height: 65px;
      color: #1A69FC;
      background: #fff;
      // text-shadow: 12px 12px  #1A69FC ;
      // -webkit-box-reflect:below -4px linear-gradient( to bottom,#1a69fc12 1%, white 500%, white );
      overflow: hidden;
    }
    .info-input {
      margin-top: -20px;
      margin-bottom: 60px;
      background: #fff;
      /deep/ .el-input {
        margin-top: 30px;
        .el-input__inner {
          height: 50px;
          background: #F9F9F9;
          border: 1px solid transparent;
          &:focus {
            border: 1px solid $primary-color;
          }
        }
      }
    }
    // 验证码输入
    .captcha-input {
      display: flex;
      margin-top: $input-gap;
      height: 50px;
      .el-input {
        width: 100%;
        margin-top: 0;
      }
      span, img {
        width: 45%;
        height: 100%;
        flex-shrink: 0;
        margin-left: 16px;
      }
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f7f7f7;
        border-radius: 8px;
      }
    }
    .el-button {
      height: 50px;
      width: 100%;
      color: #fff;
      font-size: 16px;
      background: linear-gradient(130deg, $primary-color + 20 0%, $primary-color - 20 100%);
    }
  }
}
</style>
