<template>
    <el-table border :data="data">
        <el-table-column :prop="label.name" label="资料名称" align="center"></el-table-column>
        <el-table-column :prop="label.size" label="大小" align="center">
          <div  slot-scope="{row}">
            <span v-if="row.size / 1024 / 1024 >= 1">{{(row.size / 1024 / 1024).toFixed(2)+'M'}}</span>
            <span v-else>{{(row.size / 1024).toFixed(2)+'KB'}}</span>
          </div>
        </el-table-column>
        <!-- <el-table-column :prop="label.time" label="操作时间" align="center"></el-table-column> -->
        <el-table-column  label="操作" align="center">
            <div slot-scope="{row,$index}" class="flexAround">
                <!-- <a href="https://zlt-2021.oss-cn-zhangjiakou.aliyuncs.com/slpm/2021/08/09/A面.png" download>下载</a> -->
                <a :href="row.path" download>下载</a>
                <a v-if="row.delete" @click="$emit('delete',{data:row,index:$index})">删除</a>
            </div>
        </el-table-column>
    </el-table>
</template>
<script>

export default {
  data () {
    return {
      fileList: [],
      loading: false
    }
  },
  props: {
    data: {
      default: () => []
    },
    label: {
      type: Object,
      default: () => {
        return { name: 'name', size: 'size', time: 'operatorTime' }
      }
    },
    deleteType: {
      default: 'uploaded'// uploading,uploaded
    }
  },
  watch: {
    fileList: {
      handler (val) {
        console.log(val, 'watch')
        console.log(this.fileList)
        this.$emit('input', this.fileList)
      },
      deep: true,
      immediate: true
    },
    data:{
      handler(val){
        console.log(val,this.data,'-a-s-a-s-');
        this.fileList = this.data
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    handleRemove (file, fileList) {
      console.log('remove')
      this.fileList = fileList
    },
    handlePreview (file) {
      console.log('preview')
      console.log(file)
    },
    handleExceed (files, fileList) {
      console.log(files, '--handleExceed-')
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    beforeRemove (file, fileList) {
      // return
    },
    beforeUpload (file) {
      console.log(file, '上传的文件')

      const isLt2M = file.size / 1024 / 1024 < 5
      // const fileTypes = [".jpg", ".png","jpeg"];
      const filePath = file.name // 当括号里面的值为0、空字符、false 、null 、undefined的时候就相当于false
      // if(filePath){
      //     var isNext = false;
      //     var fileEnd = filePath.substring(filePath.indexOf("."));
      //     for (var i = 0; i < fileTypes.length; i++) {
      //         if (fileTypes[i] == fileEnd) {
      //             isNext = true;
      //             break;
      //         }
      //     }
      //     if (!isNext){
      //         this.$message.error('图片后缀应为.jpg,.png,.jpeg');
      //         file.value = "";
      //         return false;
      //     }
      // }else {
      //     this.$message.error('图片异常');
      // }

      // if (!isLt2M) {
      //   this.$message.error('上传头像图片大小不能超过 5MB!');
      //   return false
      // }
      // return filePath && isLt2M;
    },
    customUpload (option) {
      this.loading = true
      const self = this
      const formData = new FormData()
      formData.append('file', option.file)
      const size = option.file.size
      // if(option.file.size / 1024 / 1024 >= 1){
      //   size = (option.file.size / 1024 / 1024).toFixed(2)+'M'
      // }else{
      //   size = (option.file.size / 1024 ).toFixed(2)+'KB'
      // }

      uploadImage(formData).then((res) => {
        this.fileList.push({
          name: option.file.name,
          path: res,
          size: size,
          delete: true
        })
        this.imageUrl = res
      }).catch(e => {
        this.$message.error('上传文件失败')
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
