<template>
<el-row class="sumInfo">
  <el-col :span="12">
    <div class="left">
      <div class="block" >
        <div class="blockTitle">
          <i class="el-icon-reading"></i>
          <span class="">饼状图进度</span>
        </div>
        <div class="blockContent contentChart" id='contentChart' style="width:100%;height:200px">
          <div id='contentChart1' class="chartItem">
            <!-- <el-progress type="circle" :percentage="25"></el-progress> -->
          </div>
          <div id='contentChart2' class="chartItem">
            <!-- <el-progress type="circle" :percentage="100" status="success"></el-progress> -->
          </div>
          <div id='contentChart3' class="chartItem">
            <!-- <el-progress type="circle" :percentage="100" status="success"></el-progress> -->
          </div>
        </div>
      </div>
      <div class="block" >
        <div class="blockTitle">
          <i class="el-icon-reading"></i>
          <span class="">项目进度</span>
        </div>
        <div class="blockContent contentBlocks">
          <div class="blockItem">
            <div class="blockItemTitle">里程碑数：<span>{{data.milepostEnclosureNum?data.milepostEnclosureNum:0}}</span></div>
            <div class="blockItemCon">
              <div class="blockItemConItem">
                <div class="blockItemConAbs">达成个数</div>
                <div class="blockItemConCen"><span>{{data.milepostEnclosureCmptNum?data.milepostEnclosureCmptNum:0}}</span>个</div>
              </div>
              <div class="blockItemConItem">
                <div class="blockItemConAbs">达成比例</div>
                <div class="blockItemConCen"><span>{{data.milepostEnclosureCmptRate?data.milepostEnclosureCmptRate:0}}%</span></div>
              </div>
            </div>
          </div>
          <div class="blockItem">
            <div class="blockItemTitle">任务个数：<span>{{data.taskNum?data.taskNum:0}}</span></div>
            <div class="blockItemCon">
              <div class="blockItemConItem">
                <div class="blockItemConAbs">未完成</div>
                <div class="blockItemConCen"><span>{{data.taskDoingNum?data.taskDoingNum:0}}</span>个</div>
              </div>
              <div class="blockItemConItem">
                <div class="blockItemConAbs">已完结</div>
                <div class="blockItemConCen"><span>{{data.taskCmptNum?data.taskCmptNum:0}}</span>个</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="block" >
        <div class="blockTitle">
          <i class="el-icon-reading"></i>
          <span class="">项目预算</span>
        </div>
        <div class="blockContent contentSimp">
          <div class="blockItem">
            <span class="title">预计收入：</span>
            <span class="value">{{data.estimateIncome}}</span>
            <span class="unit">元</span>
          </div>
          <div class="blockItem">
            <span class="title">预计支出：</span>
            <span class="value">{{data.estimateExpenditure}}</span>
            <span class="unit">元</span>
          </div>
        </div>
      </div>

      <div class="block" >
        <div class="blockTitle">
          <i class="el-icon-reading"></i>
          <span class="">项目人数</span>
        </div>
        <div class="blockContent contentSimp">
          <div class="blockItem">
            <span class="title">成员人数：</span>
            <span class="value">{{data.projectUserNum}}</span>
            <span class="unit">人</span>
          </div>
        </div>
      </div>

      <div class="block" >
        <div class="blockTitle">
          <i class="el-icon-reading"></i>
          <span class="">项目级动态</span>
        </div>
        <div class="blockContent contentLine">
          <!-- 项目级动态、任务动态、里程碑动态 -->
          <div class="tabs">
            <span :class="lineActive==0?'tabAct':'' " @click='lineActive=0'>项目级动态</span>
            <span :class="lineActive==1?'tabAct':'' " @click='lineActive=1'>任务动态</span>
            <span :class="lineActive==2?'tabAct':'' " @click='lineActive=2'>里程碑动态</span>
          </div>
          <el-timeline  class="timeLine">
            <el-timeline-item
              placement="top"
              v-for="(item, index) in lineActive==0?data.projectRecords:lineActive==1?data.taskRecords:data.milepostRecords"
              :key="index"
              icon="el-icon-time"
              size="large"
              color="#999999"
              :timestamp="item.operatorTime">
              <div class="content">
                <img class="avator" :src="item.imageUrl" alt="">
                <div class="info">
                  <div class="name">{{item.operatorName}}</div>
                  <div class="content">
                    <span style="display:block"  v-for="i in item.explains.split('<br />')">{{i}}</span>

                    <!-- {{item.explains}} -->
                    </div>
                </div>
              </div>
            </el-timeline-item>
          </el-timeline>

        </div>
      </div>

    </div>
  </el-col>

  <el-col :span="12">
    <div class="right">
      <div class="block" >
        <div class="blockTitle">
          <i class="el-icon-reading"></i>
          <span class="">基本信息</span>
        </div>
        <div class="blockContent contentBasicInfo">
          <div class="blockItem">
            <div class="title">项目类型:</div>
            <div class="content">{{data.projectTypeName}}</div>
          </div>
          <div class="blockItem" v-if="data.projectNo">
            <div class="title">项目编号:</div>
            <div class="content">{{data.projectNo}}</div>
          </div>
          <div class="blockItem">
            <div class="title">项目名称:</div>
            <div class="content">{{data.projectName}}</div>
          </div>
          <div class="blockItem">
            <div class="title">负责人:</div>
            <div class="content">{{data.directorName}}</div>
          </div>
          <div class="blockItem">
            <div class="title">项目级别:</div>
            <div class="content">{{data.projectLevelName?data.projectLevelName:'-'}}</div>
          </div>
          <div class="blockItem">
            <div class="title">关联公司:</div>
            <div class="content">{{data.belongCompanyName}}</div>
          </div>
          <div class="blockItem">
            <div class="title">计划工期:</div>
            <div class="content">{{data.planDuration}}</div>
          </div>
          <div class="blockItem">
            <div class="title">总计天数:</div>
            <div class="content">{{data.planDurationDays}}天</div>
          </div>
          <div class="blockItem">
            <div class="title">项目说明:</div>
            <div class="content">{{data.explains}}</div>
          </div>
          <div class="blockItem">
            <div class="title">备注:</div>
            <div class="content">{{data.remark}}</div>
          </div>
        </div>
      </div>
      <div class="block" >
        <div class="blockTitle">
          <i class="el-icon-reading"></i>
          <span class="">项目支出</span>
        </div>
        <div class="blockContent contentBlocks">
          <div class="blockItem">
            <div class="blockItemTitle">预计支出：<span >{{data.estimateExpenditure}}</span> <span class="unit">元</span></div>
            <div class="blockItemCon">
              <div class="blockItemConItem">
                <div class="blockItemConAbs">支出金额</div>
                <div class="blockItemConCen"><span>{{data.actualExpenditure}}</span>元</div>
              </div>
              <div class="blockItemConItem">
                <div class="blockItemConAbs">支出进度</div>
                <div class="blockItemConCen"><span>{{data.expenditureSpeed}}</span>%</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="block" >
        <div class="blockTitle">
          <i class="el-icon-reading"></i>
          <span class="">项目得分</span>
        </div>
        <div class="blockContent contentBlocks">
          <div class="blockItem">
            <div class="blockItemTitle">总分：<span >100</span> </div>
            <div class="blockItemCon">
              <div class="blockItemConItem">
                <div class="blockItemConAbs">扣除分数</div>
                <div class="blockItemConCen"><span>{{data.deductScore}}</span>分</div>
              </div>
              <div class="blockItemConItem">
                <div class="blockItemConAbs">得分</div>
                <div class="blockItemConCen"><span>{{data.score}}</span>分</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="block" >
        <div class="blockTitle">
          <i class="el-icon-reading"></i>
          <span class="">项目问题</span>
        </div>
        <div class="blockContent contentTable">
          <el-table border :data="data.questions">
            <el-table-column prop="questionName" label="问题名称" align="center"></el-table-column>
            <el-table-column prop="belongProjectName" label="所属项目" align="center"></el-table-column>
            <el-table-column prop="questionTypeName" label="问题类型" align="center"></el-table-column>
            <el-table-column prop="questionStatus" label="问题状态" align="center"></el-table-column>
            <el-table-column prop="mainDeliveryName" label="解决人" align="center"></el-table-column>
          </el-table>
        </div>
      </div>

      <div class="block" >
        <div class="blockTitle">
          <i class="el-icon-reading"></i>
          <span class="">周报</span>
        </div>
        <div class="blockContent contentTable">
          <el-table border :data="data.weeklys">
            <el-table-column prop="weeklyName" label="周报名称" align="center"></el-table-column>
            <el-table-column prop="creatorName" label="报告人" align="center"></el-table-column>
            <el-table-column prop="createDate" label="提交日期" align="center"></el-table-column>
            <el-table-column prop="mainDelivery" label="报告对象" align="center"></el-table-column>
          </el-table>
        </div>
      </div>

      <div class="block" >
        <div class="blockTitle">
          <i class="el-icon-reading"></i>
          <span class="">附件资料</span>
        </div>
        <div class="blockContent contentTable">
          <el-table border :data="data.enclosures">
            <el-table-column prop="name" label="资料名称" align="center"></el-table-column>
            <el-table-column prop="typeName" label="类型" align="center"></el-table-column>
            <el-table-column prop="size" label="大小" align="center">
              <div  slot-scope="{row}">
                <span v-if="row.size / 1024 / 1024 >= 1">{{(row.size / 1024 / 1024).toFixed(2)+'M'}}</span>
                <span v-else>{{(row.size / 1024).toFixed(2)+'KB'}}</span>
              </div>
            </el-table-column>
            <el-table-column prop="operatorTime" label="上传时间" align="center"></el-table-column>
          </el-table>
        </div>
      </div>

    </div>
  </el-col>

</el-row>

</template>

<script>
import * as echarts from 'echarts'
import { projectDetailShow } from '@/api/bussiness/project'

export default {
  name: 'project_sum_info',
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      visible: false,
      activities: [
        { content: '活动按期开始', timestamp: '2018-04-15', name: '郭宝宝', dept: '行政部' },
        { content: '通过审核', timestamp: '2018-04-13', name: '郭宝宝', dept: '行政部' },
        { content: '创建成功', timestamp: '2018-04-11', name: '郭宝宝', dept: '行政部' },
        { content: '创建成功', timestamp: '2018-04-11', name: '郭宝宝', dept: '行政部' },
        { content: '创建成功', timestamp: '2018-04-11', name: '郭宝宝', dept: '行政部' },
        { content: '创建成功', timestamp: '2018-04-11', name: '郭宝宝', dept: '行政部' },
        { content: '创建成功', timestamp: '2018-04-11', name: '郭宝宝', dept: '行政部' },
        { content: '创建成功', timestamp: '2018-04-11', name: '郭宝宝', dept: '行政部' },
        { content: '创建成功', timestamp: '2018-04-11', name: '郭宝宝', dept: '行政部' }
      ],
      lineActive: 0, // 默认项目级动态
      option: {
        series: [
          {
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false,
              itemStyle: {
                borderWidth: 1,
                borderColor: '#446'
              }
            },
            axisLine: {
              lineStyle: {
                width: 8
              }
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            data: [{
              value: 20, // value
              detail: {
                show: false
              },
              name: '工期进度',
              title: {
                offsetCenter: ['0%', '-20%']
              },
              detail: {
                offsetCenter: ['0%', '20%']
              }
            }
            ],
            detail: {
              formatter: '{value}%'
            }
          }

        ]
      }
    }// return
  },
  computed: {
    // 确认按钮文案
    confirmButtonText () {
      return this.allowCopy ? '复制' : '确定'
    },
    // 格式化后的内容
    formattedContent () {
      let content = this.content
      if (this.analyse) {
        try {
          content = JSON.stringify(JSON.parse(this.content), null, 2)
        } catch (e) {
        }
      }
      return content
    }
  },
  watch: {
    'data.projectSpeed': {
      handler (val) {
        this.getBar('contentChart1', 1)
      },
      immediate: true
    },
    'data.taskCompletionRate': {
      handler (val) {
        this.getBar('contentChart2', 2)
      },
      immediate: true
    },
    'data.expenditureSpeed': {
      handler (val) {
        this.getBar('contentChart3', 3)
      },
      immediate: true
    }
  },
  mounted () {
    projectDetailShow({ projectId: this.$route.query.pid }).then(res => {
      console.log('项目详情', res)
      this.$emit('updateInfo', res)
      // this.base = res;
    }).catch(e => {
      this.$tip.apiFailed(e)
    })
    // 1.工期进度 2.任务完成率 3.支出进度
    this.getBar('contentChart1', 1)
    this.getBar('contentChart2', 2)
    this.getBar('contentChart3', 3)
  },
  methods: {
    getBar (id, type) {
      var chartDom = document.getElementById(id)
      var myChart = echarts.init(chartDom)
      var option
      option = this.option
      option.series[0].data[0].name = type == 1 ? '工期进度' : type == 2 ? '任务完成率' : '支出进度'
      option.series[0].data[0].value = type == 1 ? this.data.projectSpeed : type == 2 ? this.data.taskCompletionRate : this.data.expenditureSpeed
      option.series[0].data[0].value = option.series[0].data[0].value == null ? 0 : option.series[0].data[0].value
      option && myChart.setOption(option)
    },
    // 点击确认
    confirm () {
      this.visible = false
      this.$emit('confirm')
    },
    // 点击取消
    cancel () {
      this.visible = false
      this.$emit('cancel')
    },
    // 复制成功
    copySuccess () {
      this.$tip.success('复制成功')
    },
    // 复制失败
    copyFailed () {
      this.$tip.error('复制失败')
    }
  }
}
</script>

<style lang="scss">

</style>
<style scoped lang="scss">
.sumInfo{
  color: #333333;
  font-size: 18px;
  font-family: 'Microsoft YaHei';
  font-weight: bold;
  /deep/ .el-timeline-item__tail{
    border-left: 2px dashed #999999;
  }
  /deep/ .el-timeline-item__timestamp{
    color: #333333 ;
    font-weight: 400;
    font-size: 18px !important;
  }
}

.left{
  border: 1px solid #CCCCCC;
  height: 100%;
  border-right: 0;
  &:last-child{
    border-bottom: 0;
  }
}
.right{
  border: 1px solid #CCCCCC;
  height: 100%;
  &:last-child{
    border-bottom: 0;
  }
}
.block{
  border-bottom: 1px solid #CCCCCC;
  // border-right:1px solid #ccc;
  padding: 12px 14px;
  .blockTitle{
    i{
      color: #1A69FC;
      font-weight: bold;
      margin-right: 3px;
    }
    span{

    }
  }
  .blockContent{
  }

}
.block .contentChart{
  display: flex;
  .chartItem{
    flex: 1;
    flex-shrink: 0;
  }

}
.block .contentBlocks{

    .blockItem{
      margin: 20px 0;

      .blockItemTitle{

        span{
          color:#FF5555;
        }
        .unit{
          color: #333333;
          font-weight: 400;
          margin-left: 4px;
        }
      }
      .blockItemCon{
        margin: 20px 75px;
        display: flex;
        justify-content: space-around;

        .blockItemConItem{
          width: 260px;
          height: 120px;
          background: #FFFFFF;
          box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
          border-radius: 10px;
          position:relative;
          display: flex;
          align-items: center;
          justify-content: center;

          .blockItemConAbs{
            position: absolute;
            top: 13px;
            left: 12px;
            font-weight: 400;

          }
          .blockItemConCen{
            font-size: 14px;
            font-weight: bold;
            color: #333333;
            span{
              font-size: 32px;
              color: #FF5555;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
.block .contentSimp{
    margin: 20px;
    .blockItem{
      margin-bottom: 12px;
      .title{

      }
      .value{
        color: #FF5555;
        margin-right:6px ;
      }
      .unit{
        font-weight: 400;
      }
    }
}
.block .contentBasicInfo{
  display: flex;
  flex-wrap: wrap;
  margin:23px ;
  .blockItem{
    width: 50%;
    flex-shrink: 0;
    display: flex;
    font-size: 14px;
    margin-bottom: 12px;
    .title{
      margin-right: 10px;
      flex-shrink: 0;

// font-family: Microsoft YaHei;
// font-weight: bold;
// color: #333333;
    }
    .content{
      color: #666666;
      font-weight: 400;
      margin-right: 10px;
    }
  }
}

.block .contentTable{
  margin:20px 0;
  min-height: 176px;
}
.block .contentLine{
  margin: 10px 20px;
  min-height: 534px;
  .tabs{
    font-weight: 400;
    span{
      margin-right: 10px;
      cursor: pointer;
    }
    .tabAct{
      font-weight:bold
    }
  }
  .timeLine{
    margin-top: 10px;
    min-height: 100px;
    max-height: 500px;
    overflow-y: auto;
    padding-left: 10px;

  &::-webkit-scrollbar {
    width: 0px;
  }
    .content{
      display: flex;
      margin-top: 29px;
      .avator{
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-right: 12px;
      }
      .info{
        .name{
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
        }
        .dept{
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }
        .content{
          margin-top: 29px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }
}
</style>
