<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    v-if="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
    :withFooter='false'
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="120px" label-position="right" style="width:100%" >
      
      <el-form-item label="问题名称" prop="questionName" :rules="{ required: true, message: '请输入问题名称', trigger: 'blur' }">
        <el-input v-model="form.questionName" placeholder="请填写问题名称" v-trim maxlength="50" />
      </el-form-item>
      <el-form-item label="问题类型" prop="questionType" :rules="{ required: true, message: '请选择问题类型', trigger: 'blur' }">
        <el-select v-model="form.questionType" placeholder="请选择">
          <el-option
            v-for="item in questionType"
            :key="item.id"
            :label="item.label"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="创建人"  :rules="{ required: true, message: '创建人获取失败', trigger: 'blur' }">
        {{form.username}}
      </el-form-item>
      <el-form-item label="问题解决对象" prop="mainDelivery" :rules="{ required: true, message: '请选择问题解决对象', trigger: 'blur' }">
        <LeaderSelect v-model="form.mainDelivery" placeholder="请选择报告对象"  :exclude-id="form.creator" :fromObjId="projectId"></LeaderSelect>
        <!-- <el-input v-model="form.mainDelivery" type="textarea" placeholder="填写问题解决对象" :rows="3" v-trim maxlength="500"/> -->
      </el-form-item>
      <el-form-item label="抄送对象" prop="userList" >
        <!-- :rules="{ required: true, message: '请选择抄送对象', trigger: 'blur' }" -->
        <LeaderSelect v-model="form.userList" placeholder="请选择抄送对象" :fromObjId="projectId" :exclude-id="form.mainDelivery" :multiple='true'></LeaderSelect>

      </el-form-item>


      <el-form-item label="问题状态" prop="questionStatus" :rules="{ required: true, message: '请选择问题状态', trigger: 'blur' }">
        <div v-if="form.id==null || form.questionStatus==10">
          未处理
        </div>
        <div v-else>
          
          <el-select v-model="form.questionStatus" placeholder="请选择">
          <!-- <el-option label="未处理" :value="10"></el-option> -->
          <el-option label="处理中" :value="11"></el-option>
          <el-option label="已解决" :value="12"></el-option>
        </el-select>
        </div>
      </el-form-item>
      <el-form-item label="问题说明" prop="questionExplain">
        <el-input v-model="form.questionExplain" placeholder="请输入" v-trim maxlength="50"/>
      </el-form-item>

      <el-form-item label="上传文件" prop="enclosureEntityList" >
        <div>
          <FileUpload v-model='form.enclosureEntityList'></FileUpload>
          <el-table border :data="form.enclosureEntityList">
                <el-table-column prop="name" label="资料名称" align="center"></el-table-column>
                <el-table-column prop="size" label="大小" align="center"></el-table-column>
                <el-table-column  label="操作" align="center">
                    <div slot-scope="{row,$index}" class="flexAround">
                        <a >下载</a>
                        <a v-if="row.delete" @click="form.enclosureEntityList.splice($index,1)">删除</a>
                    </div>
                </el-table-column>

            </el-table>
        </div>
      </el-form-item>

      <section>
        <el-button  @click="confirmBtn" type="primary">提交</el-button>
        
      </section>
     
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import LeaderSelect from '../../common/LeaderSelectProject'
import FileUpload from '../../common/FileUpload'
import {questionType} from '../../../utils/basicData'
export default {
  name: 'OperaRoleWindow',
  extends: BaseOpera,
  components: { GlobalWindow ,LeaderSelect , FileUpload},
  data () {
    return {
      questionType:questionType,
      projectId:'',//项目id
      // 原角色码
      originRoleCode: '',
      // 表单数据
      form: {
        id: null,
        questionName: '',
        questionType: '',
        creator: '',
        mainDelivery:null,
        userList:[],
        questionStatus:10,
        questionExplain:'',
        enclosureEntityList:[],
        projectId: '',
      },
      // 验证规则
      rules: {
        code: [
          { required: true, message: '请输入角色编码' }
        ],
        name: [
          { required: true, message: '请输入角色名称' }
        ]
      }
    }
  },
  methods: {
    open (title, target) {
      this.title = title
      this.visible = true
      console.log(title);
      console.log(target);
      
      // 新建
      if (target == null) {
        this.$nextTick(() => {
          // this.$refs.form.resetFields()
          this.form = {
              id: null,
              questionName: '',
              questionType: '',
              creator: '',
              mainDelivery:null,
              userList:[],
              questionStatus:10,
              questionExplain:'',
              enclosureEntityList:[],
              projectId: '',
          },
          this.form[this.configData['field.id']] = null
          this.form.creator = this.$store.state.init.userInfo.id
          this.form.username = this.$store.state.init.userInfo.username
          this.form.projectId = this.$route.query.pid

          

        })
        return
      }
      // 编辑
      this.$nextTick(() => {
        let userList = []
        if(target.userVoList){
          target.userVoList.map(i=>{
            userList.push(i.id)
          })
        }
        for (const key in this.form) {
          // this.form[key] = target[key]
          this.form.username = target.creatorName
          this.form.creator = target.entity.creator
          this.form.userList = userList
          this.form.enclosureEntityList = target.enclosureVoList
          this.form.id = target.entity.id
          this.form.questionName = target.entity.questionName
          this.form.questionType = target.entity.questionType
          this.form.mainDelivery = target.entity.mainDelivery
          this.form.questionExplain = target.entity.questionExplain
          this.form.questionStatus = target.entity.questionStatus
          this.form.projectId = target.entity.projectId 
        }
      })
    },
    confirmBtn () {
      this.__confirmCreate()
      setTimeout(()=>{
        this.$emit('refresh')
      },500)
    },
    confirm(){

    }

  },
  created () {
    this.projectId = this.$route.query.pid
    this.config({
      api: '/bussiness/question'
    })
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-form-item{
  display: flex;
}
/deep/ .el-form-item__content{
  margin-left: 0 !important;
  flex: 1;
}
</style>