// 去空
export function trim (data) {
  if (data == null) {
    return null
  }
  if (typeof data === 'string') {
    return data.trim()
  }
  if (data instanceof Array) {
    for (const item of data) {
      trim(item)
    }
  }
  if (typeof data === 'object') {
    for (const key in data) {
      data[key] = trim(data[key])
    }
  }
  return data
}

export function equalObject(obj1,obj2){

  //  obj1={"projectNo":{"switchs":false,"auto":false},"projectLevel":{"switchs":true,"references":[{"id":1,"content":"紧急，支出大于1000万"},{"id":2,"content":"紧急，支出大于500万"},{"id":3,"content":"重要，支出大于50万"},{"id":4,"content":"重要，支出大于10万"},{"id":5,"content":"普通，支出小于10万"}]},"projectTypes":[{"id":1,"content":"数字化转型项目"},{"id":2,"content":"数字化基建项目"},{"id":3,"content":"数字化运营项目"}],"expenditureTypes":[{"id":1,"content":"差旅成本"},{"id":2,"content":"材料成本"},{"id":3,"content":"外包成本"},{"id":4,"content":"行政成本"},{"id":5,"content":"设备成本"},{"id":6,"content":"销售成本"}],"deductPoints":{"switchs":true,"project":0.1,"task":0.5,"milepost":0.1},"show":{"projectLevel":true,"projectStakeholder":true,"projectExplain":true,"estimatedTotalRevenue":true,"estTtExpendAndType":true,"enclosure":true},"projectionScreen":{"projectStatusBeginMonth":"2021-01","projectStatusEndMonth":"2021-12","projectQuestionsBeginMonth":"2021-01","projectQuestionsEndMonth":"2021-12","projectQuestionsOrderType":1,"projectQuestionsRefreshTime":1,"projectScoreBeginMonth":"2021-01","projectScoreEndMonth":"2021-12","projectScoreOrderType":1,"projectScoreRefreshTime":1,"leaderRefreshTime":1,"leaderOrderType":1,"leaderProjectOrder":"1,2,3,4,6,5"}}
  //  obj2={"projectNo":{"switchs":true,"auto":false},"projectLevel":{"switchs":true,"references":[{"id":1,"content":"紧急，支出大于1000万"},{"id":2,"content":"紧急，支出大于500万"},{"id":3,"content":"重要，支出大于50万"},{"id":4,"content":"重要，支出大于10万"},{"id":5,"content":"普通，支出小于10万"}]},"projectTypes":[{"id":1,"content":"数字化转型项目"},{"id":2,"content":"数字化基建项目"},{"id":3,"content":"数字化运营项目"}],"expenditureTypes":[{"id":1,"content":"差旅成本"},{"id":2,"content":"材料成本"},{"id":3,"content":"外包成本"},{"id":4,"content":"行政成本"},{"id":5,"content":"设备成本"},{"id":6,"content":"销售成本"}],"deductPoints":{"switchs":true,"project":0.1,"task":0.5,"milepost":0.1},"show":{"projectLevel":true,"projectStakeholder":true,"projectExplain":true,"estimatedTotalRevenue":true,"estTtExpendAndType":true,"enclosure":true},"projectionScreen":{"projectStatusBeginMonth":"2021-01","projectStatusEndMonth":"2021-12","projectQuestionsBeginMonth":"2021-01","projectQuestionsEndMonth":"2021-12","projectQuestionsOrderType":1,"projectQuestionsRefreshTime":1,"projectScoreBeginMonth":"2021-01","projectScoreEndMonth":"2021-12","projectScoreOrderType":1,"projectScoreRefreshTime":1,"leaderRefreshTime":1,"leaderOrderType":1,"leaderProjectOrder":"1,2,3,4,6,5"}}
  return JSON.stringify(obj1)==JSON.stringify(obj2)
   
}
