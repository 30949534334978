

<template>
    <div  >
        <div class="title">
          <div class="name" @click="$router.go(-1)">{{base.name}}</div>
          <div class="numT">
            <div> <span>任务:{{base.taskNum?base.taskNum:0}},已完成:{{base.completedTaskNum?base.completedTaskNum:0}}</span></div>
            <div> <span>里程碑:{{base.milepostNum?base.milepostNum:0}},已完成:{{base.completedMilepostNum?base.completedMilepostNum:0}}</span></div>
          </div>
          <div class="speedT">
            工期进度：{{base.projectSpeed?base.projectSpeed:0}}%,任务进度：{{base.taskSpeed?base.taskSpeed:0}}%
          </div>
        </div>
        <gantt-elastic
            v-show="showGantt"
            :options="options"
            :tasks="tasks"
            >
        </gantt-elastic>
            <!-- -->
    </div>
</template>

<style>
</style>
<script>
import GanttElastic from "gantt-elastic";
import dayjs from "dayjs";
import {getGanttChart} from '@/api/bussiness/project'
// import GanttHeader from "gantt-elastic-header";

//tasks是数据 真实的项目需要接口赋值数据
let that
let options = {
      taskMapping: {
        progress: "percent"
      },
      maxRows: 30, 
      maxHeight: 500,
      // title: {
      //   label: "Your project title as html (link or whatever...)",
      //   html: false
      // },
      row: {
        height: 20,//设置行高
      },
      times: {
          timeScale: 60 * 1000,
          timeZoom: 20, 
        },

      chart: {
          grid: {
            horizontal: {
              gap: 6 ,//*
            }
          },
          text: {
            offset: 4, //*
            xPadding: 10, //*
            display: true //*
          },
        
          expander: {
            type: 'chart',
            display: true, //*
            displayIfTaskListHidden: true, //*
            offset: 4, //*
            size: 18   
          }
        },

      taskList: {
        // expander: {
        //   straight: true,
        // },
        columns: [
          {
            id: 1,
            label: "ID",
            value: "id",
            width: 0
          },
          {
            id: 2,
            label: "项目名称",
            value: "label",
            width: 150,
            expander: true,
            html: true,
            events: {
              click({ data, column }) {
                if(that.flags != '1'){

                  that.showDialog(data)
                }
               
              }
            }
          },
          {
            id: 3,
            label: "状态",
            value: "nums",
            width: 80,
            // expander: true,
            // html: true,
          
          },
          {
            id: 4,
            label: "负责人",
            value: "user",
            width: 80,
            html: true
          },
          {
            id: 4,
            label: "开始时间",
            value: task => task.start?dayjs(task.start).format("YYYY-MM-DD"):'',
            width: 78
          },
          {
            id: 5,
            label: "结束时间",
            value: task=>{
              return task.end?dayjs(task.end).format("YYYY-MM-DD"):''
            },
            width: 78
          },
        
        
        ],

      },
      calendar: {
          workingDays: [1, 2, 3, 4, 5], //*
          gap: 0, 
          strokeWidth: 5,
          hour: {
            display: false
          },
        },

      locale: {
        weekdays:["周日","周一","周二","周三","周四","周五","周六"],
        months:["一月","二月","三月","四月","五月","六月","七月","八月","九月","十月","十一月","十二月"],
      },
    
    };
export default {
//   name: "Gantt",
  components: {
    GanttElastic,
    // GanttHeader 
  },
  props:{
    gantData:{},
    flags:{
      type:String
    }
  },
  data() {
    return {
      base:null,//接口数据
      showGantt:false,
      dialogVisible:false,
      orderModal:false,
      TaskData:[],
      sontaskData:'',
      // tasks:[],
      tasks: [
        {
          id: 2,
          parentId:1,
          label: "立项阶段",
          nums:'预计延迟3天',
          user:'无',
          start:'2020-01-01',
          end:'2021-12-12',
          duration: 30  * 24 * 60 * 60 * 1000,
          percent: 85,
          type: "milestone"
        },
        {
          id: 1,
          label: "当前项目",
          nums:'预计延迟3天',
          user:'123',
          start: this.getTody(new Date(),4),
          end:this.getTody(new Date(),-30),
          duration: 30  * 24 * 60 * 60 * 1000,
          percent: 5,
          type: "project"
        },
        {
          id: 3,
          parentId:2,
          label: "计划",
          nums:'预计延迟3天',
          user:'无',
          start: this.getTody(new Date(),4),
          end:this.getTody(new Date(),-30),
          duration: 30  * 24 * 60 * 60 * 1000,
          percent: 85,
          type: "project"
        },
        {
          id: 4,
          parentId:2,
          label: "计划",
          nums:'预计延迟3天',
          user:'无',
          start: this.getTody(new Date(),4),
          end:this.getTody(new Date(),-30),
          duration: 30  * 24 * 60 * 60 * 1000,
          percent: 85,
          type: "project"
        },

      ],
      options,
      dynamicStyle: {},
      successStyle: {
          base: {
            fill: "#C61",
            stroke: "#C51"
          }
        },
      unfinishedStyle:{
         base: {
            fill: "#6ec",
            stroke: "#adf"
          }
      },
      comName:'newSelectProdct',
      titles:'新品选品',
      flag:true
    };
  },
  created(){
    
  },
  mounted(){
    var that = this;
    this.getTaskList()
  },
  computed:{
    
  },
//   watch:{
//     gantData:function(news,old){
//       this.getTaskList(news.id)
//     }
//   },
  methods: {
    handleClose(){
      this.dialogVisible = false;
    },
    getTody(n,ds=0,ys=0){
        var now = new Date(n);
        var time = now -  24 * 60 * 60 * 1000 * ds  ; //获取前N天
        var d = new Date(time);
        var year = d.getFullYear() - ys;//获取前N年的时间
        var mon = d.getMonth() + 1;
        var day = d.getDate();
        var week = d.getDay();
        var hour =d.getHours();
        var secd = d.getMinutes()
        var week = d.getDay();
        var times = '';
        if (mon == 0) {
            times = 12 + "-" + (day < 10 ? ("0" + day) : day);
        } else if (mon < 10) {
            times = (mon < 10 ? ('0' + mon) : mon) + "-" + (day < 10 ? ("0" + day) : day);
        } else {
            times = mon + "-" + (day < 10 ? ("0" + day) : day);
        }
        var today = year + '-' + times;
      
       return today
        
      },
    //计算时间差
    datedifference(date1, date2) {    //sDate1和sDate2是2006-12-18格式  
          var dateSpan, tempDate, iDays;
          var sDate1 = this.getTody(date1)
          sDate1 = Date.parse(sDate1)
          var sDate2 = this.getTody(date2)
          sDate2 = Date.parse(sDate2)
          dateSpan = sDate2 - sDate1;
          dateSpan = Math.abs(dateSpan);
          iDays = Math.floor(dateSpan / (24 * 3600 * 1000));
          return iDays
      },
    //获取数据-----------------------------------------------------
    getTaskList(id){
      var that = this;
        getGanttChart({
          projectId:this.$route.query.pid  
        }).then(res=>{
            console.log(res,'甘特图插件');

            let taskList = []
            this.base = res.bars[0]
            if(res.bars){
              res.bars.map((item,index)=>{
                let taskItem = {
                    id: item.id,
                    parentId:item.fatherId==-1?null:item.fatherId,
                    label: item.name?item.name:null,
                    nums:item.status?item.status:null,
                    user:item.directorName?item.directorName:null,
                    start:item.planBegin?(new Date(item.planBegin)).valueOf():'',
                    end:item.planEnd?(new Date(item.planEnd)).valueOf():'',
                    duration:item.planBegin&&item.planEnd? this.datedifference((new Date(item.planEnd)).valueOf(),(new Date(item.planBegin)).valueOf()) * 24 * 60 * 60 * 1000:null,
                    percent: item.speed?item.speed:0,
                    type: "project",
                  }
                  if(index!=0){
                    taskList.push({
                      ...taskItem,
                      style: {
                        base: {
                          fill: "#1EBC61",
                          stroke: "#0EAC51"
                        }
                      }
                    })
                  }else{
                    taskList.push({
                      ...taskItem,
                      style: {
                        base: {
                          fill: "#cdc",
                          stroke: "#dcd"
                        }
                      }
                    })
                  }

                  
                
              })
            }
            // // this.tasks = res.bars
            console.log(taskList,'-a-s--as--a');
            // this.gsgsgsg = taskList
            this.tasks = taskList
            this.showGantt = true

        }).catch(e=>{
            // this.$tips.
        })

    
    },
    //点击弹出-----------------------------------------------------
    showDialog(data){
     
    },
  
    
    tasksUpdates(tasks,num) {
      if( num == 2){
        // this.tasks = [];
        this.tasks = tasks;  
      }else{
        return
      } 
    },
    optionsUpdate(options,num ) {
      if(num == 2){ 
        this.options = options;
      } 
    },
    styleUpdate(style) {
      this.dynamicStyle = style;
    }
  }
};
</script>
<style  scoped lang="scss">
    .title{
      display: flex;
      margin: 20px 0;
      .name{
        font-weight: bold;
        margin-right: 40px;
        margin-left: 20px;
        cursor: pointer;
      }
      .numT{
        margin-right: 40px;
        display: flex;
        div{
         margin-right: 20px;
        }
      }
      .speedT{
        flex: 1;
      }
    }
</style>