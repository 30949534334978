<template>
<div class="curPage">

    <el-row class="content">
        <el-col :span="24" class="content1" >
            <!-- 进度概要 -->
            <div class="block">
                <div class="title">支出概要</div>
                <div class="blockContent contentChart">
                    <div class="block3">
                        <div id='spending_chart' class="chart">任务状态统计</div>
                    </div>

                </div>
            </div>
            <div class="block">
                <div class="title">预算支出类型统计表</div>
                <div class="blockContent contentTable">
                    <el-table :data="data" stripe border show-summary :summary-method="getSummaries">

                        <el-table-column align="center" prop="type" label="序号" min-width="100px" fixed="left"></el-table-column>
                        <el-table-column align="center" prop="typeName" label="支出类型" min-width="120px"></el-table-column>
                        <el-table-column align="center" prop="estimate" label="预算金额" min-width="120px"></el-table-column>
                        <el-table-column align="center" prop="actual" label="实际支出" min-width="120px">
                            <div slot-scope="{row,$index}">
                                <div v-if="row.editable">
                                    <div v-if='!row.editAction'><span>{{row.actual}}</span><i v-if="row.isUpdate &&projectBtns" class="el-icon-edit" style="font-size:18px;margin-left:20px;cursor:pointer" @click="row.editAction=true"></i></div>
                                    <div v-else style="display:flex;align-items:center">
                                        <el-input-number  controls-position="right" v-model="row.actual" :max="row.estimate" :min="0" placeholder="请输入实际支出"  @keypress.enter.native="changeExper(row,$index)"></el-input-number>
                                        <i class="el-icon-check" style="font-size:18px;margin-left:10px;cursor:pointer" @click="changeExper(row,$index)"></i>
                                    </div>
                                </div>
                                <div v-else>-</div>
                                
                            </div>
                        </el-table-column>
                        <el-table-column align="center" prop="per" label="支出进度" min-width="100px" >
                            <div  slot-scope="{row}">
                                {{row.per}}%
                            </div>
                        </el-table-column>
                    </el-table>
                   <!-- <el-table border>
                        <el-table-column prop="date1" label="序号" align="center"></el-table-column>
                        <el-table-column prop="date2" label="支出类型" align="center"></el-table-column>
                        <el-table-column prop="date3" label="预算金额" align="center"></el-table-column>
                        <el-table-column prop="date4" label="实际支出" align="center"></el-table-column>
                        <el-table-column prop="date4" label="支出进度" align="center"></el-table-column>
                    </el-table>  -->
                </div>
            </div>
        </el-col>


    </el-row>
</div>


</template>

<script>
import * as echarts from 'echarts';
import Pagination from '@/components/common/Pagination'
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import AddPerson from './AddPerson'
import TaskList from './TaskList'
import MilestoneList from './MilestoneList';

import {expenditure , updateActual} from '../../../api/bussiness/spending'

export default {
  name: 'project_spending',
  extends: BaseTable,
  components: { AddPerson, TableLayout, Pagination,TaskList,MilestoneList, },
  props: {
      projectBtns:{
      type:Boolean,
      default:true
    }
  },
  data () {
    return {
        searchForm:{
          projectId:this.$route.query.pid  
        },
        data:null,
        // chartData:[],

    }
  },
  computed: {

  },
  mounted(){
    
  },
  created(){
    //   this.config({
    //   module: '支出',
    //   api: '/bussiness/spending',

    // })
    // this.search()
    this.expenditure();
  },
  methods: {
    pieChart(id,data){
      var chartDom = document.getElementById(id);
      var myChart = echarts.init(chartDom);
      var option;
      option = {
    legend: {
        top: 'bottom'
    },
    toolbox: {
        show: true,
        feature: {
            mark: {show: true},
            // dataView: {show: true, readOnly: false},
            // restore: {show: true},
            // saveAsImage: {show: true}
        }
    },
    series: [
        {
            name: '面积模式',
            type: 'pie',
            radius:['0%', '60%'],
            // center: ['30%', '45%'],
            // radius: [50, 250],
            center: ['50%', '35%'],
            roseType: 'area',
            itemStyle: {
                borderRadius: 8
            },
            data: data
        }
    ]
};
    
      option && myChart.setOption(option);
    },
    getBar(id){
      var chartDom = document.getElementById(id);
      var myChart = echarts.init(chartDom);
      var option;
      option = this.option
      option && myChart.setOption(option);
    },
    expenditure(){ //支出
        expenditure({projectId:this.$route.query.pid}).then(res=>{
            console.log(res,'---支出');
                let chartData = []

            if(res&&res.length>0){
                res.map(i=>{
                    
                    if(i.estimate==0){
                        chartData.push({
                            value:0,
                            name:i.typeName
                        })
                        i.per = 0;
                        i.editable = false
                    }else{
                        chartData.push({
                            // value:(i.actual/i.estimate * 100).toFixed(0),
                            value:i.actual,
                            name:i.typeName
                        })
                        i.per = (i.actual/i.estimate * 100).toFixed(2);
                        i.editable = true
                        i.editAction = false
                    }
                    
                })
            }
            this.data = res;
            // this.chartData = chartData
            this.pieChart('spending_chart',chartData)

        }).catch(e=>{
        this.$tip.apiFailed(e)
      })
    },
    changeExper(row,index){
        console.log(row,'----',index);
        updateActual({
            id:row.id,
            actual:row.actual
        }).then(res=>{
            this.$tip.apiSuccess('实际支出更新成功')
            this.expenditure();
            
        }).catch(e=>{
        this.$tip.apiFailed(e)
      })
        
        // this.data = JSON

        // row.editAction = false
    },
    getSummaries(param) { //合计自定义
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '总价';
            return;
          }
          if (index === 4) {
            sums[index] = '';
            return;
          }
          if(data){
              const values = data.map(item => Number(item[column.property]));
                if (!values.every(value => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                    const value = Number(curr);
                    if (!isNaN(value)) {
                        return prev + curr;
                    } else {
                        return prev;
                    }
                    }, 0);
                    // sums[index] += ' 元';
                } else {
                    sums[index] = 'N/A';
                }
          }
          
        });

        return sums;
      }

  }
}
</script>

<style lang="scss">

</style>
<style scoped lang="scss">

.curPage{
    color: #333333;
    font-size: 18px;
    font-family: 'Microsoft YaHei';
    font-weight: bold;
}
.tabs{
    .tab_tab{
        display: flex;
        justify-content: space-around;
        .tabItem{
           font-weight: 400; 
           cursor: pointer;
        }
        .activeItem{
            font-weight: bold;
        }
    }
}
.content{
    padding: 20px;
}
.content1{
    .block{
       .title{

       } 
       .blockContent{
           margin: 20px 0;
       }
    }
}
.block .contentChart{
    
    .block1{
        font-size: 14px;
        font-weight: 400;
        .title{
            margin-bottom: 11px;
        }
    }
    .block3{
        display: flex;
        height: 200px;
        width: 100%;

        .chart{
            width: 100%;
            min-width: 300px;
            flex: 1;
            flex-shrink: 0;
            height: 100%;
            min-width:380px;
            min-height: 200px;
        }
    }
}
.block .contentTable{
    .block1{
        font-size: 14px;
        font-weight: 400;
        .title{
            margin-bottom: 11px;
        }
    }
    .block2{
        margin-left: -40px;
    }
}


</style>
