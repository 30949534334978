import { render, staticRenderFns } from "./ProjectData.vue?vue&type=template&id=0c8f9bee&scoped=true&"
import script from "./ProjectData.vue?vue&type=script&lang=js&"
export * from "./ProjectData.vue?vue&type=script&lang=js&"
import style1 from "./ProjectData.vue?vue&type=style&index=1&id=0c8f9bee&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c8f9bee",
  null
  
)

export default component.exports