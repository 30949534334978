import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import './assets/style/element-variables.scss'
import VueClipboard from 'vue-clipboard2'
import directives from './directives'
import filters from './filters'
import plugins from './plugins'
import { mapState, mapMutations } from 'vuex'
import { fetchMenuTree } from './api/system/menu'
import {querySettings} from './api/common';//或
import '../src/assets/iconfont/iconfont'



import scroll from 'vue-seamless-scroll'
Vue.use(scroll)

import vGanttChart from 'v-gantt-chart';
Vue.use(vGanttChart);





Vue.config.productionTip = false
Vue.use(ElementUI, {
  size: 'small'
})
Vue.use(VueClipboard)
Vue.use(directives)
Vue.use(filters)
Vue.use(plugins)

new Vue({
  data: {
    loading: false
  },
  router,
  store,
  computed: {
    ...mapState({
      userInfo: state=>state.init.userInfo,
      homePage: state=>state.init.homePage,
    })

  },
  watch: {
    async userInfo () {
      if (this.userInfo == null) {
        return
      }
      this.$store.dispatch('querySettings')
      await this.initRoutes()
      
    }
  },
  methods: {
    ...mapMutations(['switchCollapseMenu', 'setHomePage','setSetting']),

    // 初始化本地配置
    initLocalConfig () {
      // 菜单状态配置
      const menuStatus = window.localStorage.getItem('MENU_STATUS')
      if (menuStatus != null) {
        this.switchCollapseMenu(menuStatus === 'true')
      }
    },
    // 初始化路由
    async initRoutes () {
      console.log('接口方法',this.loading,this.userInfo == null);
      if (this.loading || this.userInfo == null) {
        
        return
      }
      
      this.loading = true
      // 重置菜单
      this.$store.commit('resetMenus')
      // 获取菜单
      const storeMenus = this.$store.state.init.menuData.list;
      console.log('---,----',storeMenus);
      console.log(storeMenus,'---------菜单');
      
      if (storeMenus.length > 0 && this.homePage == null) {
        console.log('重置默认页');
        console.log('重置默认页',storeMenus);
        console.log('重置默认页',storeMenus[1].children[0]);


        this.setHomePage(storeMenus[1].children[0])
      }
      console.log('接口调用前');
      await fetchMenuTree()
        .then(menus => {
          this.__afterGetMenus(menus,storeMenus);
        })
        .catch(e => {
          throw e
        })
        .finally(() => {
          this.loading = false
        })
    },
    //菜单路由获取之后
    __afterGetMenus(menus,storeMenus){

      // menus=[{"id":101,"label":"假菜单业务中心","index":"menu_101","icon":"el-icon-folder-opened","url":"","children":[{"id":104,"label":"首页","index":"menu_104","icon":"el-icon-s-home","url":"/bussiness/index","children":[]},{"id":105,"label":"项目中心","index":"menu_105","icon":"","url":"/bussiness/project","children":[]},{"id":106,"label":"个人中心","index":"menu_106","icon":"el-icon-user","url":"/bussiness/person","children":[]}]},{"id":103,"label":"管理中心","index":"menu_103","icon":"el-icon-folder-opened","url":"","children":[{"id":107,"label":"首页","index":"menu_107","icon":"el-icon-s-home","url":"/manage/index","children":[]},{"id":108,"label":"数据中心","index":"menu_108","icon":"","url":"","children":[{"id":109,"label":"轮播设置规则","index":"menu_109","icon":"","url":"/manage/rules","children":[]},{"id":110,"label":"项目看板","index":"menu_110","icon":"","url":"/manage/projectBoard","children":[]}]},{"id":111,"label":"业务设置","index":"menu_111","icon":"","url":"/manage/bussinessSetting","children":[]},{"id":112,"label":"系统设置","index":"menu_112","icon":"","url":"/manage/systemSetting","children":[]},{"id":113,"label":"账号设置","index":"menu_113","icon":"","url":"/manage/accountSetting","children":[]},{"id":114,"label":"管理日志","index":"menu_114","icon":"","url":"/manage/managementLog","children":[]}]}]
      
      //添加菜单
      storeMenus.push.apply(storeMenus, menus)
      // 添加路由
      this.__addRouters([storeMenus[0]])
      this.__addRouters([storeMenus[1]])

      // 404
      router.addRoute({
        path: '*',
        redirect: '/not-found'
      })
      // 首页
      router.addRoute({
        path: '/',
        redirect: this.homePage?this.homePage.url:'/'
      })
      //项目详情
      router.addRoute('layout', {
        path: '/detail',
        name: '项目详情',
        meta: {
          title: '项目详情',
          // paths: [...parents.map(p => p.label), route.label]
        },
        component: () => import('@/views' + '/bussiness/projectDetail')
      })

      console.log(router,'添加了项目详情');

      router.addRoute('layout', {
        path: '/gantE',
        name: '甘特图',
        meta: {
          title: '甘特图',
          
        },
        component: () => import('@/views/bussiness/gantElastic')
      })
      // 路由加载完成后，如果访问的是/，跳转至动态识别的首页
      console.log(this.$route.path,'-----------当前页面',window.location.hash );
      let hashUrl = window.location.hash.substring(1,(window.location.hash.length))
      console.log(hashUrl,'---hashUrl');
      let path = this.$route.path
      if (path === '/') {
        this.$router.push(this.homePage.url)
      }
    },


    // 新建路由
    __addRouters (routes, parents = []) {
      if (routes == null || routes.length === 0) {
        return
      }

      const rs = router.getRoutes()
      for (const route of routes) {

        const parentsDump = JSON.parse(JSON.stringify(parents))
        parentsDump.push(route)
        if (route.url == null || route.url === '') {
          this.__addRouters(route.children, parentsDump)
          continue
        }
        if (rs.findIndex(r => r.path === route.url) > -1) {
          this.__addRouters(route.children, parentsDump)
          continue
        }
        if (this.homePage == null && route.url=='/manage/index' ) {
          console.log(route,'------------设置默认页');
          this.setHomePage(route)
        }
        router.addRoute('layout', {
          path: route.url,
          name: route.label,
          meta: {
            title: route.label,
            paths: [...parents.map(p => p.label), route.label]
          },
          component: () => import('@/views' + route.url)
        })
        this.__addRouters(route.children, parentsDump)
      }

    }
  },
  async created () {
    console.log('创建期-----------,,,',this.$route.path,window.location);
    
    if (this.userInfo == null) {
      return
    }
    this.$store.dispatch('querySettings')
    await this.initRoutes().catch(() => {})
    
    
  },
  mounted () {
    this.initLocalConfig()
  },
  render: h => h(App)
}).$mount('#app')
