<template>
<!--  -->
  <TableLayout :permissions="['business:weekly:query']"  :withBreadcrumb="false">
      <el-row class="tabs" slot="search-check">
        <el-col :span="12">
            <div class="tab_tab">
              <!-- v-if="item.num!=0" -->
              <!-- <span v-for="(item,index) in tableData.types" :key="index" class="tabItem"  
                :class="searchForm.type==item.type?'activeItem':''" 
                @click="searchForm.type=item.type">
                {{item.name}}<span>({{item.num}})</span> 
              </span> -->
                <span class="tabItem" :class="searchForm.type==1?'activeItem':''" @click="searchForm.type=1">全部<span></span> </span>
                <span class="tabItem" :class="searchForm.type==2?'activeItem':''" @click="searchForm.type=2">我提交的<span></span> </span>
                <span class="tabItem" :class="searchForm.type==3?'activeItem':''" @click="searchForm.type=3">提交给我的<span></span> </span>
                <span class="tabItem" :class="searchForm.type==4?'activeItem':''" @click="searchForm.type=4">抄送给我的<span></span> </span>
                <span class="tabItem" :class="searchForm.type==5?'activeItem':''" @click="searchForm.type=5">保存未提交<span></span> </span>

            </div>
        </el-col>
    </el-row>
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="80px" inline>
      <el-form-item label="报告名称" prop="weeklyName">
        <el-input v-model.trim="searchForm.weeklyName" v-trim placeholder="请输入周报名称" @keypress.enter.native="search"/>
      </el-form-item>
      <section>
        <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <template v-slot:table-wrap>
      <ul class="toolbar"  v-if="projectBtns" v-permissions="['business:weekly:save']">

        <li ><el-button v-permissions="['business:weekly:save']" icon="el-icon-plus" type="primary" @click="$refs.addWeekLog.open('新建周报')">新建周报</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        @row-click="(row)=>{$refs.weekLogDetail.open('周报:'+row.weeklyName,row)}"
        :default-sort = "{prop: 'createTime', order: 'descending'}"
        stripe
        border
        @sort-change="handleSortChange"
      >

        <el-table-column align="center" prop="weeklyName" label="周报名称" min-width="100px" fixed="left"></el-table-column>
        <el-table-column align="center" prop="belongProjectName" label="所属项目" min-width="120px"></el-table-column>
        <el-table-column align="center" prop="creatorName" label="报告人" min-width="120px"></el-table-column>
        <el-table-column align="center" prop="createDate" label="操作时间" min-width="120px"></el-table-column>
        <el-table-column align="center" prop="weeklyStatusName" label="状态" min-width="100px" ></el-table-column>
        <el-table-column align="center" prop="mainDelivery" label="报告对象" min-width="100px" ></el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination>
    </template>

    <AddWeekLog ref="addWeekLog" @success="handlePageChange(tableData.pagination.pageIndex)"/>
    <WeekLogDetail ref='weekLogDetail'  @success="handlePageChange(tableData.pagination.pageIndex)"></WeekLogDetail>
  </TableLayout>
</template>

<script>
import Pagination from '@/components/common/Pagination'
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import AddWeekLog from '@/components/bussiness/projectAddModal/AddWeekLog'
import WeekLogDetail from '@/components/bussiness/projectDetail/WeekLogDetail'

export default {
  name: 'weekLogList',
  extends: BaseTable,
  components: { AddWeekLog, WeekLogDetail,TableLayout, Pagination, },
   props: {
     projectBtns:{
      type:Boolean,
      default:true
    }
  },
  data () {
    return {
        searchForm: {
            projectId:this.$route.query.pid,
            type:1,
            weeklyName:null
        },
        activeItem:0,
        // tableData:{
        //   list:[
        //   ]
        // }
    }
  },
  computed: {
    types:function(){
      let a = this.tableData.types
      return a
    }
  },
  watch:{
    tableData:{
      handler(val){
        console.log(val,'--as-a-s-a-s-as-');
        this.tableData = val
      },
      immediate:true,
      deep:true
    },
    'searchForm.type':{
      handler(val){
        this.search()
      },
      immediate:true
    }
  },
  created(){
    this.config({
      api: '/bussiness/weekLog'
    })
    this.search()

    // this.config({
    //   module: '账号',
    //   api: '/system/user',
    //   'field.main': 'account',
    //   sorts: [{
    //     property: 'CREATE_TIME',
    //     direction: 'DESC'
    //   }]
    // })
    // this.search()

  },
  methods: {
  }
}
</script>

<style lang="scss">

</style>
<style scoped lang="scss">
.tabs{
    .tab_tab{
        display: flex;
        justify-content: space-around;
        .tabItem{
           font-weight: 400; 
           cursor: pointer;
        }
        .activeItem{
            font-weight: bold;
            border-bottom: 2px solid #2264f1;
            color: #2264f1;
        }
    }
}
.sumInfo{
  color: #333333;
  font-size: 18px;
  font-family: 'Microsoft YaHei';
  font-weight: bold;
  /deep/ .el-timeline-item__tail{
    border-left: 2px dashed #999999;
  }
  /deep/ .el-timeline-item__timestamp{
    color: #333333 ;
    font-weight: 400;
    font-size: 18px !important;
  }
}


</style>
