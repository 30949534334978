<template>
  <TableLayout :permissions="['business:question:query']" :withBreadcrumb="false">
    <!--  -->

    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="80px" inline>
      <el-form-item label="问题名称" prop="account">
        <el-input v-model="searchForm.account" v-trim placeholder="请输入问题名称" @keypress.enter.native="search"/>
      </el-form-item>
      <section>
        <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        <!-- <el-button @click="reset">重置</el-button> -->
      </section>
    </el-form>
    <template v-slot:table-wrap>
      <ul class="toolbar" v-if="projectBtns">
        <!--  -->
        <li v-permissions="['business:question:save']"><el-button icon="el-icon-plus" type="primary"  v-permissions="['business:question:save']"  @click="$refs.addQuestion.open('新建问题')">新建问题</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        @row-click="(row)=>{$refs.questionDetail.open('问题详情:'+row.questionName,row)}"
        :default-sort = "{prop: 'createTime', order: 'descending'}"
        stripe
        border
        @sort-change="handleSortChange"
      >
        <el-table-column align="center" prop="questionName" label="问题名称" min-width="100px" fixed="left"></el-table-column>
        <el-table-column align="center" prop="projectName" label="所属项目" min-width="120px"></el-table-column>
        <el-table-column align="center" prop="questionTypeName" label="问题类型" min-width="120px">
        </el-table-column>
        <el-table-column align="center" prop="creatorName" label="创建人" min-width="120px"></el-table-column>
        <el-table-column align="center" prop="questionStatusName" label="问题状态" min-width="100px" ></el-table-column>
        <el-table-column align="center" prop="mainDeliveryName" label="解决人" min-width="100px" ></el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination>
    </template>
    <!-- 新建/修改 -->
    <AddQuestion ref="addQuestion" @success="handlePageChange(tableData.pagination.pageIndex)" @refresh="()=>{this.$emit('refresh')}"/>
    <QuestionDetail ref='questionDetail'  @success="handlePageChange(tableData.pagination.pageIndex)" @refresh="()=>{this.$emit('refresh')}"></QuestionDetail>
  </TableLayout>
</template>

<script>
import Pagination from '@/components/common/Pagination'
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import AddQuestion from '@/components/bussiness/projectAddModal/AddQuestion'
import QuestionDetail from '@/components/bussiness/projectDetail/QuestionDetail'
import DepartmentSelect from '../../common/DepartmentSelect'
import {questionType} from '../../../utils/basicData'
export default {
  name: 'project_question',
  extends: BaseTable,
  components: { AddQuestion, QuestionDetail,TableLayout, Pagination,DepartmentSelect },
   props: {
     projectBtns:{
      type:Boolean,
      default:true
    }
  },
  data () {
    return {
      questionType:questionType,
        searchForm: {
            projectId:this.$route.query.pid,
            // account: '', // 名字
            // realname: '', // 姓名
            // rootDeptId: null, // 部门ID
            // positionId: null, // 岗位ID
            // mobile: '' // 手机号码
        },
        activeItem:0,
        tableData:{
          list:[
            {qName:'这里是一个问题',project:'项目名称',qType:'xx',publisher:'andy',qStatus:'error', toObject:'Lily'}
          ]
        }
    }
  },
  computed: {

  },
  watch:{
    'tableData.list':{
      handler(val){
        if(val && this.tableData.list){
          this.tableData.list.filter(i=>{
           let data =  this.questionType.filter(item=>item.id==i.questionType)
           if(data.length>0){
             i.questionTypeName = data[0].label
           }
          })
        }
      },
      deep:true
    }
  },
  created(){
    this.config({
      module: '问题',
      api: '/bussiness/question',
      // sorts: [{
      //   property: 'CREATE_TIME',
      //   direction: 'DESC'
      // }]
    })
    this.search()

  },
  methods: {
  }
}
</script>

<style lang="scss">

</style>
<style scoped lang="scss">
.tabs{
    .tab_tab{
        display: flex;
        justify-content: space-around;
        .tabItem{
           font-weight: 400; 
           cursor: pointer;
        }
        .activeItem{
            font-weight: bold;
        }
    }
}
.sumInfo{
  color: #333333;
  font-size: 18px;
  font-family: 'Microsoft YaHei';
  font-weight: bold;
  /deep/ .el-timeline-item__tail{
    border-left: 2px dashed #999999;
  }
  /deep/ .el-timeline-item__timestamp{
    color: #333333 ;
    font-weight: 400;
    font-size: 18px !important;
  }
}


</style>
