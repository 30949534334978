<template>
  <TableLayout :withBreadcrumb="false">
    <!-- 表格和分页 -->
    <template v-slot:search-form>
      <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="80px" inline>
        <el-form-item label="任务状态" prop="name">
          <el-select v-model="searchForm.status" clearable placeholder="请选择" @change="search">
            <!-- <el-option  :key="0" label="全部" :value="[2,13,14,15]"> </el-option> -->
            <el-option  :key="1" label="进行中" :value="[1]"> </el-option>
            <el-option  :key="2" label="已暂停" :value="[2]"> </el-option>
            <el-option  :key="3" label="已完成" :value="[3]"> </el-option>
            <el-option  :key="13" label="未发布" :value="[13]"> </el-option>
            <el-option  :key="14" label="待启动" :value="[14]"> </el-option>
            <!-- <el-option  :key="15" label="已超期" :value="[15]"> </el-option> -->
            <el-option  :key="17" label="已删除" :value="[17]"> </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </template>
    <template v-slot:table-wrap>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        row-key="id"
        stripe
        border
        default-expand-all
      >
        <!-- <el-table-column type="selection" fixed="left" width="55"></el-table-column> -->
        <el-table-column prop="taskName" label="任务名称" fixed="left" min-width="200px" align="center">
          <template slot-scope="{row}" >
            <el-button type="text" @click="()=>{$refs.taskDetail.open('任务详情',row)}">{{row.taskName}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="projectName" label="所属项目" fixed="left" min-width="100px" align="center">
          <template slot-scope="{row}" >
            <el-button type="text" @click="todetail(row)">{{row.projectName}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="taskTypeName" label="任务类型" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="directorName" label="负责人" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="planBegin" label="计划开始时间" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="planEnd" label="计划结束时间" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="taskStatusName" label="任务状态" min-width="100px" align="center"></el-table-column>

      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination>
    </template>
    <TaskDetail ref="taskDetail" @success="search"/>

  </TableLayout>
</template>

<script>
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import { mapState, mapMutations } from 'vuex'
import { fetchTree } from '@/api/system/department'
import BaseTable from '@/components/base/BaseTable'
import TaskDetail from '@/components/bussiness/projectDetail/TaskDetail'
// import OperaDepartmentWindow from '@/components/system/department/OperaDepartmentWindow'
// import DepartmentUserWindow from '@/components/system/department/DepartmentUserWindow'
// import CompanySelect from '../../components/common/CompanySelect.vue'
export default {
  name: '',
  extends: BaseTable,
  components: {  TableLayout , Pagination , TaskDetail  },
  computed:{
     ...mapState({
      setting: state=>state.setting
    }),
  },
  data(){
    return{
      active:0,
      checkList:[],
      // 搜索
      time:'',
      searchForm: {
        type:2,
        status:[1,2,3,13,14,17],
      },
    }
  },
  watch:{
    checkList:{
      handler(val){
        let checkedList = this.itemOption[this.active].status.filter(i=>i.checked)
        console.log(checkedList,'watch,checklist');
      }
    },
  },
  methods: {
    // changeButtons(item,index){
    //   this.itemOption[this.active].activated = false
    //   this.itemOption[index].activated = true
    //   this.active = index
    //   this.checkList = []
    // },
    // handleCheckedStatus(value){
    //   console.log(value,'checked change status ');
    //   this.itemOption[this.active].status.map((item,index)=>{
    //     console.log();
    //     if(value.findIndex(item.name)>-1){
    //       item.checked = true
    //     }else{
    //       item.checked = false
    //     }
    //   })
    // },
    //项目详情
    todetail(row, column, event){
      console.log(row);
      this.$router.push({
        path:'/detail',
        query:{
          pid:row.projectId
        }
      })
    },
  },
  created () {
    this.config({
      module: '问题',
      api: '/bussiness/index'
    })
    this.search()
  }
}
</script>

<style scoped lang="scss">
.table-layout {
  /deep/ .table-content {
    margin-top: 0;
  }
}
</style>
