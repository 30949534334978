<template>
  <TableLayout >
    <!-- :permissions="['system:user:query']" -->
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar">
         <!-- v-permissions="['system:user:create', 'system:user:delete']" -->
         <!-- v-permissions="['system:user:create']"
         v-permissions="['system:user:delete']" -->
        <li v-if="!data.readOnly&& projectBtns"><el-button icon="el-icon-plus" type="primary" @click="$refs.JoinUser.open('添加人员',data.users)">添加人员</el-button></li>
        <!-- <li ><el-button icon="el-icon-delete" @click="deleteByIdInBatch">移出项目</el-button></li> -->
        <!-- <li><el-input placeholder="输入名称"></el-input></li> -->
      </ul>
      <el-table
        :data="data.users"
        stripe
        border
      >
        <el-table-column align="center" prop="avatar" label="头像" width="80px" class-name="table-column-avatar" >
          <div slot-scope="{row}">
            <img :src="row.avatar == null ? '/static/avatar/man.png' : row.avatar">
          </div>
        </el-table-column>
        <el-table-column align="center" prop="userName" label="姓名" min-width="100px" ></el-table-column>
        <el-table-column align="center" prop="companyName" label="关联公司" min-width="120px">
          
        </el-table-column>
        <el-table-column align="center" prop="projectRoleName" label="角色" min-width="120px">
        </el-table-column>

        <el-table-column align="center" prop="mobile" label="电话" min-width="100px" ></el-table-column>

        <el-table-column align="center" v-if="data&&!data.readOnly"
          label="操作"
          width="270"
          fixed="right"
        >
          <div  slot-scope="{row}">
            <el-button v-if="row.deleted" type="text" icon="el-icon-delete" @click="removeUser(row)">移出</el-button>
          </div>
        </el-table-column>
      </el-table>
      <!-- <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination> -->
    </template>
    <!-- 新建/修改 -->
    <AddPerson ref="JoinUser" @success="userListByProject"/>
  </TableLayout>
</template>

<script>
import Pagination from '@/components/common/Pagination'
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import AddPerson from './AddPerson'
import {userListByProject,addUser,removeUser} from '../../../api/bussiness/person'

export default {
  name: 'project_person',
  extends: BaseTable,
  components: { AddPerson, TableLayout, Pagination },
   props: {
    projectBtns:{
      type:Boolean,
      default:true
    }
  },
  data () {
    return {
        searchForm: {
          account: '', // 名字
          realname: '', // 姓名
          rootDeptId: null, // 部门ID
          positionId: null, // 岗位ID
          mobile: '' // 手机号码
        },

        data:null,
    }
  },
  computed: {

  },
  created(){
    this.userListByProject();
  },
  methods: {
    userListByProject(){
      userListByProject({
        projectId:this.$route.query.pid  
      }).then(res=>{
        console.log(res,'--asa--');
        this.data = res
      }).catch(e=>{
        this.$tip.apiFailed(e)
      })
    },
    removeUser(row){
      this.$dialog.confirm('确定将当前人员移出项目？', '提示', {
        confirmButtonText: '确认移除',
        type: 'warning'
      }).then(() => {
        removeUser({
          projectId:this.$route.query.pid,
          userId:row.userId
        }).then(res=>{
          this.$tip.apiSuccess('移除成功')
          this.userListByProject()
        }).catch(e=>{
          this.$tip.apiFailed(e)
        }).finally(()=>{
        })
      })
    }
    
  }
}
</script>

<style lang="scss">

</style>
<style scoped lang="scss">
// @import "@/assets/style/variables.scss";
// 列表头像处理
.table-column-avatar {
  img {
    width: 48px;
  }
}
.sumInfo{
  color: #333333;
  font-size: 18px;
  font-family: 'Microsoft YaHei';
  font-weight: bold;
  /deep/ .el-timeline-item__tail{
    border-left: 2px dashed #999999;
  }
  /deep/ .el-timeline-item__timestamp{
    color: #333333 ;
    font-weight: 400;
    font-size: 18px !important;
  }
}


</style>
