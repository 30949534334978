<template>
  <TableLayout :permissions="['business:milepost:query']">

<!--   -->
    <template v-slot:table-wrap>
      <ul class="toolbar">
        <!--  -->
        <li v-if="projectBtns"  v-permissions="['business:milepost:save']"><el-button icon="el-icon-plus" type="primary" @click="$refs.addMilestone.open('新建里程碑')">新建里程碑</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        :default-sort = "{prop: 'createTime', order: 'descending'}"
        @row-click="(row)=>{$refs.milestoneDetail.open('里程碑详情',row)}"

        stripe
        border
        @sort-change="handleSortChange"
      >
        <el-table-column align="center" prop="milepostName" label="里程碑名称" min-width="100px" fixed="left"></el-table-column>
        <el-table-column align="center" prop="planEnd" label="预计达成日期" min-width="120px"></el-table-column>
        <el-table-column align="center" prop="completeDate" label="实际达成日期" min-width="120px"></el-table-column>
        <el-table-column align="center" prop="overdueDays" label="超期天数" min-width="120px"></el-table-column>
        <el-table-column align="center" prop="directorName" label="负责人" min-width="100px" ></el-table-column>
        <el-table-column align="center" prop="milepostStatusName" label="状态" min-width="100px" ></el-table-column>
        <el-table-column align="center" prop="createDate" label="里程碑发布日期" min-width="100px" ></el-table-column>

        <el-table-column align="center"
          label="操作"
          width="270"
          fixed="right"
          v-if="projectBtns"
        >
          <div  slot-scope="{row}">
             <el-button  v-if="row.operate" type="text"  @click.stop="(x)=>{$refs.addMilestone.open('编辑里程碑',{id:row.id})}" >编辑</el-button>
          </div>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination>
    </template>
    <!-- 新建/修改 -->

    <AddMilestone ref="addMilestone" @success="successCallback"/>
    <MilestoneDetail ref='milestoneDetail' @success="successCallback" ></MilestoneDetail>

  </TableLayout>
</template>

<script>
import Pagination from '@/components/common/Pagination'
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import AddMilestone from '@/components/bussiness/projectAddModal/AddMilestone'
import MilestoneDetail from '@/components/bussiness/projectDetail/MilestoneDetail'
import DepartmentSelect from '../../common/DepartmentSelect'

export default {
  name: 'project_milestone',
  extends: BaseTable,
  components: { AddMilestone,MilestoneDetail, TableLayout, Pagination,DepartmentSelect },
   props: {
    projectBtns:{
      type:Boolean,
      default:true
    }
  },
  data () {
    return {
      searchForm:{
        projectId:Number(this.$route.query.pid)
      },
    }
  },
  computed: {

  },
  created(){
    this.config({
      module: '里程碑',
      api: '/bussiness/milestone',
      'field.id': 'id',
      'field.main': 'milepostName',
      // sorts: [{
      //   property: 'CREATE_TIME',
      //   direction: 'DESC'
      // }]
    })
    this.search()

  },
  methods: {
    successCallback(){
      this.handlePageChange(this.tableData.pagination.pageIndex)
      this.$emit('success')
    }
  }
}
</script>

<style lang="scss">

</style>
<style scoped lang="scss">
// @import "@/assets/style/variables.scss";
// 列表头像处理
.table-column-avatar {
  img {
    width: 48px;
  }
}
.sumInfo{
  color: #333333;
  font-size: 18px;
  font-family: 'Microsoft YaHei';
  font-weight: bold;
  /deep/ .el-timeline-item__tail{
    border-left: 2px dashed #999999;
  }
  /deep/ .el-timeline-item__timestamp{
    color: #333333 ;
    font-weight: 400;
    font-size: 18px !important;
  }
}


</style>
