<template>
  <div class="page">
    <v-gantt-chart
        :startTime="times[0]"
        :endTime="times[1]"
        :cellWidth="cellWidth"
        :cellHeight="cellHeight"
        :titleHeight="titleHeight"
        :scale="43200"
        :titleWidth="titleWidth"
        
        :hideHeader="hideHeader"
        :dataKey="dataKey"
        :arrayKeys="arrayKeys"
        :scrollToPostion="positionA"
        @scrollLeft="scrollLeftA"
        :datas="datasA"
      >
        <template v-slot:block="{ data, item }">
          <Test
            :data="data"
            :updateTimeLines="updateTimeLines"
            :cellHeight="cellHeight"
            :currentTime="currentTime"
            :item="item"
          ></Test>
        </template>
        <template v-slot:left="{ data }">
          <TestLeft :data="data"></TestLeft>
        </template>
        <!-- <template v-slot:timeline="{ day , getTimeScales }">
          <TestTimeline :day="day" :getTimeScales="getTimeScales"></TestTimeline>
        </template> -->
        <template v-slot:title>项目名称 </template>
      </v-gantt-chart>
  </div>
</template>

<script>
import Test from "../../components/gant/test.vue"
import TestLeft from "../../components/gant/test-left.vue"
import TestTimeline from "../../components/gant/test-timeline.vue"
import { mockDatas } from "../../mock/index.js"
import dayjs from "dayjs"
const scaleList = `1,2,3,4,5,6,10,12,15,20,30,60,120,180,240,360,720,1440,2880,4320`
  .split(",")
  .map(n => {
    let value = parseInt(n)
    let label
    if(value<60){
        label=value+'minute'
    }else if(value>=60 && value< 1440){
        label=value/60+'hour'
    }else{
      label = value/1440 +'day'
    }
    return {
      value,
      label
    }
  })
export default {
  name: 'gant',
  components: {Test,TestLeft,TestTimeline},
  data(){
     return{
       data:[{id:'test',gtArray:[{start:'2019-01-11 18:18:18',end:'2019-01-11 18:18:18'}]}],
       timeLines: [
        {
          time: dayjs()
            .add(2, "hour")
            .toString()
        },
        {
          time: dayjs()
            .add(5, "hour")
            .toString(),
          color: "#747e80"
        }
      ],
      currentTime: dayjs(),
      cellWidth: 100,
      cellHeight: 30,
      titleHeight: 40,
      titleWidth: 250,
      scale: 60,
      // times: [
      //   dayjs() .subtract(5, "hour") .toString(),
      //   dayjs() .add(29, "day") .add(2, "hour") .toString()
      // ],
      times:['2019-01-11','2029-01-11'],
      rowNum: 12,
      colNum: 5,
      datasA: [],
      datasB: [],
      dataKey: "id",
      scaleList: scaleList,
      scrollToTime: dayjs()
        .add(1, "day")
        .toString(),
      scrollToPostion: { x: 10000, y: 10000 },
      hideHeader: false,
      hideSecondGantt: false,
      arrayKeys: ["gtArray", "error"],
      scrollToY: 0,
      positionB: {},
      positionA: {}
    }
  },
  watch:{
    
  },
  mounted() {
    this.updateData();
  },
  methods: {
    updateData() {
      this.datasA = mockDatas(this.rowNum, this.colNum, this.times);
    },
    updateTimeLines(timeA, timeB) {
      this.timeLines = [
        {
          time: timeA
        },
        {
          time: timeB,
          color: "#747e80"
        }
      ];
    },
    scrollLeftA(val) {
      this.positionB = { x: val };
    },
    scrollLeftB(val) {
      this.positionA = { x: val };
    }
  },
  created () {
    let pid = this.$route.query.pid;
    pid && this.showDetail(pid);
    // if(pid){
    //   // this.showDetail(pid);
    // }
  }
}
</script>

<style scoped lang="scss">
/deep/.el-tabs__nav-wrap::after{
  background: none;
}
/deep/ .el-tabs__item{
font-size: 18px;
font-family: 'Microsoft YaHei';
font-weight: bold;
}

/deep/ .gantt-blocks{
  background: none;
}

.page{
  color: #333333;
  font-size: 16px;
  font-family: 'Microsoft YaHei';
  margin:30px 20px;
}
.detailTitle{
  display: flex;
  margin-bottom: 37px;

  .info{
    flex: 3;
    .top{
      font-weight: bold;
      display: flex;
      margin:30px 0px 35px 0;
      div{
        margin-right: 20px;
      }

      .status{
        background: #5470C6;
        border-radius:1.5rem;
        color: #fff;
        padding:4px 33px;
      }
    }
    .bottom{
      display: flex;
      align-items: center;

      .left{
        display: flex;
        align-items: center;
        margin-right: 30px;
        .avator{
          width:60px;
          height:60px;
          border-radius: 50%;
        }
        .other{
        border-right: 2px solid #CCCCCC;
        display: flex;
        flex-direction: column;
        padding-left: 12px;
        padding-right: 30px;


          .name{
            font-size: 18px;
            font-weight: bold;
          }
          .dept{
            font-size: 14px;
          }
        }

      }
      .right{
        font-size: 14px;
        line-height: 1.4rem;
        font-weight: 400;
      }
    }
  }

  .btns{
    flex: 2;
    text-align: right;
    margin-right: 20px;
  }
}

</style>
