<template>
  <TableLayout >
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="120px" inline>
      <el-form-item label="项目名称" prop="projectName">
        <el-input class="fixWidth" v-model="searchForm.projectName" v-trim placeholder="请输入项目名称" @keypress.enter.native="search"/>
      </el-form-item>
      <el-form-item label="项目状态" >
        <el-select  class="fixWidth" v-model="searchForm.status" clearable  @change="search">
          <el-option :key='0' :value="0" label="预启动"/>
          <el-option :key='1' :value="1" label="进行中"/>
          <el-option :key='2' :value="2" label="已暂停"/>
          <el-option :key='3' :value="3" label="已完成"/>
          <el-option :key='4' :value="4" label="已取消"/>
        </el-select>
      </el-form-item>

      <el-form-item label="项目负责人" prop="userName">
        <LeaderSelect  class="fixWidth" v-model="searchForm.userId" :clearable="true"></LeaderSelect>

        <!-- <el-input v-model="searchForm.userName" v-trim placeholder="请输入项目负责人" @keypress.enter.native="search"/> -->
      </el-form-item>
      <el-form-item label="计划工期" prop="time">
        <el-date-picker  class="fixWidth"
          v-model="time"
          type="datetimerange"
          range-separator="至"
          value-format="yyyy-MM-dd"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
        ></el-date-picker>
          <!-- @change="handleSearchTimeChange" -->

      </el-form-item>
      <el-form-item label="关联公司" prop="departmentId">
         <CompanySelect  class="fixWidth" v-model="searchForm.departmentId" placeholder="请选择用户关联子公司" :inline="true" clearable/>
      </el-form-item>

      <!-- <el-form-item label="所属部门" prop="positionId">
        <el-select v-model="searchForm.exceptionLevel" clearable @change="search">
          <el-option value="10" label="高"/>
          <el-option value="5" label="中"/>
          <el-option value="0" label="低"/>
        </el-select>
      </el-form-item> -->

      <el-form-item label="项目类型" prop="positionId">
        <el-select  class="fixWidth" v-model="searchForm.projectType" clearable @change="search">
          <el-option
            v-for="item in setting.projectTypes"
            :key="item.id"
            :label="item.content"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="项目级别" prop="positionId">
        <el-select class="fixWidth" v-model="searchForm.projectLevel" clearable @change="search">
          <el-option
            v-for="item in setting.projectLevels"
            :key="item.id"
            :label="item.content"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <section  class="fixWidth" style="text-align:right">
        <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <template v-slot:table-wrap>
      <div style="text-align:right;margin:0px 0px 10px" v-permissions="['business:project:export']">
        <el-button type="primary" @click="download(2)" v-permissions="['business:project:export']" :loading="loadingTwo">下载所有项目详情进度表</el-button>
        <el-button type="primary" @click="download(1)" v-permissions="['business:project:export']" :loading="loadingOne">下载所有项目概览</el-button>
      </div>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        row-key="id"
        @row-click="todetail"
        stripe
        border
        default-expand-all
        @selection-change=""
      >
        <el-table-column type="selection" fixed="left" width="55"></el-table-column>
        <el-table-column prop="projectNo" label="项目编号" fixed="left" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="projectName" label="项目名称" fixed="left" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="projectTypeName" label="项目类型" fixed="left" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="planDuration" label="计划工期" fixed="left" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="actualDuration" label="实际工期" fixed="left" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="overdue" label="是否超期" fixed="left" min-width="100px" align="center">
          <div slot-scope="{row}">
            {{row.overdue?'是':'否'}}
          </div>
        </el-table-column>
        <el-table-column prop="score" label="得分" fixed="left" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="createDate" label="创建时间" fixed="left" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="directorName" label="负责人" fixed="left" min-width="100px" align="center"></el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination>
    </template>
  </TableLayout>
</template>

<script>
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import { mapState, mapMutations } from 'vuex'
import { fetchTree } from '@/api/system/department'
import BaseTable from '@/components/base/BaseTable'
import OperaDepartmentWindow from '@/components/system/department/OperaDepartmentWindow'
import DepartmentUserWindow from '@/components/system/department/DepartmentUserWindow'
import CompanySelect from '../../components/common/CompanySelect.vue'
import LeaderSelect from '../../components/common/LeaderSelectProject'
import {download} from '../../api/common'

export default {
  name: '',
  extends: BaseTable,
  components: { DepartmentUserWindow, OperaDepartmentWindow, TableLayout , Pagination , CompanySelect , LeaderSelect },
  computed:{
     ...mapState({
      setting: state=>state.setting
    }),
  },
  data(){
    return{
      active:0,
      checkList:[],
      linkUrl:process.env.VUE_APP_BASE_URL+process.env.VUE_APP_API_PREFIX+'/business/project/downloadAllOverview',//弃用
      loadingTwo:false,
      loadingOne:false,
      // itemOption:[
      //   {name:'项目',activated:true,status:[
      //       {name:'已超期',checked:true},
      //       {name:'预启动',checked:false},
      //       {name:'已暂停',checked:false},
      //     ]},
      //     {name:'任务',activated:false,status:[
      //       {name:'未发布',checked:true},
      //       {name:'待启动',checked:false},
      //       {name:'暂停中',checked:false},
      //       {name:'已超期',checked:false},
      //     ]},
      //     {name:'里程碑',activated:false,status:[
      //       {name:'已超期',checked:true},
      //       // {name:'预启动',activated:false},
      //       // {name:'已暂停',activated:false},
      //     ]},
      //     {name:'问题',activated:false,status:[
      //       {name:'未处理',checked:true},
      //       {name:'处理中',checked:false},
      //     ]},
      // ],
      // 搜索
      time:'',
      searchForm: {
        keyword:null,
        // companyId:null,
        departmentId:null,
        planDuration:'',
        planBegin:null,
        planEnd:null,
        projectLevel:null,
        projectName:'',
        status:null,
        projectType:null,
        userId:null,
        showDeleted:false,
      },
    }
  },
  watch:{
    checkList:{
      handler(val){
        let checkedList = this.itemOption[this.active].status.filter(i=>i.checked)
        console.log(checkedList,'watch,checklist');
      }
    },
    'searchForm.userId':{
      handler(val,olv){
        this.search()
      },
      immediate:true
    },
    'searchForm.departmentId':{
      handler(val,olv){
        this.search()
      },
      immediate:true
    },
    'time':{
      handler(val,olv){
        console.log(val,olv,'--计划工期');
        if(val==null){
          this.searchForm.planBegin = null
          this.searchForm.planEnd = null
        }else{
          this.searchForm.planBegin = val[0]
          this.searchForm.planEnd = val[1]
        }
        this.search()
      },
      immediate:true
    }
  },
  methods: {
    reset(){
      this.time = '',
      this.searchForm= {
        keyword:null,
        // companyId:null,
        departmentId:null,
        planDuration:'',
        planBegin:null,
        planEnd:null,
        projectLevel:null,
        projectName:'',
        status:null,
        projectType:null,
        userId:null,
        showDeleted:false,
      }
    },
    download(type){
      let downLoadName = ''
      if(type==1){
        downLoadName = '项目概览'
        this.loadingOne = true
      }else{
        downLoadName = '详情进度表'
        this.loadingTwo = true
      }
      download({
        fileName:downLoadName,
        type:type
      }).then(res=>{
        var blob = new Blob([res.data], {type: 'application/vnd.ms-excel'}); //type这里表示xlsx类型
    　　var downloadElement = document.createElement('a');
    　　var href = window.URL.createObjectURL(blob); //创建下载的链接
    　　downloadElement.href = href;
    　　downloadElement.download = downLoadName+'.xlsx'; //下载后文件名
    　　document.body.appendChild(downloadElement);
    　　downloadElement.click(); //点击下载
    　　document.body.removeChild(downloadElement); //下载完成移除元素
    　　window.URL.revokeObjectURL(href); //释放掉blob对象 
        })
        .catch(err => {
            this.$message({
                message:'下载失败！',
                type:'error',
                showClose:true
            })
        })
        .finally(res=>{
          this.loadingOne = false
          this.loadingTwo = false
        })
    },

    changeButtons(item,index){
      this.itemOption[this.active].activated = false
      this.itemOption[index].activated = true
      this.active = index
      this.checkList = []
    },
    handleCheckedStatus(value){
      console.log(value,'checked change status ');
      this.itemOption[this.active].status.map((item,index)=>{
        console.log();
        if(value.findIndex(item.name)>-1){
          item.checked = true
        }else{
          item.checked = false
        }
      })
    },
    //项目详情
    todetail(row, column, event){
      console.log(row);
      this.$router.push({
        path:'/detail',
        query:{
          pid:row.id
        }
      })
    },
  },
  created () {
    if(this.$route.query){
      let query = this.$route.query
      this.searchForm.departmentId = query.companyId?Number(query.companyId):null
      this.searchForm.projectType = query.projectType?Number(query.projectType):null
      this.searchForm.status = query.status?Number(query.status):null 
    }
    this.config({
      module: '项目',
      api: '/manage/projectBoard'
    })
    this.search()
  }
}
</script>

<style scoped lang="scss">
.table-layout {
  /deep/ .table-content {
    margin-top: 0;
  }
}
.fixWidth{
  width:250px !important;
}
</style>
