<template>
 <div class="test">
   {{day}}
  <span v-for="i in getTimeScales(day)">测试后四十岁 {{i.format('HH:mm')}}</span>
 </div> 
</template>

<script>
export default {
  name: "TestLeft",
  props: {
    day: Object,
    getTimeScales:Function,
  }
};
</script>

<style lang="scss" scoped>
.test{
  display: flex;

  span{
    flex:1
  }
}
</style>
