<template>
<div>
  <header>
    <span>
      <a>授权类型:</a>
      <el-select v-model="value" placeholder="请选择" @change="gradeChange">
        <el-option v-for="item in shouquan" :key="item.id" :label="item.label" :value="item.id"></el-option>
      </el-select>
    </span>
    <span>
      <a v-show="dispy">所属项目:</a>
      <el-tree v-show="dispy" :data="list" v-model="value1" placeholder="请选择" @change="gradeChange1">
        <!-- <el-option
          v-for="item in shouquan[1].xiangmu"
          :key="item.id"
          :label="item.label"
          :value="item.id"
        ></el-option> -->
      </el-tree>
    </span>
    <span>
      <a>人员:</a>
      <el-select v-model="value2" placeholder="请选择" @change="gradeChange2">
        <el-option v-for="item in options" :key="item.id" :label="item.label" :value="item.id"></el-option>
      </el-select>
    </span>
  </header>
  <body>
    <span>
      <el-transfer
        style="text-align: left; display: inline-block "
        v-model="value4"
        filterable
        :left-default-checked="[]"
        :right-default-checked="[]"
        :titles="['未绑定', '绑定']"
        :button-texts="[]"
        :format="{
        noChecked: '${total}',
        hasChecked: '${checked}/${total}'
        }"
        @change="handleChange"
        :data="search(name)"
      >
        <span slot-scope="{ option }">{{ option.label }}</span>
      </el-transfer>
    </span>
  </body>
</div>
</template>

<script>
import Axios from "axios";
export default {
  data() {
    return {
      data: [],
      value4: [],
      renderFunc(h, option) {
        return <span>{option.label}</span>;
      },
      dispy: false,
      name: "",
      shouquan: [
        {
          id: "2",
          label: "菜单"
        },
        {
          id: "1",
          label: "设备",
          xiangmu: []
        }
      ],
      options: [
      ],
      value: "",
      value1: "",
      value2: "",
      list: [],
      list1: [],
      list2: [],
      gongjuren: [],
      mun: []
    };
  },
  created() {
    this.fuzhi();
  },
  methods: {
    fuzhi() {
      this.list1=[{
          id: 1,
          label: '一级 1',
          children: [{
            id: 4,
            label: '二级 1-1',
            children: [{
              id: 9,
              label: '三级 1-1-1'
            }, {
              id: 10,
              label: '三级 1-1-2'
            }]
          }]
        }, {
          id: 2,
          label: '一级 2',
          children: [{
            id: 5,
            label: '二级 2-1'
          }, {
            id: 6,
            label: '二级 2-2'
          }]
        }, {
          id: 3,
          label: '一级 3',
          children: [{
            id: 7,
            label: '二级 3-1'
          }, {
            id: 8,
            label: '二级 3-2',
            children: [{
             id: 11,
              label: '三级 3-2-1'
            }, {
              id: 12,
              label: '三级 3-2-2'
            }, {
              id: 13,
              label: '三级 3-2-3'
            }]
          }]
        }]
      this.list = this.list1
      // var api = "";
      // Axios.get(api)
      //   .then(response => {
      //     for (var i = 0; i < response.data.result.length; i++) {
      //       this.gongjuren = {
      //         id: response.data.result[i].id,
      //         label: response.data.result[i].name,
      //         key: response.data.result[i].id,
      //         disabled:response.data.result[i].scope / 1 === 1,
              
      //       };
      //       this.list.push(this.gongjuren);
            
      //     }
      //     //this.data = response.data.result;
      //     // console.log(this.list);
      //   })
      //   .catch(error => {
      //     console.log(error);
      //   });
      // var api = "";
      // Axios.get(api)
      //   .then(response => {
      //     for (var i = 0; i < response.data.result.length; i++) {
      //       this.gongjuren = {
      //         id: response.data.result[i].id,
      //         label: response.data.result[i].name,
      //         key: response.data.result[i].id,
      //         disabled:response.data.result[i].scope / 1 === 1,
      //         scope:response.data.result[i].scope
      //       };
      //       this.list1.push(this.gongjuren);
      //     }
      //   })
      //   .catch(error => {
      //     console.log(error);
      //   });
      // var api = "";
      // Axios.get(api)
      //   .then(response => {
      //     for (var i = 0; i < response.data.result.length; i++) {
      //       this.gongjuren = {
      //         id: response.data.result[i].id,
      //         label: response.data.result[i].projectName,
      //         key: i
      //       };
      //       this.shouquan[1].xiangmu.push(this.gongjuren);
      //     }
      //   })
      //   .catch(error => {
      //     console.log(error);
      //   });
      // var api = "";
      // Axios.get(api)
      //   .then(response => {
      //     for (var i = 0; i < response.data.result.length; i++) {
      //       this.gongjuren = {
      //         id: response.data.result[i].id,
      //         label: response.data.result[i].name,
      //         bangding:[],
      //       };
      //       this.options.push(this.gongjuren);
      //     }
      //   })
      //   .catch(error => {
      //     console.log(error);
      //   });
    },
    gradeChange(value) {
      console.log(value);
      if (this.value == 2) {
        this.dispy = false;
        this.value1 = "";
        this.name = ""; 
        this.data = this.list;
        for(var i=0;i<this.list.length;i++){
          if(this.list.scope = 1){
            this.mun.push(this.list[i].id)
          }
        }
      } else {
        this.dispy = true;
        this.data = this.list1;
      }
    },
    gradeChange1(value1) {
      console.log(value1);
      for (var i = 0; i < this.shouquan[1].xiangmu.length; i++) {
        if (this.value1 == this.shouquan[1].xiangmu[i].id) {
          this.name = this.shouquan[1].xiangmu[i].label;
        }
      }
    },
    gradeChange2(value2) {
      var api = "";
      Axios.get(api)
        .then(response => {
         for(var i =0;i<response.data.result.length;i++){
           for(var j=0;j<this.options.length;j++){
             if(this.options[j].id == value2){
              this.options[j].bangding.push(response.data.result[i].sourceid)
           }
           }
         }
        })
        .catch(error => {
          console.log(error);
        });
      for (var i = 0; i < this.options.length; i++) {
        if (value2 == this.options[i].id) {
          this.value4 = this.options[i].bangding;
        }
      }
      for(var i=0; i<this.mun.length;i++){
         this.value4.push(this.mun[i])
      }
     
    },
    search(name) {
      return this.data.filter(item => {
        if (item.label.includes(name)) {
          return item;
        }
      });
    },
    handleChange(value, direction, movedKeys) {
      if (this.value2 == "") {
        this.$confirm("请选择授权的人员");
      } else {
            Axios.post(
              "" ,
              {
                  type:parseFloat(this.value),
                  sourceIds: movedKeys,
              }
            )
              .then(response => {
                console.log(response);
              })
              .catch(error => {
                console.log(error);
              });
      }
      for (var i = 0; i < this.options.length; i++) {
        if (this.value2 == this.options[i].id) {
            this.options[i].bangding = this.value4;
        }
      }
      console.log(value, direction, movedKeys);
    }
  }
};
</script>

<style scoped lang="scss" scoped>
.transfer-footer {
  margin-left: 20px;
  padding: 6px 5px;
}
span {
  margin-left: 20px;
}

/deep/.el-button + .el-button {
  margin-left: 0px !important;
}

/deep/ .el-button {
  display: block !important;
  border-radius: 30%;
}
/deep/.el-transfer__button i,
.el-transfer__button span {
  font-size: 20px !important;
}
/deep/.el-transfer-panel__list.is-filterable {
    height: 500px !important;
    padding-top: 0;
}
/deep/.el-transfer-panel {
    height: 500px !important;
}
</style>