<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    v-if="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <!-- :withFooter='false' -->
<!--  -->
    <el-form :model="form" ref="form"  label-width="120px" label-position="right" style="width:100%" >
 
      <el-form-item label="资料类型" prop="type" :rules="{ required: true, message: '请选择资料类型', trigger: 'blur' }">
        <el-select v-model="form.type" placeholder="请选择">
          <el-option :key="1" label="说明" :value="1"> </el-option>
          <el-option :key="2" label="项目规范" :value="2"> </el-option>
          <el-option :key="3" label="项目手册" :value="3"> </el-option>
        </el-select>
      </el-form-item>
      <!-- <section>
        <el-button type="primary" @click="confirm">确定</el-button>
        <el-button type="primary">取消</el-button>
      </section> -->
     
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import {fetchList,deleteEnclosureById,updateEnclosureType} from '../../../api/bussiness/projectData'

export default {
  name: 'OperaRoleWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      form: {
        type:1,
        tableTitle:null,
        id:null,
      },

    }
  },
  methods: {
    open (title, target) {
      this.title = title
      this.visible = true
      this.form.id = target.id
      this.form.tableTitle = target.tableTitle
      console.log(target,'09090909--');
      // // 新建
      // if (target == null) {
      //   this.$nextTick(() => {
      //     this.$refs.form.resetFields()
      //     this.form[this.configData['field.id']] = null
      //   })
      //   return
      // }
      // // 编辑
      // this.$nextTick(() => {
      //   this.originRoleCode = target.code
      //   for (const key in this.form) {
      //     this.form[key] = target[key]
      //   }
      // })
    },
    confirm () {
      updateEnclosureType(this.form).then(res=>{
        this.$tip.apiSuccess('修改成功')
        this.$emit('success')
      }).catch(e=>{
        this.$tip.apiFailed(e)
      }).finally(()=>{
        this.visible = false
        this.isWorking = false
      })
    }
  },
  created () {
    // this.config({
    //   api: '/system/role'
    // })
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-form-item{
  display: flex;
}
/deep/ .el-form-item__content{
  margin-left: 0 !important;
  flex: 1;
}
</style>