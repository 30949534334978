<template>
<GlobalWindow
    :title="title"
    :visible.sync="visible"
    @confirm="confirm"
    :withFooter='false'
  >
    <!-- :confirm-working="isWorking" -->
<el-row class="sumInfo">
  <el-col :span="24" class="left">

    <div class="statusBlock">
        <div class="statusItem">
            <div class="label">状态</div>
            <div class="content">{{data.questionStatusStr}}</div>
        </div>
        <!-- <div class="statusItem">
            <div class="label">工期进度</div>
            <div class="content">30%</div>
        </div>
        <div class="statusItem">
            <div class="label">自评进度</div>
            <div class="content">30%</div>
        </div> -->
    </div>

    <div class="infoBlock">
        <div class="left">
            <img :src="data.creatorImageUrl" alt="">
            <div class="leftContent">
                <div class="name">{{data.creatorName}}</div>
                <!-- <div class="special">不知道是个啥</div> -->
            </div>
        </div>
        <div class="right">
            <div>提交时间:{{data.entity.createDate?data.entity.createDate.split(' ')[0]:''}}</div>
            <div>所属项目:{{data.entity.projectId}} {{data.projectName}}</div>
        </div>
    </div>

    <div class="block" >
        <div class="blockTitle">
            <i class="el-icon-reading"></i>
            <span class="">问题详情</span>
        </div>
        <div class="blockContent contentBlocks">
            <div class="contentLine">
                <div class="label">问题名称:</div>
                <div class="content">{{data.entity.questionName}}</div>
            </div>
            <div class="contentLine">
                <div class="label">问题类型:</div>
                <div class="content">{{data.questionTypeStr}}</div>
            </div>
            <div class="contentLine">
                <div class="label">问题解决对象:</div>
                <div class="content">{{data.mainDeliveryName}}</div>
            </div>
            <div class="contentLine">
                <div class="label">抄送对象:</div>
                <div class="content">
                  <span v-for="(item,key) in data.userVoList" :key='key'>{{item.name}}<span v-if="key<data.userVoList.length-1">、</span></span>
                </div>
            </div>
            <div class="contentLine">
                <div class="label">问题说明:</div>
                <div class="content">{{data.entity.questionExplain}}</div>
            </div>
        </div>
    </div>

    <div class="block" >
        <div class="blockTitle">
            <i class="el-icon-reading"></i>
            <span class="">资料</span>
            <!-- <el-button type="primary">上传附件</el-button> -->
        </div>
        <div class="blockContent contentTable">
          <file-upload-table :data="data.enclosureVoList" @delete="deleteEnclosure"></file-upload-table>
        </div>
    </div>
    <div class="block"  >
        <div class="blockTitle">
        <i class="el-icon-reading"></i>
        <span class="">回复情况</span>
        </div>
        <div class="blockContent contentLine">
        <el-timeline  class="timeLine" v-if="data.recordVoList.length>0">
            <el-timeline-item
            placement="top"
            v-for="(activity, index) in data.recordVoList"
            :key="index"
            icon="el-icon-time"
            size="large"
            color="#999999"
            :timestamp="activity.operatorTime">
            <div class="content">
              <div style="display:flex;align-items:center;">
                <img class="avator" :src="activity.operatorImageUrl" alt="">
                <span>{{activity.operatorName}}</span>
              </div>
                <div class="info">
                <div class="content">
                  <div>
                    <!-- {{activity.content}} -->
                    <span style="display:block" v-for="i in activity.content.split('<br />')">{{i}}</span>
                  </div>
                  <div style="text-align:right" v-if="activity.delete"><i class="el-icon-delete"  style="cursor:pointer"  @click="deleteFollowUpByQuestionId(activity.id)"></i></div>
                </div>
                </div>

            </div>
            </el-timeline-item>
        </el-timeline>
        <el-input type="textarea" maxlength="30" show-word-limit v-model="content" v-trim placeholder="请输入内容"v-if="data.buttons.findIndex(i=>i==14)!=-1"></el-input>
        <el-button style="margin:20px 0" type="primary" @click="creatQuestionReply" :disabled="content.length<=0" v-if="data.buttons.findIndex(i=>i==14)!=-1">回复</el-button>
        <!-- :loading="loading" -->

        </div>
    </div>

  </el-col>
  <el-col  class="pageDetailBtn">
        <!-- <el-button v-if="data.buttons.findIndex(i=>i==7)!=-1" @click="$refs.editWeekLog.open('编辑周报',data)">编辑</el-button> -->
        <el-button v-if="data.buttons.findIndex(i=>i==7)!=-1" @click="$refs.addQuestion.open('编辑问题',data)">编辑</el-button>
        <el-button v-if="data.buttons.findIndex(i=>i==9)!=-1"  @click="deleteQuestion(data.entity.id)">删除</el-button>

        <!-- <el-button v-buttons="[...data.buttons,7]" @click="$refs.addQuestion.open('编辑问题',data)">编辑</el-button>
        <el-button v-buttons="[...data.buttons,9]"  @click="deleteQuestion(data.entity.id)">删除</el-button> -->

  </el-col>

</el-row>
<AddQuestion ref="addQuestion" @success="open('问题详情',{id:data.entity.id})"/>

</GlobalWindow>
</template>

<script>
import * as echarts from 'echarts'
import GlobalWindow from '@/components/common/GlobalWindow'
import { queryQuestionInfoById, creatQuestionReply, deleteFollowUpByQuestionId, deleteEnclosure, deleteQuestion } from '@/api/bussiness/question'
import AddQuestion from '@/components/bussiness/projectAddModal/AddQuestion'
import FileUploadTable from '../../common/FileUploadTable'

// import BaseOpera from '@/components/base/BaseOpera'

export default {
  name: 'questionDetail',
  components: { GlobalWindow, AddQuestion, FileUploadTable },
  data () {
    return {
      data: {},
      content: '',
      visible: false,
      activities: [
        { content: '活动按期开始', timestamp: '2018-04-15', name: '郭宝宝', dept: '行政部' },
        { content: '通过审核', timestamp: '2018-04-13', name: '郭宝宝', dept: '行政部' },
        { content: '创建成功', timestamp: '2018-04-11', name: '郭宝宝', dept: '行政部' },
        { content: '创建成功', timestamp: '2018-04-11', name: '郭宝宝', dept: '行政部' },
        { content: '创建成功', timestamp: '2018-04-11', name: '郭宝宝', dept: '行政部' },
        { content: '创建成功', timestamp: '2018-04-11', name: '郭宝宝', dept: '行政部' },
        { content: '创建成功', timestamp: '2018-04-11', name: '郭宝宝', dept: '行政部' },
        { content: '创建成功', timestamp: '2018-04-11', name: '郭宝宝', dept: '行政部' },
        { content: '创建成功', timestamp: '2018-04-11', name: '郭宝宝', dept: '行政部' }
      ],
      lineActive: 0// 默认项目级动态

    }// return
  },
  computed: {
    // 确认按钮文案
    confirmButtonText () {
      return this.allowCopy ? '复制' : '确定'
    },
    // 格式化后的内容
    formattedContent () {
      let content = this.content
      if (this.analyse) {
        try {
          content = JSON.stringify(JSON.parse(this.content), null, 2)
        } catch (e) {
        }
      }
      return content
    }
  },
  mounted () {

  },
  methods: {
    open (title, target) {
      this.title = title
      this.visible = true
      console.log(target, '---')
      this.queryQuestionInfoById(target.id)
    },
    queryQuestionInfoById (id) {
      queryQuestionInfoById({
        id: id
      }).then(res => {
        console.log(res, '问题详情')
        this.data = res || {}
        this.data.buttons = this.data.buttons ? this.data.buttons : []
        // this.data = {
        //   ...this.data,
        //   id:target.id
        // }
      }).catch(e => {
        this.$tip.apiFailed(e)
      })
    },

    creatQuestionReply () {
      creatQuestionReply({
        content: this.content,
        questionId: this.data.entity.id
      }).then(res => {
        console.log(res)
        this.$tip.apiSuccess('跟进添加成功')
        this.content = ''
        this.lineActive = 1
        this.queryQuestionInfoById(this.data.entity.id)
        this.$emit('success', 1)
      }).catch(e => {
        this.$tip.apiFailed(e)
      })
    },
    deleteFollowUpByQuestionId (id) {
      deleteFollowUpByQuestionId({ id: id }).then(res => {
        this.queryQuestionInfoById(this.data.entity.id)
      }).catch(e => {
        this.$tip.apiFailed(e)
      })
    },
    deleteQuestion (id) {
      this.$dialog.deleteConfirm('确定要删除问题吗' + id).then(res => {
        deleteQuestion({ id: id }).then(res => {
          this.$tip.apiSuccess('删除成功')
          this.$emit('success', 1)
          this.$emit('refresh')
          this.visible = false
        }).catch(e => {
          this.$tip.apiFailed(e)
        })
      })
    },
    deleteEnclosure (e) {
      console.log(e, '问题附件')
      deleteEnclosure({ id: e.data.id }).then(res => {
        this.$tip.apiSuccess('删除成功')
        this.queryQuestionInfoById(this.data.entity.id)
      }).catch(e => {
        this.$tip.apiFailed(e)
      })
    }
  }
}
</script>

<style lang="scss">

</style>
<style scoped lang="scss">
.sumInfo{
  color: #333333;
  font-size: 14px;
  font-family: 'Microsoft YaHei';
  font-weight: bold;
  padding: 20px;
}
.statusBlock{
    display: flex;

    align-items: center;
    .statusItem{
        display: flex;
        align-items: center;
        margin-right: 30px;
        .label{
            margin-right: 10px;
        }
        .content{
            background: #5470C6;
            border-radius: 1.5rem;
            padding: 4px 20px;
            color: #fff;
        }
    }
}
.infoBlock{
    display: flex;
    align-items: center;
    margin-top: 40px;
    .left{
        display: flex;
        align-items: center;
        border-right:2px solid #CCCCCC;
        margin-right: 12px;
        img{
            width: 60px;
            height: 60px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 12px;
            background: #edc;

        }
        .leftContent{
            margin-right: 12px;
            .name{
                font-size: 18px;
            }
            .special{
                font-weight: 400;
            }
        }

    }
    .right{
        font-weight: 400;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        div{
            line-height: 1.5rem;
        }
    }
}
.infoLine{
    margin: 20px 0 0 0;
    padding-bottom: 10px;
    border-bottom:1px solid #CCCCCC ;
}

.block{
  border-bottom: 1px solid #CCCCCC;
  padding: 12px 14px;
  .blockTitle{
    i{
      color: #1A69FC;
      font-weight: bold;
      margin-right: 3px;
    }
    span{

    }
  }
  .blockContent{
  }

}
.block .contentBlocks{
    .contentLine{
        display: flex;
        margin: 10px 0px;
        .label{
            flex-shrink: 0;
            margin-right: 10px;
        }
        .content{
            font-weight: 400;
        }
    }
}
.block .contentChart{
  display: flex;
  .chartItem{
    flex: 1;
    flex-shrink: 0;
  }

}
.block .contentSimp{
    margin: 20px;
    .blockItem{
      margin-bottom: 12px;
      .title{

      }
      .value{
        color: #FF5555;
        margin-right:6px ;
      }
      .unit{
        font-weight: 400;
      }
    }
}

.block .contentTable{
  margin:20px 0;
}
.block >.contentLine{
  margin: 10px 20px;
  .tabs{
    font-weight: 400;
    span{
      margin-right: 10px;
      cursor: pointer;
    }
    .tabAct{
      font-weight:bold
    }
  }
  .timeLine{
    margin-top: 10px;
    min-height: 100px;
    max-height: 500px;
    overflow-x: auto;
    padding-left: 10px;

  &::-webkit-scrollbar {
    width: 0px;
  }
    .content{
    //   display: flex;
      margin-top: 29px;
      .avator{
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-right: 12px;
      }
      .info{
        .name{
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
        }
        .dept{
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }
        .content{
          margin-top: 29px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          display: flex;
          div{
            flex: 1;
            flex-shrink: 0;
          }
        }
      }
    }
  }
}
</style>
