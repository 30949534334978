

export const projectLevel=[
    {id:1,label:'1级',},
    {id:2,label:'2级'},
    {id:3,label:'3级'},
    {id:4,label:'4级'},
    {id:5,label:'5级'},
]

export const projectStatus=[
    {id:1,label:'预启动'},
    {id:2,label:'进行中'},
    {id:3,label:'已取消'},
    {id:4,label:'已暂停'},
    {id:5,label:'已完成'},
]


export const taskType=[
    {id:1,label:'设计任务'},
    {id:2,label:'开发任务'},
    {id:3,label:'调研任务'},
    {id:4,label:'实施任务'},
    {id:5,label:'验收任务'},
]

export const pointLevel=[//紧急程度
    {id:1,label:'紧急'},
    {id:2,label:'高'},
    {id:3,label:'中'},
    {id:4,label:'低'},
]
export const questionType=[ //问题类型
    {id:1,label:'结果问题'},
    {id:2,label:'风险问题'},
    {id:3,label:'人员问题'},
    {id:4,label:'设备问题'},
    {id:5,label:'过程问题'},
    {id:6,label:'技术问题'},
    {id:7,label:'设计问题'},
    {id:8,label:'质量问题'},
    {id:9,label:'其它问题'},
]
export const questionStatus=[ //问题状态
    {id:1,label:'未处理'},
    {id:2,label:'处理中'},
    {id:3,label:'已解决'},
]

export const dataType=[ //资料类型
    {id:1,label:'说明'},
    {id:2,label:'项目规范'},
    {id:3,label:'项目手册'},
]

