<template>
<div class="curPage">
    <el-row class="tabs">
        <el-col :span="12">
            <div class="tab_tab">
                <span class="tabItem" :class="activeItem==0?'activeItem':''" @click="activeItem=0">进度概要<span></span> </span>
                <span size="large" class="tabItem" :class="activeItem==1?'activeItem':''" @click="activeItem=1">任务<span></span> </span>
                <span size="large"class="tabItem" :class="activeItem==2?'activeItem':''" @click="activeItem=2">里程碑<span></span> </span>
            </div>
        </el-col>
        <!-- <el-col :span="12">
        </el-col> -->
    </el-row>
    <el-row class="content">
        <el-col :span="24" class="content1" v-if='activeItem==0&&pageData'>
            <!-- 进度概要 -->
            <div class="block">
                <div class="title">项目关键节点路线图</div>
                <div class="blockContent contentChart">
                    <div class="block1">
                        <span class="title">工期进度</span>
                        <el-progress  :percentage="pageData.projectSpeed?pageData.projectSpeed:0"></el-progress>
                    </div>
                    <div class="block2">
                        <el-timeline  class="timeLine">
                            <el-timeline-item
                            placement="top"
                            v-for="(activity, index) in pageData.progressBars"
                            :key="index"
                            :icon="activity.icon"
                            :color="activity.color"
                            style
                            size="large"

                            :timestamp="activity.operatorTime">
                            <div class="content">
                                <span style="display:block"  v-for="item in activity.operatorContent.split('<br />')">{{item}}</span>
                            </div>

                            </el-timeline-item>
                        </el-timeline>
                    </div>
                    <div class="block3">
                        <div id='chart1' class="chart" style="">任务状态统计</div>
                        <div id='chart2' class="chart">里程碑状态统计</div>
                    </div>

                </div>
            </div>
            <div class="block">
                <div class="title">项目进展 </div>
                <div class="blockContent ">

                    <div v-if="!textareaEdit">
                        <span style="display:block" v-for="item in pageData.progressDescription.trim().split('\n')">{{item}}</span>
                        <i v-if="pageData.editInfo"  class="el-icon-edit" style="font-size:18px;cursor:pointer" @click="textareaEdit=true"></i>
                    </div>
                    <div v-else>
                        <el-input  v-model="pageData.progressDescription" type="textarea" :autosize="{ minRows: 4}" placeholder="请输入项目进展"></el-input>
                        <el-button style="margin:10px 0" type="primary" @click="updateSingleInfo" :loading="textareaLoading">保存</el-button>
                    </div>
                   <!-- <el-table border :data="pageData.overdueEnd">
                        <el-table-column prop="typeName" label="类型" align="center"></el-table-column>
                        <el-table-column prop="name" label="名称" align="center"></el-table-column>
                        <el-table-column prop="directorName" label="负责人" align="center"></el-table-column>
                        <el-table-column prop="planDate" label="计划结束" align="center"></el-table-column>
                        <el-table-column prop="overdueDays" label="超期天数" align="center"></el-table-column>

                    </el-table>  -->
                </div>
            </div>
            <div class="block">
                <div class="title">超期未结束({{pageData.overdueEndNum}})</div>
                <div class="blockContent contentTable">
                   <el-table border :data="pageData.overdueEnd">
                        <el-table-column prop="typeName" label="类型" align="center"></el-table-column>
                        <el-table-column prop="name" label="名称" align="center"></el-table-column>
                        <el-table-column prop="directorName" label="负责人" align="center"></el-table-column>
                        <el-table-column prop="planDate" label="计划结束" align="center"></el-table-column>
                        <el-table-column prop="overdueDays" label="超期天数" align="center"></el-table-column>

                    </el-table>
                </div>
            </div>
            <div class="block">
                <div class="title">超期未开始({{pageData.overdueBeginNum}})</div>
                <div class="blockContent contentTable">
                   <el-table border :data="pageData.overdueBegin">
                        <el-table-column prop="typeName" label="类型" align="center"></el-table-column>
                        <el-table-column prop="name" label="名称" align="center"></el-table-column>
                        <el-table-column prop="directorName" label="负责人" align="center"></el-table-column>
                        <el-table-column prop="planDate" label="计划结束" align="center"></el-table-column>
                        <el-table-column prop="overdueDays" label="超期天数" align="center"></el-table-column>
                    </el-table>
                </div>
            </div>
        </el-col>

        <el-col :span="24" v-if='activeItem==1'>
            <div class="block">
                <div class="title">项目关键节点路线图</div>
                <div class="blockContent contentTable">
                    <div class="block1" style="width:100%;margin-top:10px">
                        <span class="title">任务完成率</span>
                        <el-progress  :percentage="pageData.taskCompletedRate"></el-progress>
                    </div>
                    <div class="block2">
                        <TaskList :projectBtns="projectBtns" @success="querySpeedOfProgress"></TaskList>
                    </div>
                    <div>

                    </div>

                </div>
            </div>
        </el-col>

        <el-col :span="24" v-if='activeItem==2'>
            <div class="block">
                <div class="blockContent contentTable">
                    <div class="block2">
                        <MilestoneList :projectBtns="projectBtns" @success="querySpeedOfProgress"></MilestoneList>
                    </div>

                </div>
            </div>
        </el-col>

    </el-row>
</div>

</template>

<script>
import * as echarts from 'echarts'
import Pagination from '@/components/common/Pagination'
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import AddPerson from './AddPerson'
import TaskList from './TaskList'
import MilestoneList from './MilestoneList'
import { querySpeedOfProgress } from '../../../api/bussiness/index'
import { updateSingleInfo } from '../../../api/common'

export default {
  name: 'project_schedules',
  extends: BaseTable,
  components: { AddPerson, TableLayout, Pagination, TaskList, MilestoneList },
  props: {
    projectBtns:{
      type:Boolean,
      default:true
    }
  },
  data () {
    return {
      visible: false,
      pageData: null,
      textarea: '项目进展啦啦啦啦',
      textareaEdit: false,
      textareaLoading: false,
      activeItem: 0, // 默认项目级动态
      chart1: [],
      chart2: []
    }// return
  },
  computed: {

  },
  watch: {
    activeItem: {
      handler (val) {
        if (val == 0) {
          new Promise((resolve) => {
            resolve()
          }).then(() => {
            this.pieChart('chart1', this.chart1)
            this.pieChart('chart2', this.chart2)
          })
        }
      },
      immediate: true
    }
  },
  mounted () {
    // let contentWidth = window.getComputedStyle(document.getElementsByClassName('chart')[0]).width
    // let contentHeight = window.getComputedStyle(document.getElementsByClassName('chart')[0]).height;//0
    // console.log(document.getElementsByClassName('chart')[0]);
    // console.log(contentWidth,contentHeight,'0-0-0-0-0-9-0-0',window.getComputedStyle(document.getElementsByClassName('chart')[0]));
    this.querySpeedOfProgress()

    console.log('图片都画完了')
  },
  methods: {
    updateSingleInfo () {
      this.textareaLoading = true
      updateSingleInfo({
        id: this.$route.query.pid,
        progressDescription: this.pageData.progressDescription
      }).then(res => {
        console.log(res, '---进度')
      }).finally(() => {
        this.textareaLoading = false
        this.textareaEdit = false
      })
    },
    querySpeedOfProgress () {
      querySpeedOfProgress({
        projectId: this.$route.query.pid
      }).then(res => {
        console.log(res, '----')
        this.pageData = res
        this.pageData.progressDescription = res.progressDescription ? res.progressDescription : ''
        const chart1 = []
        const chart2 = []
        if (res.taskPercentage.items) {
          const list = res.taskPercentage.items
          list.map(i => {
            chart1.push({
              value: i.number,
              name: i.name
            })
          })
        }
        if (res.milepostPercentage.items) {
          const list = res.milepostPercentage.items
          list.map(i => {
            chart2.push({
              value: i.number,
              name: i.name
            })
          })
        }

        if (res.progressBars && res.progressBars.length > 0) {
          res.progressBars.map(item => {
            // rgb(245, 108, 108)

            // item.operatorContent = item.operatorContent +'<br /> ahhahhvyhsgd'
            // if(item.doing){
            //     item.color = '#000'
            //     item.icon = 'el-icon-caret-right'
            // }else{
            // 0,1,2,3
            if (item.style == 0) { // 计划开始结束
              item.color = '#EE6666'
            } else if (item.style == 1) {
              item.color = '#5470C6'
              item.icon = item.doing ? 'el-icon-caret-right' : ''
            } else if (item.style == 2) {
              item.color = '#91CC75'
              item.icon = item.doing ? 'el-icon-caret-right' : ''
            } else if (item.style == 3) {
              item.color = '#FAC858'
              item.icon = item.doing ? 'el-icon-caret-right' : ''
            } else {
              item.color = '#ddd'
              item.icon = item.doing ? 'el-icon-caret-right' : ''
            }
            // }
          })
        }

        this.chart1 = chart1
        this.chart2 = chart2

        new Promise((resolve) => {
          resolve()
        }).then(() => {
          this.pieChart('chart1', this.chart1)
          this.pieChart('chart2', this.chart2)
        })
      }).catch(e => {
        this.$tip.apiFailed(e)
      })
    },
    pieChart (id, data) {
      var chartDom = document.getElementById(id)
      var myChart = echarts.init(chartDom)
      var option

      option = {
        title: {
          text: id == 'chart1' ? '任务状态统计' : '里程碑状态统计',
          left: 'center',
          top: '10px',
          textStyle: {
            color: '#333333',
            fontWeight: 500,
            fontSize: '16px',
            'font-family': ' Source Han Sans CN',
            'font-weight': 500

          }
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: 'bottom'
        },
        toolbox: {
          show: true,
          feature: {
            mark: { show: true }
            // dataView: {show: true, readOnly: false},
            // restore: {show: true},
            // saveAsImage: {show: true}
          }
        },
        series: [
          {
            name: '状态',
            type: 'pie',
            radius: ['0%', '60%'],
            center: ['50%', '50%'],
            roseType: 'area',
            itemStyle: {
              borderRadius: 1
            },
            data: data
          }
        ]
      }

      option && myChart.setOption(option)
    },
    getBar (id) {
      var chartDom = document.getElementById(id)
      var myChart = echarts.init(chartDom)
      var option
      option = this.option
      option && myChart.setOption(option)
    },
    // 点击确认
    confirm () {
      this.visible = false
      this.$emit('confirm')
    },
    // 点击取消
    cancel () {
      this.visible = false
      this.$emit('cancel')
    },
    // 复制成功
    copySuccess () {
      this.$tip.success('复制成功')
    },
    // 复制失败
    copyFailed () {
      this.$tip.error('复制失败')
    }
  }
}
</script>

<style lang="scss">

</style>
<style scoped lang="scss">
.curPage{
  color: #333333;
  font-size: 18px;
  font-family: 'Microsoft YaHei';
  font-weight: bold;

}
.curPage{
    color: #333333;
    font-size: 18px;
    font-family: 'Microsoft YaHei';
    font-weight: bold;
}
.tabs{
    .tab_tab{
        display: flex;
        justify-content: space-around;
        .tabItem{
           font-weight: 400;
           cursor: pointer;
        }
        .activeItem{
            font-weight: bold;
            border-bottom: 2px solid #2264f1;
            color: #2264f1;
        }
    }
}
.content{
    padding: 20px;
}
.content1{
    .block{
       .title{

       }
       .blockContent{
           margin: 20px 0;
           font-weight: normal;
           font-size: 16px;
       }
    }
}
.block .contentChart{

    .block1{
        font-size: 14px;
        font-weight: 400;
        .title{
            margin-bottom: 11px;
        }
    }
    .block2{
        width: 100%;
        height: 150px;
        margin-top: 10px;
        overflow-x: scroll;
        &::-webkit-scrollbar {
            // width: 0px;  //横轴时间线滚动条
        }
        .timeLine{
           .content{
                font-size: 14px;
                font-weight: 400;
                padding: 0;
           }
        }
    }
    .block3{
        display: flex;
        height: 200px;

        .chart{
            width: 50%;
            flex: 1;
            flex-shrink: 0;
            height: 100%;
            // border: 1px solid #def;
             min-width:380px;
            min-height: 200px;
        }
    }
}
  .contentChart{
    /deep/ .el-timeline{
        display: flex;
        width: 100%;
        height: 100%;
    }
    /deep/.el-timeline-item{
        border-top: 2px dashed #eee;
        margin-top: 10px;
        flex-shrink: 0;
        flex: 1;
        min-width:200px;
        margin-right: 4px;

        &:first-child{
            border-top: none;
            position:relative;

        }
        &:first-child:after {
                content: "";
                position: absolute;
                right: -5px;
                top: 0px;
                width: 50%;
                height: 2px;
                border-top: 2px dashed #eee;

            }
            &:last-child{
            border-top: none;
            position:relative;
        }
        &:last-child:after {
                content: "";
                position: absolute;
                left: -10px;
                top: 0px;
                width: 50%;
                height: 2px;
                border-top: 2px dashed #eee;
            }
    }
    /deep/ .el-timeline-item__tail{
        border:none
    }
    /deep/ .el-timeline-item__timestamp{
        color: #333333 ;
        font-weight: 400;
        font-size: 14px;
        text-align: center;
        margin-top: 10px;
    }
    /deep/.el-timeline-item__wrapper{
        padding-left: 0;
        top: 0;
    }
    /deep/.el-timeline-item__node--large {
        top: -8px;
        left: calc(50% - 9px);
    }
    /deep/.el-timeline-item__content{
        text-align: center;
    }
  }
.block .contentTable{
    .block1{
        font-size: 14px;
        font-weight: 400;
        .title{
            margin-bottom: 11px;
        }
    }
    .block2{
        margin-left: -40px;
    }
}

</style>
