<template>
  <TableLayout :permissions="['system:user:query']" >

    <template v-slot:table-wrap>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        :default-sort = "{prop: 'createTime', order: 'descending'}"
        stripe
        border
        @sort-change="handleSortChange"
      >

        <el-table-column align="center" prop="systemName" label="系统" min-width="100px" fixed="left"></el-table-column>
        <el-table-column align="center" prop="modelName" label="操作板块" min-width="120px"></el-table-column>
        <el-table-column align="center" prop="content" label="更改内容" min-width="120px"></el-table-column>
        <el-table-column align="center" prop="operatorDate" label="操作时间" min-width="120px"></el-table-column>
        <el-table-column align="center" prop="operatorName" label="操作人" min-width="100px" ></el-table-column>

      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination>
    </template>

  </TableLayout>
</template>
<script>
import Pagination from '@/components/common/Pagination'
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import {queryLog} from '@/api/manage/managementLog.js'

export default {
  name: '',
  extends: BaseTable,
  components: {TableLayout, Pagination },
   props: {
  },
  data () {
    return {
        searchForm: {
            account: '', // 名字
            realname: '', // 姓名
            rootDeptId: null, // 部门ID
            positionId: null, // 岗位ID
            mobile: '' // 手机号码
        },
        activeItem:0,
        pageWrap:{
          capacity: 0,
          model: {
            content: "",
            modelName: "",
            operatorDate: "",
            operatorId: 0,
            systemName: ""
          },
          page: 0,
          sorts: [
            {
              direction: "",
              property: ""
            }
          ]
        }
    }
  },
  computed: {

  },
  created(){
    // this.queryLog();
    this.config({
      module: '日志',
      api: '/manage/managementLog'
    })
    this.search()

  },
  methods: {
    queryLog(){
      queryLog(this.pageWrap).then(res=>{

      })
    }
  }
}
</script>
<style scoped lang="scss">
.tabs{
    .tab_tab{
        display: flex;
        justify-content: space-around;
        .tabItem{
           font-weight: 400; 
           cursor: pointer;
        }
        .activeItem{
            font-weight: bold;
        }
    }
}
.sumInfo{
  color: #333333;
  font-size: 18px;
  font-family: 'Microsoft YaHei';
  font-weight: bold;
  /deep/ .el-timeline-item__tail{
    border-left: 2px dashed #999999;
  }
  /deep/ .el-timeline-item__timestamp{
    color: #333333 ;
    font-weight: 400;
    font-size: 18px !important;
  }
}
</style>
