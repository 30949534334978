<template>
    <div :style="{'display':block?'block':'inline-block'}">
        <el-upload
            class="upload-demo"
            action=""
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :before-upload="beforeUpload"
            :http-request="customUpload" 
            multiple
            :limit="max"
            :on-exceed="handleExceed"
            :show-file-list="false"
            :file-list="fileList">
              <el-button size="small" type="primary" :loading="loading">点击上传</el-button>
              <span slot="tip" class="el-upload__tip">文件大小不超过5M</span>
        </el-upload>
    </div>
</template>
<script>
import {uploadImage} from '../../api/common'
export default {
   data(){
       return{
           fileList:[],
           loading:false
       }
   },
   props:{
     block:{
       default:false
     },
     max:{
       default:5
     },
     projectId:{
       default:null
     },
     value:{
       default:()=>[]
     },
     limitType:{
       default:null
     }
   },
   watch:{
     value:{
       handler(val,olv){
         console.log(val,olv,'----默认值');
         if(olv!=val){
           this.fileList = val?val:[]
         }
       },
       immediate:true,
     },
       fileList:{
           handler(val,olv){
             console.log(val,olv,'----上传');
               this.$emit('input',this.fileList)
           },
           deep:true,
           immediate:true,
       }
   },
   methods:{
    handleRemove(file, fileList) {
           console.log('remove');
          this.fileList = fileList
      },
      handlePreview(file) {
          console.log('preview');
        console.log(file);
      },
      handleExceed(files, fileList) {
        console.log(files,'--handleExceed-');
        this.$message.warning(`当前限制选择 ${this.max} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
      },
      beforeRemove(file, fileList) {
        // return 
      },
      beforeUpload(file) {
        console.log(file,'上传的文件',this.limitType);
        if(!this.limitType){//不限制
          return file;
        }else {
          if(this.limitType == file.type){ //符合条件
            return true
          }else{
            this.$message.error('应上传文件类型为'+this.limitType);
            return false
          }
        }
      },
      customUpload(option){
        if(this.projectId){
          this.projectUpload(option)
        }else{
          this.normalUpload(option)
        }
      },
      projectUpload(option){ //失败
        this.normalUpload(option).then(res=>{
          this.fileList = []
        })
      },
      async normalUpload(option){
        console.log('上传',this.fileList);
        this.loading = true
        let self = this
        let formData = new FormData();
        formData.append('file',option.file);
        let size = option.file.size
        await uploadImage(formData).then((res) => {
        console.log('上传后',this.fileList);
          this.fileList.push({
            name:option.file.name,
            path:res,
            size:size,
            delete:true
          })
          this.imageUrl = res
        }).catch(e=>{
          this.$message.error('上传文件失败');
        }).finally(()=>{
          this.loading = false
        })
      }
   } 
}
</script>
<style lang="scss" scoped>

</style>