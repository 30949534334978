<template>
<TableLayout  :permissions="['common:settings:query']">
<div class="curPage">
  <el-row class="tabs">
        <el-col :span="12">
            <div class="tab_tab">
                <span class="tabItem" :class="activeItem==0?'activeItem':''" @click="tabClick(0)">项目编号</span>
                <span class="tabItem" :class="activeItem==1?'activeItem':''" @click="tabClick(1)">项目级别</span>
                <span class="tabItem" :class="activeItem==2?'activeItem':''" @click="tabClick(2)">项目类型</span>
                <span class="tabItem" :class="activeItem==3?'activeItem':''" @click="tabClick(3)">支出类型</span>
                <span class="tabItem" :class="activeItem==4?'activeItem':''" @click="tabClick(4)">扣分设置</span>
                <span class="tabItem" :class="activeItem==5?'activeItem':''" @click="tabClick(5)">表单设置</span>
            </div>
        </el-col>
    </el-row>

    <el-row class="rowContent">
        <el-col :span="24">
            <div class="content" v-if="activeItem==0">
              <span class="title">是否启用项目编号</span>
              <el-switch v-model="setting.projectNo.switchs"></el-switch>
              <div style="margin:20px" v-if="setting.projectNo.switchs">
                <span class="title">项目编号生成方式</span>
                <el-radio-group v-model="setting.projectNo.auto" >
                  <el-radio :label="false">手动填写</el-radio>
                  <el-radio :label="true">自动生成</el-radio>
                </el-radio-group>
                <p v-if='!setting.projectNo.auto'>手动填写项目编号规则：第一位为英文字母大写，后面为数字，总共15位。例如：P96844623479681</p>
                <p v-else>自动生成项目编号规则：第一位为英文字母，后面为数字，总共15位。其中第一位英文字母为大写P，数字为：年份+月份+创建项目日期+创建时间（取到秒）。 例如，合同名称："掌上陕旅“一期建设，创建时间为：2021年6月10号16点15分37秒，则项目编号为：Z20210610161537</p>
              </div>
            </div>
        </el-col>
        <el-col :span="12">
            <div class="content"  v-if="activeItem==1">
              <!-- <span class="title bt20">项目级别开关</span>
              <el-switch v-model="setting.projectLevels.switchs"></el-switch> -->
              <el-button type="primary" @click="addSetting('level')"  v-permissions="['common:settings:save']">新增</el-button>
              <div class="">
                <el-table
                    :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                    row-key="id"
                    stripe border
                    :data="setting.projectLevels"
                  >
                    <!-- :data="tableData.list"
                    v-loading="isWorking.search" -->
                    <el-table-column prop="id" label="编号"  align="center" ></el-table-column>
                    <el-table-column prop="content" label="级别名称"  align="center"></el-table-column>
                    <el-table-column prop="reference" label="描述"  align="center"></el-table-column>

                    <el-table-column label="操作" align="center" v-permissions="['common:settings:save']">
                      <div slot-scope="{row,$index}">
                        <el-button type="text" @click="expenditureClick(row,$index,'projectLevels')" icon="el-icon-edit" v-permissions="['common:settings:save']" >编辑</el-button>
                        <el-button type="text" @click="deleteItem(row,$index,'projectLevels')" icon="el-icon-delete" v-permissions="['common:settings:save']" >删除</el-button>

                      </div>
                    </el-table-column>
                  </el-table>
              </div>
            </div>
        </el-col>
        <el-col :span="12">
            <div class="content"  v-if="activeItem==2">
              <p>注意：项目类型修改后同步至项目中心</p>
              <div class="">
                <el-table
                    :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                    row-key="id"
                    stripe border
                    :data="setting.projectTypes"
                  >
                    <!-- :data="tableData.list"
                    v-loading="isWorking.search" -->
                    <el-table-column prop="id" label="序号" align="center"></el-table-column>
                    <el-table-column prop="content" label="项目类型" align="center"></el-table-column>
                    <el-table-column label="操作" align="center" >
                      <div slot-scope="{row,$index}">
                        <el-button type="text" @click="expenditureClick(row,$index,'projectTypes')" icon="el-icon-edit" v-permissions="['common:settings:save']">编辑</el-button>
                      </div>
                    </el-table-column>
                  </el-table>
              </div>
            </div>
        </el-col>
        <el-col :span="12">
            <div class="content"  v-if="activeItem==3">
              <p>注意：支出类型增删改后同步至项目中心。支出类型如果删除，已经存在的支出将从支出类型移除，对应的支出金额也会从预计总支出金额中移除；支出类型名称如果修改，已经存在的项目支出类型处显示为修改后的支出类型；支出类型如果增加,已经存在的项目不受影响。</p>
              <div class="">
                <el-button type="primary" @click="addSetting('expenditureTypes')"  v-permissions="['common:settings:save']">新增</el-button>
                <el-table
                    :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                    row-key="id"
                    stripe border
                    :data='setting.expenditureTypes'
                  >
                    <el-table-column prop="id" label="序号" fixed="left" align="center"></el-table-column>
                    <el-table-column prop="content" label="支出类型" fixed="left" align="center"></el-table-column>
                    <el-table-column label="操作"  align="center" v-permissions="['common:settings:save']">
                      <div slot-scope="{row, $index}">
                        <el-button type="text" @click="expenditureClick(row,$index,'expenditureTypes')" icon="el-icon-edit" v-permissions="['common:settings:save']">编辑</el-button>
                        <el-button type="text" @click="deleteItem(row,$index,'expenditureTypes')" icon="el-icon-delete" v-permissions="['common:settings:save']">删除</el-button>
                      </div>
                    </el-table-column>
                  </el-table>
              </div>
              
            </div>
        </el-col>
        
        <el-col :span="24">
            <div class="content" v-if="activeItem==4">
              <span class="title bt20">扣分设置</span>
              <el-switch v-model="setting.deductPoints.switchs"></el-switch>
              <div>
                <div>
                  <span class="title">项目超期每天扣分设置</span>
                  <el-input v-model="setting.deductPoints.project"  placeholder="输入数字"></el-input>
                  <p>扣分规则：根据设置的项目超期每天扣分分数计算，例如：设置每天扣分5分，超期5天，则系统计算总计扣分为5*5=25。</p>
                </div>
                <div>
                  <span class="title">任务超期每天扣分设置</span>
                  <el-input v-model="setting.deductPoints.task"  placeholder="输入数字"></el-input>
                  <p>扣分规则：根据设置的任务超期每天扣分分数计算，例如：设置每天扣分5分，超期5天，则系统计算总计扣分为5*5=25。</p>
                </div>
                <div>
                  <span class="title">里程碑超期每天扣分设置</span>
                  <el-input  v-model="setting.deductPoints.milepost"   placeholder="输入数字"></el-input>
                  <p>扣分规则：根据设置的里程碑超期每天扣分分数计算，例如：设置每天扣分5分，超期5天，则系统计算总计扣分为5*5=25。</p>
                </div>

                <p>得分规则：每个项目总分100分，得分=100-项目超期扣分总计-任务超期扣分总计-里程碑超期扣分总计，如果计算出得分为负，统一算0分。</p>
              </div>
            </div>
        </el-col>
        <el-col :span="24">
            <div class="content" v-if="activeItem==5">
              <p>此处设置在新建项目时显示的内容</p>
              <div class="bt20">
                <span class="title">项目级别</span>
                <el-switch v-model="setting.tableShow.projectLevel"></el-switch>
              </div>
              <div class="bt20">
                <span class="title">项目参与人</span>
                <el-switch  v-model="setting.tableShow.projectStakeholder"></el-switch>
              </div>
              <div class="bt20">
                <span class="title">项目说明</span>
                <el-switch v-model="setting.tableShow.projectExplain"></el-switch>
              </div>
              <div class="bt20">
                <span class="title">预算总收入</span>
                <el-switch v-model="setting.tableShow.estimatedTotalRevenue"></el-switch>
              </div>
              <div class="bt20">
                <span class="title">预计总支出和各支出类型</span>
                <el-switch v-model="setting.tableShow.estTtExpendAndType"></el-switch>
              </div>
              <div class="bt20">
                <span class="title">附件</span>
                <el-switch v-model="setting.tableShow.enclosure"></el-switch>
              </div>
              
            </div>
        </el-col>
        <el-col :span="4" style="margin-top:100px;text-align:center" v-if="this.showBtn!=-1&&(activeItem!=1 && activeItem!=2 && activeItem!=3)">
          <el-button type="primary" @click="saveSettings" :loading="loading">保存</el-button>
        </el-col>
    </el-row>


    <el-dialog title="编辑" :visible="costTypeEditVisible" width="30%" @close="closeDialog(1,2)">
      <el-form>
        <el-form-item label="编号" v-if="costTypeEditValue.attr=='projectLevels'">
          {{costTypeEditValue.id}} 级
        </el-form-item>
        <el-form-item :label="costTypeEditValue.label">
          <el-input v-model.trim='costTypeEditValue.row' placeholder="不能为空" style="width:180px"></el-input>
        </el-form-item>
        <el-form-item label="描述" v-if="costTypeEditValue.reference">
          <el-input v-model.trim='costTypeEditValue.reference' placeholder="不能为空" style="width:180px"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog(1,2)">取 消</el-button>
        <el-button type="primary"   @click="saveEdit">确 定</el-button>
        <!-- v-if="costTypeEditValue&&costTypeEditValue.row.length>0" -->
      </span>
    </el-dialog>
    <el-dialog title="新增" :visible="createLevelVisible" width="30%" @close="closeDialog">
      <el-form>
        <el-form-item label="编号" >
          <el-input v-model.trim='levelValue.id' type="number" :min="1" placeholder="级别编号不能为空" style="width:180px"></el-input> 级
        </el-form-item>
        <el-form-item label="名称">
          <el-input v-model.trim='levelValue.content' placeholder="级别名称不能为空" style="width:180px"></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input v-model.trim='levelValue.reference' placeholder="级别描述不能为空" style="width:180px"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary"   @click="saveAdd('level')">确 定</el-button>
        <!-- v-if="costTypeEditValue&&costTypeEditValue.row.length>0" -->
      </span>
    </el-dialog>
    <el-dialog title="新增" :visible="createSpendVisible" width="30%" @close="closeDialog">
      <el-form label-width="120px">
        <el-form-item label="序号" required>
          <el-input v-model.trim='spendValue.id' type="number" :min="1" placeholder="支出类型序号不能为空" style="width:180px"></el-input>
        </el-form-item>
        <el-form-item label="支出类型" required>
          <el-input v-model.trim='spendValue.content' placeholder="支出类型不能为空" style="width:180px"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary"   @click="saveAdd('expenditureTypes')">确 定</el-button>
        <!-- v-if="costTypeEditValue&&costTypeEditValue.row.length>0" -->
      </span>
    </el-dialog>
</div>
</TableLayout>
</template>

<script>
import TableLayout from '@/layouts/TableLayout'

import { mapState, mapMutations } from 'vuex'
import {equalObject} from '@/utils/util.js'
import {querySettings,saveSettings} from '@/api/manage/rules'

export default {
  name: '',
  components: {  TableLayout },
  watch:{
    setting:{
      handler(val,olv){
        // const basic = JSON.parse(JSON.stringify(this.$store.state.setting))
        const basic = this.$store.state.setting
        if(olv!=null&&!equalObject(basic.projectNo, val.projectNo)){
          console.log('项目编号改变了');
          this.showBtn = 0;
        }else if(olv!=null&&!equalObject(basic.projectLevels, val.projectLevels)){
          console.log('项目级别改变了');
          this.showBtn = 1;
        }else
        if(olv!=null&&!equalObject(basic.projectTypes, val.projectTypes)){
          console.log('项目类型改变了');
          this.showBtn = 2;
        }else
        // if(olv!=null&&!equalObject(basic.expenditureTypes, val.expenditureTypes)){
        //   console.log('支出类型改变了');
        //   this.showBtn = -1;
        // }else
        if(olv!=null&&!equalObject(basic.deductPoints, val.deductPoints)){
          console.log('扣分设置改变了');
          this.showBtn = 4;
        }else
        if(olv!=null&&!equalObject(basic.tableShow, val.tableShow)){
          console.log('表单设置改变了');
          this.showBtn = 5;
        }else{
          this.showBtn = -1;
        }
      },
      deep:true
    },
   
  },
  computed:{
    // ...mapState({
    //   setting: state=>state.setting // ?
    // })
  },
  data(){
    return{
      activeItem:0,//tabActive
      radio:false,
      costTypeEditVisible:false,
      costTypeEditValue:{},
      setting:null,
      basicSetting:null,
      showBtn:-1,
      loading:false,
      createLevelVisible:false,//新增项目级别
      levelValue:{
        id:null,
        content:null,//级别，名称
        reference:null,//参考内容
      },
      createSpendVisible:false,//新增支出类型
      spendValue:{}
    }
  },
  created(){
    this.setting = JSON.parse(JSON.stringify(this.$store.state.setting))
    setTimeout(()=>{},3000)
  },

  mounted(){
    this.showBtn= -1;
    console.log('接口调用');
    equalObject();

  },
  methods:{
    //新增业务设置项
    addSetting(type){
      if(type=='level'){
        this.createLevelVisible = true
        this.levelValue = {}
      }else if(type=='expenditureTypes'){
        this.createSpendVisible = true
        this.spendValue = {}
      }
    },
    async saveAdd(type){
      if(type=='level'){
        console.log(this.levelValue,'levelValue');
        let index = this.setting.projectLevels.findIndex(i=>i.id==this.levelValue.id)
        if(this.levelValue.id<=0){
          return this.$tip.error('级别编号不能小于等于0')
        }
        if(index!=-1){
          return this.$tip.error('级别编号不能重复')
        }
        if(this.levelValue.content==null || this.levelValue.content.length==0){
          return this.$tip.error('级别名称不能重复')
        }
        if(this.levelValue.content==null || this.levelValue.content.length==0){
          return this.$tip.error('级别描述不能重复')
        }
        let projectLevels = [
          ...this.setting.projectLevels,
          this.levelValue,
        ]
        await this.saveSettingsApi({projectLevels:projectLevels});
        this.closeDialog()
      }else if(type=='expenditureTypes'){
        let index = this.setting.expenditureTypes.findIndex(i=>i.id==this.spendValue.id)
        if(this.spendValue.id<=0){
          return this.$tip.error('序号不能小于等于0')
        }
        if(index!=-1){
          return this.$tip.error('序号不能重复')
        }
        let expenditureTypes = [
          ...this.setting.expenditureTypes,
          this.spendValue
        ]
        await this.saveSettingsApi({expenditureTypes:expenditureTypes});
        this.closeDialog()
      }
    },
    async deleteItem(row,index,attr){
      let label = attr=="projectLevels"?'名称':attr=="projectTypes"?'项目类型':attr=="expenditureTypes"?'支出类型':'异常label'
      console.log(index,'---sdsd');
      if(attr=="projectLevels"){
        this.$dialog.deleteConfirm('确定要删除当前级别吗').then(res=>{
          let projectLevels = [...this.setting.projectLevels];
          projectLevels.splice(index,1)
          this.saveSettingsApi({projectLevels:projectLevels})
          
          this.closeDialog()
        })
        
      }else if(attr=="expenditureTypes"){
        this.$dialog.deleteConfirm('确定要删除当前支出类型吗').then(res=>{
          let expenditureTypes = [...this.setting.expenditureTypes];
          expenditureTypes.splice(index,1)
          this.saveSettingsApi({expenditureTypes:expenditureTypes});
          this.closeDialog()
        })
        
      }
    },
    tabClick(type){
      this.activeItem = type;
      this.setting = JSON.parse(JSON.stringify(this.$store.state.setting)) 
      this.showBtn = -1;
    },
    expenditureClick(row,index,attr){
      console.log(row,index);
      console.log(attr,'--');
      let label = attr=="projectLevels"?'名称':attr=="projectTypes"?'项目类型':attr=="expenditureTypes"?'支出类型':'异常label'
      this.costTypeEditVisible = true;
      this.costTypeEditValue ={
        id:row.id,
        index:index,
        row:row.content,
        reference:row.reference?row.reference:null,
        attr:attr,
        label:label
      } 
    },
    closeDialog(){
      this.loading=false
      this.costTypeEditVisible=false 
      this.createLevelVisible = false
      this.createSpendVisible = false
    },
    saveEdit(){
      switch (this.activeItem) {
        case 1:
          //项目级别
          this.setting.projectLevels[this.costTypeEditValue.index].content = this.costTypeEditValue.row  
          this.setting.projectLevels[this.costTypeEditValue.index].reference = this.costTypeEditValue.reference  
          break;
        case 2:
          //项目类型
          this.setting.projectTypes[this.costTypeEditValue.index].content = this.costTypeEditValue.row
          
          break;
        case 3:
          //支出类型
          this.setting.expenditureTypes[this.costTypeEditValue.index].content = this.costTypeEditValue.row
          
          break;
      
        default:
          break;
      }

      this.saveSettings();
    },
    async saveSettings(){
      let saveData = {}
      let saveBtn = this.activeItem

      switch (saveBtn) {
        case -1:
          return this.$tip.error('按钮状态异常')
          break;
        case 0:
          saveData={projectNo:this.setting.projectNo}
          break;
        case 1:
          saveData={projectLevels:this.setting.projectLevels}
          break;
        case 2:
          saveData={projectTypes:this.setting.projectTypes}
          break;
        case 3:
          saveData={expenditureTypes:this.setting.expenditureTypes}
          break;
        case 4:
          saveData={deductPoints:this.setting.deductPoints}
          break;
        case 5:
          saveData={tableShow:this.setting.tableShow}
          break;
        default:
          break;
      }
      this.loading=true
      this.saveSettingsApi(saveData)
    },
    async saveSettingsApi(saveData){

      saveSettings(saveData)
       .then(res=>{
         console.log(2,'res',res);
        if(res==null){
           this.$store.dispatch('querySettings').then(res=>{
             this.setting = JSON.parse(JSON.stringify(this.$store.state.setting))
          })

          this.showBtn = -1
        }
      }).catch((err)=>{})
      .finally(()=>{
        this.closeDialog();
      })
    }

  }
}
</script>

<style scoped lang="scss">
.curPage{
  color: #333333;
  font-size: 16px;
  font-family: 'Microsoft YaHei';
  font-weight: 400;
  margin: 20px;
}
p{
  font-size: 12px;
  font-family: 'Microsoft YaHei';
  font-weight: 400;
  color: #FF5555;
}

.tabs{
    .tab_tab{
        display: flex;
        // justify-content: space-around;
        .tabItem{
           font-weight: 400; 
           cursor: pointer;
           margin-right: 20px;
        }
        .activeItem{
            font-weight: bold;
        }
    }
}
.rowContent{
  margin: 20px 0;
  .content{
    .title{
      display: inline-block;
      margin-right: 20px;
      min-width: 100px;
    }
    
  }
}
.bt20{
  margin-bottom: 20px;
}
/deep/ .el-input{
  width: 100px;
}
</style>
