<template>
  <TableLayout >
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline >
     
      <!-- <el-form-item label="资料名称" prop="keyword" >
        <el-input  v-model="searchForm.keyword" placeholder="请输入资料名称" v-trim maxlength="50" @keypress.enter.native="search"/>
      </el-form-item> -->
      <el-form-item label="关键字" prop="keyword" >
        <el-input  v-model="searchForm.keyword" placeholder="请输入资料名称/项目名称" v-trim maxlength="50"  @keypress.enter.native="search"/>
      </el-form-item>
      <el-form-item label="项目类型" prop="keyword" >
        <el-select v-model="searchForm.projectTypes" clearable placeholder="请选择" @change="()=>{this.searchForm.projectTypes=this.searchForm.projectTypes==''?null: this.searchForm.projectTypes ;this.search();}">
          <el-option
            v-for="item in projectTypes"
            :key="item.id"
            :label="item.content"
            :value="[item.id]">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属公司" prop="keyword" >
         <CompanySelect  class="fixWidth" v-model="searchForm.departmentId" placeholder="请选择用户关联子公司" :inline="true" clearable/>
      </el-form-item>
      <section>
        <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <template v-slot:table-wrap>
      
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        row-key="id"
        stripe
        border
        
      >
        <!-- <el-table-column type="selection" fixed="left" width="55"></el-table-column> -->
        <el-table-column prop="name" label="资料名称" fixed="left" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="projectName" label="项目名称" fixed="left" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="projectTypeName" label="项目类型" fixed="left" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="companyName" label="所属公司" fixed="left" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="belonging" label="所属" fixed="left" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="size" label="大小" fixed="left" min-width="100px" align="center">
          <div  slot-scope="{row}">
            <span v-if="row.size / 1024 / 1024 >= 1">{{(row.size / 1024 / 1024).toFixed(2)+'M'}}</span>
            <span v-else>{{(row.size / 1024).toFixed(2)+'KB'}}</span>
          </div>
        </el-table-column>
        <el-table-column prop="operatorName" label="上传人" fixed="left" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="operatorTime" label="上传时间" fixed="left" min-width="100px" align="center"></el-table-column>
        <el-table-column align="center"
          label="操作"
          width="100"
          fixed="right"
        >
          <div  slot-scope="{row}">
            <el-button type="text"><a :href="row.path" download="">下载</a></el-button>
          </div>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination>
    </template>
    <AddProject  ref='projectAddModal' @success="handlePageChange(tableData.pagination.pageIndex)"></AddProject>
    <!-- <UserChooseWindow></UserChooseWindow> -->
    <!-- <transfer-one></transfer-one> -->
  </TableLayout>
</template>

<script>
import TableLayout from '@/layouts/TableLayout'
import { mapState, mapMutations } from 'vuex'

import BaseTable from '@/components/base/BaseTable'
import OperaDepartmentWindow from '@/components/system/department/OperaDepartmentWindow'
import DepartmentUserWindow from '@/components/system/department/DepartmentUserWindow'
import AddProject from '../../components/bussiness/projectAddModal/AddProject'
import UserChooseWindow from '@/components/common/UserChooseWindow.vue'
import TransferOne from '../../components/transfer/transferOne.vue'
import Pagination from '@/components/common/Pagination'
import CompanySelect from '../../components/common/CompanySelect.vue'

export default {
  name: '',
  extends: BaseTable,
  components: { DepartmentUserWindow,CompanySelect, OperaDepartmentWindow, TableLayout,AddProject,UserChooseWindow,TransferOne,Pagination},
  computed:{
     ...mapState({
      projectTypes: state=>state.setting.projectTypes
    }),
  },
  data(){
   
     return{
      addProjectModal:false,
      active:0,
      checkList:[],
      dateArray:'',

      // 搜索
      searchForm: {
        keyword:null,//项目名称/项目编号/负责人（模糊搜索）
        projectTypes:null,//项目类型 前台缓存
        showType: 0,//展示类型 0-所有项目 1-我负责的 2-我参与的 3-按部门查看
        showDeleted:false,//默认值
      },

      projectAddModalVisible:false
      
      
      
    }
  },
  watch:{
    'searchForm.departmentId':{
      handler(){
        this.search()
      }
    },
    'searchForm.projectTypes':{
      handler(){
        this.searchForm.projectTypes = this.searchForm.projectTypes!=''?this.searchForm.projectTypes:null
      },
      immediate:true
    }
  },
  methods: {

    reset(){
      this.dateArray = ''
      this.searchForm= {
        keyword:null,//项目名称/项目编号/负责人（模糊搜索）
        overdueStatus: [],//项目是否超期
        planBegin: null,
        planEnd: null,
        status: [],//0-预启动 1-进行中 2-已暂停 3-已完成 4-已取消
        projectTypes: [],//项目类型

      }
      this.search()
    },
  },
  created () {
    this.config({
      module: '项目资料',
      api: '/bussiness/summary'
    })
    this.search()
  }
}
</script>

<style scoped lang="scss">
.table-layout {
  /deep/ .table-content {
    margin-top: 0;
  }
}
</style>
