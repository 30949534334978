import Vue from 'vue'
import Vuex from 'vuex'
import {querySettings} from '@/api/common'
import {querySettings as bigScreenSettingQuery} from '@/api/manage/rules'
Vue.use(Vuex)

const state = {
  //轮播样式设置
  projectionScreen:{},

  //扣分设置
  deductPoints:{},

  //项目支出类型设置
  expenditureTypes:{},

  //项目级别设置
  projectLevels:{},

  //项目编号设置
  projectNo:{},

  //项目类型设置
  projectTypes:{},

  //表单设置
  tableShow:{},

  //originW
  originW:null,//宽度
  originH:null,//高度
  rollingSpeed:2,//大屏项目滚动速度

}

const mutations = {
  originSize(state,value){
    state.originW = value.originW
    state.originH = value.originH
  },
  rollingSpeed(state,value){
    state.rollingSpeed = value
  },
  setSetting(state,value){
    if(value.projectionScreen){//轮播样式设置
      state.projectionScreen = value.projectionScreen
    }
    if(value.deductPoints){//扣分设置
      state.deductPoints = value.deductPoints
    }
    if(value.expenditureTypes){//项目支出类型设置
      state.expenditureTypes = value.expenditureTypes
    }
    if(value.projectLevels){//项目级别设置
      state.projectLevels = value.projectLevels
    }
    if(value.projectNo){//项目编号设置
      state.projectNo = value.projectNo
    }
    if(value.projectionScreen){//项目类型设置
      state.projectTypes = value.projectTypes
    }
    if(value.tableShow){//表单设置
      state.tableShow = value.tableShow
    }
  }
}
const actions = {
  querySettings(context){
    return new Promise((resolve,rej)=>{
      querySettings().then(res=>{
        context.commit('setSetting',res);
        resolve(res)
      }).catch(err=>{
        rej(err)
      })
    })
    
  },
  bigScreenSettingQuery(context){
    bigScreenSettingQuery().then(res=>{
      context.commit('rollingSpeed',res.sss)
    })
  }
  
}
const getters = {}
export default ({
  state,
  mutations,
  actions,
  getters
})
