import request from '@/utils/request'

// 获取图片验证码
export function getCaptcha () {
  return request.get('/common/captcha')
}

//日志管理
export function fetchList (data) {
  return request.post('/settingLog/page',data)
}