<template>

  <!-- <div class="page" :style="{width:originW+'px'}" ref="ScaleBox">  -->
    <div class="page" style="widtg:1920px;height:866px"  ref="ScaleBox">
        <div  >

        
    <!-- <el-button type="primary" class="" @click="$router.push('/showBoard')">投屏---</el-button> -->
    <div class="fullScreen" @click="fullScreen">全屏</div>
    <el-row class="row1" :gutter="20" style="background:#def;height:100vh">
      <el-col  :span="14"  style="background:#efe;padding:10px;height:33vh" >
         
          <div class="sumChart" style="height:100%">
              <el-row style="height:33%;background:#dee">
                  <div class="sum" style="padding:20px">
                    <div class="colTitle">总项目数总览</div>
                    <div class="sumItem"><span class="sum_title">项目总数</span>12</div>
                    <div class="sumItem"><span class="sum_title">已完成数</span>2</div>
                  </div>
                </el-row>
                <el-row  style="height:66%;">
                    <div class="sum_tabs"  style="height:100%;background:#fff">
                        <el-col :span="12" style="width:50%;background:#edf;padding:10px;"   v-for='(item,key) in dataSum' :key='key' >
                            <div class="sum_tabItem">
                                <div class="item" @click="$router.push('/manage/projectBoard')">
                                    <span class="title">{{item.type}}</span>
                                    <span class="number">{{item.number}}</span>
                                </div>
                                <div class="item" @click="$router.push('/manage/projectBoard')">
                                    <span class="title">已完成数</span>
                                    <span class="number">{{item.complete}}</span>
                                </div>
                            </div> 
                        </el-col>
                    </div>
                </el-row>
          </div>
      </el-col>
      <el-col :span="10" style="height:33vh">
        <el-row  style="background:#cdc;padding:10px;height:100%">
          <div class="pieChart" style="">
            <div class="colTitle">项目分布总览</div>
            <div class="pies" id="pieArea">
              <div id='pie1' class="pieBlock"></div>
              <div id='pie2' class="pieBlock" ></div>
              <div id='pie3' class="pieBlock" ></div>
              <div id='pie4' class="pieBlock" ></div>
            </div>
          </div>
          
        </el-row>
        
      </el-col>
    </el-row>

    <el-row  class="row2"  style="background:#ccc">
      <el-col  :span='14'  style="background:#eff">
          3
        <!-- <div class="projectList">
        <div class="colTitle">项目列表</div>
        <vue-seamless-scroll :data="block4" :class-option="optionCustomerInner" style="overflow:hidden;padding-top
        10px">
        <el-row class="listItem" v-for="item in block4" >
          <div class="left">
            <div class="left_left">
              <div class="top">
                <span class="name" @click="$router.push('/detail?pid=2')">陕旅系列360极限</span>
                <span class="id">(p21232342)</span>
              </div>
              <div class="middle">
                <span>一级</span>
                <span>开发项</span>
                <span>未超期</span>
              </div>
              <div class="bottom">计划工期:2021-04-09~2021-09-23</div>
            </div>
            <div class="left_right">
              <div>最后更新：2021-07-01</div>
              <div>计划工期：2021-07-01</div>
            </div>
            
          </div>
          
          <div class="right">
            <div class="progressItem">
              <span>进度</span>
              <el-progress color="#EC7A6D" :percentage="25"></el-progress>
            </div>
            <div class="progressItem">
              <span>进度</span>
              <el-progress  color="#EC7A6D"  :percentage="75"></el-progress>
            </div>
            <div class="progressItem">
              <span>进度</span>
              <el-progress  color="#EC7A6D" :percentage="50"></el-progress>
            </div>
          </div>
          <div class="end">
            <img class="avatar" src="../assets/images/wxpay.jpeg" alt="">
            <span class="name">杨涛</span>
          </div>
        </el-row>
        </vue-seamless-scroll>
        </div> -->
        
      </el-col>
      <el-col :span='10'  style="background:#fff">
          4
          <!-- <div>
            <el-row class="barContent">
                <div class="colTitle">项目状态总览</div>
                <div id="statusBar" class="barBlock"></div>
                </el-row>
                <el-row class="barContent">
                <div class="colTitle">项目问题总览</div>
                <div id="questionBar" class="barBlock"></div>
            </el-row>
          </div> -->
        
      </el-col>
    </el-row>
</div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import screenfull from "screenfull";
import { mapState } from 'vuex';
import {showScreen } from '../api/manage/index'

export default {
  name: '',
  data(){
    return{
      setOrigin:true,
      dataSum:[
        {id:1,type:'其他项目',number:120,complete:12},
        {id:2,type:'数字化转型项目',number:20,complete:12},
        {id:3,type:'数字化基建项目',number:20,complete:12},
        {id:4,type:'数字化运营项目',number:80,complete:12},
      ],
      optionCustomer:{
        step: 0.5,
        limitMoveNum: 4,
        openTouch: false,
        waitTime: 1,
        direction: 2,
        // navigation:true
        // singleWidth: 30
      },
      optionCustomerInner:{
        step: 0.5,
        limitMoveNum: 4,
        openTouch: false,
        waitTime: 1,
        // direction: 2,
      },
      block4:[
        {avator:'',username:'1秦琦', projects:[
            {title:'1项目库名称真难起',status:1},
            {title:'2项目库名称真难起',status:1},
            {title:'3项目库名称真难起',status:1},
            {title:'4项目库名称真难起',status:1},
            {title:'5项目库名称真难起',status:1},
            {title:'6项目库名称真难起',status:1},
            {title:'7项目库名称真难起',status:1},
          ]
        },
        {avator:'',username:'2张三', projects:[
            {title:'1项目库名称真难起',status:1},
            {title:'2项目库名称真难起',status:1},
            {title:'3项目库名称真难起',status:1},
            {title:'4项目库名称真难起',status:1},
            {title:'5项目库名称真难起',status:1},
            {title:'6项目库名称真难起',status:1},
            {title:'7项目库名称真难起',status:1},
          ]
        },
        {avator:'',username:'3李四', projects:[
            {title:'1项目库名称真难起',status:1},
            {title:'2项目库名称真难起',status:1},
            {title:'3项目库名称真难起',status:1},
            {title:'4项目库名称真难起',status:1},
            {title:'5项目库名称真难起',status:1},
            {title:'6项目库名称真难起',status:1},
            {title:'7项目库名称真难起',status:1},
          ]
        },
        {avator:'',username:'4王五', projects:[
            {title:'1项目库名称真难起',status:1},
            {title:'2项目库名称真难起',status:1},
            {title:'3项目库名称真难起',status:1},
            {title:'4项目库名称真难起',status:1},
            {title:'5项目库名称真难起',status:1},
            {title:'6项目库名称真难起',status:1},
            {title:'7项目库名称真难起',status:1},
          ]
        },
        {avator:'',username:'5周谷爱', projects:[
            {title:'1项目库名称真难起',status:1},
            {title:'2项目库名称真难起',status:1},
            // {title:'3项目库名称真难起',status:1},
            // {title:'4项目库名称真难起',status:1},
            // {title:'5项目库名称真难起',status:1},
            // {title:'6项目库名称真难起',status:1},
            // {title:'7项目库名称真难起',status:1},
          ]
        },
        {avator:'',username:'6秋秋', projects:[
            {title:'1项目库名称真难起',status:1},
            {title:'2项目库名称真难起',status:1},
            {title:'3项目库名称真难起',status:1},
            {title:'4项目库名称真难起',status:1},
            // {title:'5项目库名称真难起',status:1},
            // {title:'6项目库名称真难起',status:1},
            // {title:'7项目库名称真难起',status:1},
          ]
        },
        {avator:'',username:'7巴贝', projects:[
            {title:'1项目库名称真难起',status:1},
            {title:'2项目库名称真难起',status:1},
            {title:'3项目库名称真难起',status:1},
            // {title:'4项目库名称真难起',status:1},
            // {title:'5项目库名称真难起',status:1},
            // {title:'6项目库名称真难起',status:1},
            // {title:'7项目库名称真难起',status:1},
          ]
        },

      ],
      animate:false,
      scale: null,
      sumData:{},//

    }
  },
  computed:{
    ...mapState({
      originW:state=>state.setting.originW,
      originH:state=>state.setting.originH,
    }),
    xxx(){
      if(this.originW&&this.setOrigin){
        this.setScale()
        this.setOrigin = false
      }
      return
    }
  },
  mounted(){
    if (this.loading) return
    this.setScale()
    if (!document.addEventListener) return
    window.addEventListener('resize', this.debounce(this.setScale, 500, true))
    document.addEventListener('DOMContentLoaded', this.debounce(this.setScale, 500, true), false)
    setTimeout(()=>{
        this.setScale()
    },1000)
console.log('roterjsss-----');
this.showScreen();

    this.pieCharts()

    this.barChart('statusBar')
    this.barChart('questionBar')


    // this.loop(this.block4);

    
  },
  methods:{
    showScreen(){
      showScreen().then(res=>{
        console.log(res,'结果数');
        this.sumData = res
      })
    },
    fullScreen(){
      // 判断是否支持
      // if (!screenfull.enabled) {
      //   this.$message({
      //     message: "不支持全屏",  
      //     type: "warning"
      //   });
      //   return false;
      // }
      screenfull.toggle();
    },
    /**
     * @description 获取窗口缩放比例
     */
    setScale() {
      const docEl = document.documentElement
      console.log('------',document.getElementsByClassName("page")[0].clientWidth)
      let page = document.getElementsByClassName("page")[0]
      // console.log('----asa--',document.getElementsByClassName('page').style);
//  const heiht = docEl.clientHeight
      const width = page.clientWidth 
      const heiht = page.clientHeight
      console.log(width,heiht);
      if (!width || !heiht) return
      
      let ww = width / 1920
      let wh = heiht / 866
      console.log('width,heiht,',ww,wh,ww/wh);

      if(this.originW){
        console.log('改变数据');
        ww = this.originW / 1920
        wh = this.originH / 866
      }
      console.log('widtasah,heiht,',ww/wh,(ww/wh)/2.215,);
      let perc =  2.215-(ww/wh);
      console.log(perc,'----');
      let per = 0
      per = perc<0?('-'+(perc*10).toFixed(6)+'%'):perc==0?'0%':(perc).toFixed(0)+'%'
      console.log(per,'aksiajskajsoa');
      // if(perc)



      let scale = ww < wh ? ww : wh
      // let scale = ww

      this.scale = scale.toFixed(6)
      console.log(this.scale);
      // this.$refs.ScaleBox.style.setProperty('--scale', this.scale)
      this.$nextTick(() => {
        this.$refs.ScaleBox.style.transform = `scale(${this.scale}) translate(0%, 0%)`
      })
    },
    /**
     * @description 节流
     */
    debounce(fn, delay) {
      let delays = delay || 500
      let timer
      return () => {
        let _this = this
        let args = arguments
        if (timer) {
          clearTimeout(timer)
        }
        timer = setTimeout(() => {
          timer = null
          fn.apply(_this, args)
        }, delays)
      }
    },


    loop(data){
      if(data.length<=4){
        return
      }
      this.animate=true;    // 因为在消息向上滚动的时候需要添加css3过渡动画，所以这里需要设置true
      //  setTimeout(()=>{      //  这里直接使用了es6的箭头函数，省去了处理this指向偏移问题，代码也比之前简化了很多
      //          this.data.push(this.data[0]);  // 将数组的第一个元素添加到数组的
      //          this.data.shift();             //删除数组的第一个元素


      //          this.animate=false;  // margin-top 为0 的时候取消过渡动画，实现无缝滚动
      //  },500)

    },
    pieCharts(){
    this.pieChart('pie1')
    this.pieChart('pie2')
    this.pieChart('pie3')
     this.pieChart('pie4')
    },
    barChart(id){
      var div = document.createElement('div');
      div.id = id;
      div.className='barBlock'
      document.getElementById(id).appendChild(div);
      var chartDom = document.getElementById(id);
      var myChart = echarts.init(chartDom);
      var option;
      option = {
          legend: {
            textStyle:{
              fontSize:10,
              color:'#333333'
            }
          },
          tooltip: {},
          dataset: {
              source: [
                  ['状态', '已暂停', '进行中', '未启动','已完成','已取消'],
                  ['数字化转型项目', 43.3, 85.8, 93.7,34,55],
                  ['基建', 83.1, 73.4, 55.1,78,2],
                  ['运营', 86.4, 65.2, 82.5,23,0],
                  ['其它', 72.4, 53.9, 39.1,99,-10],
              ]
          },
          xAxis: {type: 'category'},
          yAxis: {
            splitLine: {
                show: false
              }
          },
          series: [
            // barMinHeight:2,
              {type: 'bar',barMinHeight:1,itemStyle:{color:'#EE6666'}},
              {type: 'bar',barMinHeight:1,itemStyle:{color:'#5470C6'}},
              {type: 'bar',barMinHeight:1,itemStyle:{color:'#73C0DE'}},
              {type: 'bar',barMinHeight:1,itemStyle:{color:'#FAC858'}},
              {type: 'bar',barMinHeight:1,itemStyle:{color:'#999999'}}
          ]
      };

      option && myChart.setOption(option);
    },
    lineChart(id){
      var div = document.createElement('div');
      div.id = id;
      div.style.width='770px';
      div.style.height='230px';
      // div.style.background='#def'
      div.className='block'
      document.getElementById('shape').appendChild(div);
      var chartDom = document.getElementById(id);
      var myChart = echarts.init(chartDom);
      var option;
      option = {
          xAxis: {
              type: 'category',
              data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
              splitLine: {
                show: false
              }
          },
          yAxis: {
              type: 'value',
              show:true,
              splitLine: {
                  show: false
              }
          },
          series: [{
              data: [820, 932, 901, 934, 1290, 1330, 1320],
              type: 'line',
              smooth: true,
              symbol:'circle',
              symbolSize: 8,
              itemStyle:{
                normal:{
                  color:'#FFEBD5',
                  lineStyle:{
                    color:'#FFEBD5'
                  },
                }
              },
              areaStyle: {
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 0,
                    colorStops: [{
                        offset: 0, color: '#36393C' // 0% 处的颜色
                    }, {
                        offset: 1, color: '#535760' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                }
              },
              markPoint:{
                itemStyle:{
                  color:'red'
                }
              }
          }]
      };

      option && myChart.setOption(option);

    },
    pieChart(id){
      var div = document.createElement('div');
      div.id = id;
      // div.style.width='100px';
      // div.style.height='100px';
      // div.style.background='#def'
      div.className='pieBlock'
      document.getElementById('pieArea').appendChild(div);
      var chartDom = document.getElementById(id);
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        title: {
            text: '数字化转型项目',
            left: 'center',
            top:'10px',
            textStyle:{
              color:'#333333',
              fontWeight:500,
              fontSize: '16px',
              'font-family':' Source Han Sans CN',
              'font-weight': 500,

            }
        },
          tooltip: {
              trigger: 'item'
          },
          legend: {
              orient: 'horizontal',
              left: 'center',
              bottom:'10px',
              itemWidth:10,
              itemHeight:10,
              itemStyle:{

              },
              textStyle:{
                fontSize:10,
                color:'#333333'
              }
          },
          
          series: [
              {
                  name: '访问来源',
                  type: 'pie',
                  // radius: '50%',
                  radius:['25%', '50%'],
                  center: ['50%', '45%'],
                  // avoidLabelOverlap: false,
                  itemStyle: {
                      // borderRadius: 5,
                      // borderColor: '#fff',
                      // borderWidth: 1
                  },
                  label: {
                      show: false
                  },
                  data: [
                      {value: 1048, name: '公司1:12'},
                      {value: 735, name: '公司2'},
                      {value: 580, name: '公司3:0'},
                      {value: 484, name: '公司4'},
                      {value: 300, name: '其它'}
                  ],
                  emphasis: {
                      itemStyle: {
                          shadowBlur: 10,
                          shadowOffsetX: 0,
                          shadowColor: 'rgba(0, 0, 0, 0.25)'
                      }
                  }
              }
          ]
      };

      option && myChart.setOption(option);
    },

  }
}
</script>

<style scoped lang="scss">
.anim{
    transition: all 0.5s;
    margin-left: -30px;
}
.fullScreen{
  position: absolute;
  top: 50%;
  right: 20px;
  background: #def;
  border-radius: 50%;
  padding: 40px;
  z-index: 22222;
  cursor: pointer;
  opacity: 0;
  &:hover{
    opacity:1;
  }
}
.page{
  // width:1570px;
  // height:730px;
  height: auto;
  margin: 0 auto;
  // margin: 10px auto;
  // background: linear-gradient(190deg, #404550 0%, #1B1D20 100%);
  // background: rgb(92, 108, 224);
  position: relative;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background: chocolate;
  // overflow: scroll;
  
}
.row1{
  height: 281px;
}
.row2{
  height: 498px;
  overflow: hidden;
}
  .colTitle{
    // height: 20px;
    color: white;
    background: #007AFF;
    border-radius: 1.5rem;
    display: inline-block;
    padding: 10px 20px;
    font-size: 20px;
    font-family: 'PingFang SC';
    font-weight: bold;
     position: relative;
     padding-left: 40px;
    &::before{
     position: absolute;
      top: 15px;
      left: 14px;
      display: inline-block;
      content: '';
      width: 20px;
      height: 4px;
      background: #fff;
      // padding-right: 6px;
      border-radius: 1.5em;
    }
    &::after{
     position: absolute;
      top: 23px;
      left: 14px;
      display: inline-block;
      content: '';
      width: 14px;
      height: 4px;
      background: #fff;
      // padding-right: 6px;
      border-radius: 1.5em;
    }
  }
.sumChart{
  height: 281px;
  font-size: 20px;
}
.sum{
  background: #FFFFFF;
  box-shadow: 0px 0px 8px 0px rgba(33, 33, 33, 0.1);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  // overflow: hidden;
  .sumItem{
    flex:1;
    flex-shrink: 0;
    text-align: center;
  }

  .sum_title{

  }
  // padding: 5px 20px;
}
.sum_tabs{
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: space-between;
  // align-items: center;
  // width: 1089px;
  // height: 200px;

  .sum_tabItem{
    height:25%;
    width: 100%;
    flex-shrink: 0;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px 0px rgba(33, 33, 33, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 6px 0;
    .item{
      flex: 1;
      display: flex;
      flex-direction: column;
      text-align: center;
      .title{
        font-size: 15px;
        font-family: 'PingFang SC';
        font-weight: 500;
        color: #333333;
      }
      .number{
        font-size: 29px;
        font-family: 'PingFang SC';
        font-weight: 500;
        color: #333333;
      }
    }
  }
}
.pieChart{
  // height: 281px;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px 0px rgba(33, 33, 33, 0.1);
  // padding: 14px 20px;
  border-radius: 17px;
  padding: 10px ;
  // margin-left: -20px;
  

  .pies{
    height: 200px;
    display: flex;
    .pieBlock{
      width:25%;
      height: 100%;
      // border: 1px solid #def;
    }
  }
}


.projectList{
  background: #FFFFFF;
  box-shadow: 0px 0px 8px 0px rgba(33, 33, 33, 0.1);
  border-radius: 17px;
  padding: 14px 17px;
  width: 1080px;
  height: 468px;
  margin-left: 5px;
  margin-top: 10px;
  overflow: hidden;
  
  .listItem{
    height: 79px;
    margin: 10px 0;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px 0px rgba(33, 33, 33, 0.1);
    border-radius: 7px;
    display: flex;
    padding: 11px 14px;

    
    .right{

    }
    .end{
      display: flex;
      align-items: center;
      margin-left: 18px;
      .avatar{
        width: 44px;
        height: 44px;
        border-radius: 50%;
        margin-right: 15px;
      }
      .name{
        font-size: 20px;
        font-family:'PingFang SC';
        font-weight: bold;
        color: #333333;
      }
    }
  }
}

.projectList .left{
  display: flex;
  align-items: center;
  font-size: 10px;
  .left_left{

    .top{
      .name{
        font-size: 15px;
        font-family: 'PingFang SC';
        font-weight: bold;
        color: #333333;
      }
      .id{
        font-size: 10px;
        font-family: 'PingFang SC';
        font-weight: 500;
        color: #999999;
        margin-left: 10px;
      }
    }
    .middle{
      display: flex;
      // font-size: 10px;
      font-family:'PingFang SC';
      font-weight: 500;
      color: #666666;
      margin: 10px 0;
      span{
        border-right:1px solid #ccc ;
        padding:0 10px;
        line-height: 10px;
      }
    }
    .bottom{
      // font-size: 6px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #666666;
    }
  }
  .left_right{
    // font-size: 6px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #666666;
    margin:0 17px;
    padding: 0 17px;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    line-height: 1.2rem;
  }
}
.projectList  .right{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 6px;
  font-family: 'PingFang SC';
  font-weight: 500;
  color: #666666;
  border-right: 1px solid #ccc;
  margin-left: 18px;
  
  .progressItem{
    flex:1;
    width:450px;
    display: flex;
    align-items: center;
    font-size: 6px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #666666;
    margin-right: 18px;
    
    span{
      // float:lefts
      width:60px;
    }
    // display: flex;
    .el-progress,.el-progress--line{
      flex-shrink: 0;
      flex: 1;

    }
    // flex-direction: column;
  }
}

.barContent{
  background: #FFFFFF;
  box-shadow: 0px 0px 8px 0px rgba(33, 33, 33, 0.1);
  border-radius: 17px;
  padding:14px 30px ;
  height: 228px;
  margin-top: 10px;
  margin-left: 15px;
  overflow: hidden;
  margin-right: -40px;
  .barBlock{
    width: 100%;
    height: 200px;
    // background: #def;
    margin-top: 10px;
  }
}


</style>
