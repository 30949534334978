<template>
<div class="curPage">
    <!-- <el-row class="tabs">
        <el-col :span="12">
            <div class="tab_tab">
                <span class="tabItem" :class="activeItem==0?'activeItem':''" @click="activeItem=0">全部<span></span> </span>
                <span class="tabItem" :class="activeItem==1?'activeItem':''" @click="activeItem=1">项目信息变更<span></span> </span>
                <span class="tabItem" :class="activeItem==2?'activeItem':''" @click="activeItem=2">负责人变更<span></span> </span>
            </div>
        </el-col>
    </el-row> -->
    <el-row class="content">
        <!-- v-if='activeItem==0' -->
        <el-col :span="24" class="content1" >
            <el-table border :data="tableData.list" :default-sort = "{prop: 'createTime', order: 'descending'}"
         @row-click="(row)=>{$refs.recordDetail.open('变更详情',row)}"
        stripe
        border>
                <el-table-column prop="typeName" label="变更类型" align="center"></el-table-column>
                <el-table-column prop="operatorDate" label="操作日期" align="center"></el-table-column>
                <el-table-column prop="operatorName" label="操作人" align="center"></el-table-column>

            </el-table>
            <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination>
        </el-col>
        <!-- <el-col :span="24" class="content1" v-if='activeItem==1'>
            <el-table border>
                <el-table-column prop="date1" label="变更类型1" align="center"></el-table-column>
                <el-table-column prop="date2" label="操作日期" align="center"></el-table-column>
                <el-table-column prop="date3" label="操作人" align="center"></el-table-column>

            </el-table> 
        </el-col>
        <el-col :span="24" class="content1" v-if='activeItem==2'>
            <el-table border>
                <el-table-column prop="date1" label="变更类型2" align="center"></el-table-column>
                <el-table-column prop="date2" label="操作日期" align="center"></el-table-column>
                <el-table-column prop="date3" label="操作人" align="center"></el-table-column>

            </el-table> 
        </el-col> -->


    </el-row>
    <RecordDetail ref="recordDetail" @success="search"></RecordDetail>
</div>


</template>

<script>
import * as echarts from 'echarts';
import Pagination from '@/components/common/Pagination'
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import RecordDetail from '../projectDetail/RecordDetail';


export default {
  name: 'project_sum_info',
  extends: BaseTable,
  components: { TableLayout, Pagination,RecordDetail, },
  props: {
  },
  data () {
    return {
      visible: false,
    //   searchForm:{
    //       projectId:Number(this.$route.query.pid),
    //       type:0,
    //   },
      searchForm:Number(this.$route.query.pid),
      activeItem:0,//默认项目级动态

    }//return
  },
  computed: {

  },
  mounted(){
    

  },
  methods: {
    
    // 点击确认
    confirm () {
      this.visible = false
      this.$emit('confirm')
    },
    // 点击取消
    cancel () {
      this.visible = false
      this.$emit('cancel')
    },
    // 复制成功
    copySuccess () {
      this.$tip.success('复制成功')
    },
    // 复制失败
    copyFailed () {
      this.$tip.error('复制失败')
    }
  },
  created(){
      this.config({
      module: '任务',
      api: '/bussiness/record',

    })
    this.search()
  }
}
</script>

<style lang="scss">

</style>
<style scoped lang="scss">
.curPage{
  color: #333333;
  font-size: 18px;
  font-family: 'Microsoft YaHei';
  font-weight: bold;
  /deep/ .el-timeline{
      display: flex;
      width: 100%;
      height: 100%;
  }
  /deep/.el-timeline-item{
      border-top: 2px dashed #999999;
      margin-top: 10px;
      flex-shrink: 0;
      flex: 1;
      min-width:200px;
      margin-right: 4px;
      &:first-child{
          border-top: none;
          position:relative;
      }
      &:first-child:after {
            content: "";
            position: absolute;
            right: -2px;
            top: 0px;
            width: 50%;
            height: 2px;
            border-top: 2px dashed #999;
        }
        &:last-child{
          border-top: none;
          position:relative;
      }
      &:last-child:after {
            content: "";
            position: absolute;
            left: -2px;
            top: 0px;
            width: 50%;
            height: 2px;
            border-top: 2px dashed #999;
        }
  }
  /deep/ .el-timeline-item__tail{
    border:none
  }
  /deep/ .el-timeline-item__timestamp{
    color: #333333 ;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
  }
  /deep/.el-timeline-item__wrapper{
      padding-left: 0;
      top: 0;
  }
  /deep/.el-timeline-item__node--large {
      top: -8px;
      left: calc(50% - 9px);
  }
  /deep/.el-timeline-item__content{
      text-align: center;
  }
}
.curPage{
    color: #333333;
    font-size: 18px;
    font-family: 'Microsoft YaHei';
    font-weight: bold;
}
.tabs{
    .tab_tab{
        display: flex;
        justify-content: space-around;
        .tabItem{
           font-weight: 400; 
           cursor: pointer;
        }
        .activeItem{
            font-weight: bold;
        }
    }
}
.content{
    padding: 20px;
}
.content1{
    .block{
       .title{

       } 
       .blockContent{
           margin: 20px 0;
       }
    }
}
.block .contentChart{
    
    .block1{
        font-size: 14px;
        font-weight: 400;
        .title{
            margin-bottom: 11px;
        }
    }
    .block2{
        width: 100%;
        height: 150px;
        margin-top: 10px;
        overflow-x: scroll;
        &::-webkit-scrollbar {
            width: 0px;
        }
        .timeLine{
           .content{
                font-size: 14px;
                font-weight: 400;
                padding: 0;
           } 
        }
    }
    .block3{
        display: flex;
        height: 200px;

        .chart{
            width: 50%;
            min-width:50%;
            min-height: 100%;
            flex: 1;
            height: 100%;
            border: 1px solid #def;
        }
    }
}
.block .contentTable{
    .block1{
        font-size: 14px;
        font-weight: 400;
        .title{
            margin-bottom: 11px;
        }
    }
    .block2{
        margin-left: -40px;
    }
}


</style>
