<template>
  <TableLayout :withBreadcrumb="false">
    <!-- 表格和分页 -->
    <template v-slot:search-form>
      <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="80px" inline>
        <el-form-item label="项目状态" prop="name">
          <el-select v-model="searchForm.status" clearable  placeholder="请选择" @change="search">
            <!-- <el-option  :key="[0,2,15]" label="全部" :value="[0,2,15]"> </el-option> -->
            <el-option  :key="1" label="预启动" :value="[0]"> </el-option>
            <el-option  :key="2" label="进行中" :value="[1]"> </el-option>
            <el-option  :key="3" label="已暂停" :value="[2]"> </el-option>
            <el-option  :key="4" label="已完成" :value="[3]"> </el-option>
            <el-option  :key="5" label="已取消" :value="[4]"> </el-option>
            <el-option  v-permissions="['business:project:delete']"  :key="6" label="已删除" :value="[17]"> </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </template>
    <template v-slot:table-wrap>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        row-key="id"
        stripe
        border
        default-expand-all
      >
        <!-- <el-table-column type="selection" fixed="left" width="55"></el-table-column> -->
        <el-table-column prop="name" label="项目名称" fixed="left" min-width="200px" align="center">
          <template slot-scope="{row}" >
            <el-button type="text" @click="todetail(row)">{{row.projectName}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="projectTypeName" label="项目类型" fixed="left" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="projectNo" label="编号" min-width="100px" align="center">
          <template slot-scope="{row}">
            <div>{{row.projectNo?row.projectNo:'-'}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="directorName" label="负责人" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="planDuration" label="计划时间" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="projectStatusName" label="项目状态" min-width="100px" align="center"></el-table-column>

      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination>
    </template>
  </TableLayout>
</template>

<script>
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import { mapState, mapMutations } from 'vuex'
import { fetchTree } from '@/api/system/department'
import BaseTable from '@/components/base/BaseTable'
// import OperaDepartmentWindow from '@/components/system/department/OperaDepartmentWindow'
// import DepartmentUserWindow from '@/components/system/department/DepartmentUserWindow'
// import CompanySelect from '../../components/common/CompanySelect.vue'
export default {
  name: '',
  extends: BaseTable,
  components: {  TableLayout , Pagination  },
  computed:{
     ...mapState({
      setting: state=>state.setting
    }),
  },
  data(){
    return{
      active:0,
      checkList:[],
      // 搜索
      time:'',
      searchForm: {
        type:1,
        status:[0,1,2,3,4,17],
      },
    }
  },
  watch:{
    checkList:{
      handler(val){
        let checkedList = this.itemOption[this.active].status.filter(i=>i.checked)
        console.log(checkedList,'watch,checklist');
      }
    }
  },
  methods: {
  
    //项目详情
    todetail(row, column, event){
      console.log(row);
      this.$router.push({
        path:'/detail',
        query:{
          pid:row.id
        }
      })
    },
  },
  created () {
    this.config({
      module: '项目',
      api: '/bussiness/index'
    })
    this.search()
  }
}
</script>

<style scoped lang="scss">
.table-layout {
  /deep/ .table-content {
    margin-top: 0;
  }
}
</style>
