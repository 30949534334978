<template>
  <TableLayout :withBreadcrumb="false">
    <!-- 表格和分页 -->
    <template v-slot:search-form>
      <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="80px" inline>
        <el-form-item label="问题状态" prop="name">
          <el-select v-model="searchForm.status" clearable placeholder="请选择" @change="search">
            <!-- <el-option  :key="0" label="全部" :value="[10,11]"> </el-option> -->
            <el-option  :key="1" label="未处理" :value="[10]"> </el-option>
            <el-option  :key="2" label="处理中" :value="[11]"> </el-option>
            <el-option  :key="3" label="已解决" :value="[12]"> </el-option>
            <el-option  :key="4" label="已删除" :value="[17]"> </el-option>

          </el-select>
        </el-form-item>
      </el-form>
    </template>
    <template v-slot:table-wrap>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        row-key="id"
        stripe
        border
        default-expand-all
      >
        <!-- <el-table-column type="selection" fixed="left" width="55"></el-table-column> -->
        <el-table-column prop="name" label="问题名称" fixed="left" min-width="200px" align="center">
          <template slot-scope="{row}" >
            <el-button type="text" @click="()=>{$refs.questionDetail.open('问题详情',row)}">{{row.questionName}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="projectType" label="所属项目" fixed="left" min-width="100px" align="center">
          <template slot-scope="{row}" >
            <el-button type="text" @click="todetail(row)">{{row.projectName}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="questionTypeName" label="问题类型" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="creatorName" label="创建人" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="questionStatusName" label="问题状态" min-width="100px" align="center"></el-table-column>
        <el-table-column prop="mainDeliveryName" label="解决人" min-width="100px" align="center"></el-table-column>

      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination>
    </template>
    <QuestionDetail ref='questionDetail'  @success="handlePageChange(tableData.pagination.pageIndex)"></QuestionDetail>

  </TableLayout>
</template>

<script>
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import { mapState, mapMutations } from 'vuex'
import { fetchTree } from '@/api/system/department'
import BaseTable from '@/components/base/BaseTable'
import QuestionDetail from '@/components/bussiness/projectDetail/QuestionDetail'

// import OperaDepartmentWindow from '@/components/system/department/OperaDepartmentWindow'
// import DepartmentUserWindow from '@/components/system/department/DepartmentUserWindow'
// import CompanySelect from '../../components/common/CompanySelect.vue'
export default {
  name: '',
  extends: BaseTable,
  components: {  TableLayout , Pagination , QuestionDetail  },
  computed:{
     ...mapState({
      setting: state=>state.setting
    }),
  },
  data(){
    return{
      active:0,
      checkList:[],
      // 搜索
      time:'',
      searchForm: {
        type:4,
        status:[10,11,12,17],
      },
    }
  },
  watch:{
    checkList:{
      handler(val){
        let checkedList = this.itemOption[this.active].status.filter(i=>i.checked)
        console.log(checkedList,'watch,checklist');
      }
    }
  },
  methods: {

    //项目详情
    todetail(row, column, event){
      console.log(row);
      this.$router.push({
        path:'/detail',
        query:{
          pid:row.projectId
        }
      })
    },
  },
  created () {
    this.config({
      module: '项目',
      api: '/bussiness/index'
    })
    this.search()
  }
}
</script>

<style scoped lang="scss">
.table-layout {
  /deep/ .table-content {
    margin-top: 0;
  }
}
</style>
