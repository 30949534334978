<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    v-if="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
    :withFooter='false'
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="120px" label-position="right" style="width:100%" >
      <h2>新建变更</h2>
      <el-form-item label="变更类型" prop="type" :rules="{ required: true, message: '请选择变更类型', trigger: 'blur' }">
        <el-select v-model="form.type" placeholder="请选择">
          <el-option label="项目信息变更" :value="1"></el-option>
          <el-option label="负责人变更" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="项目名称" prop="name" >
        {{oldData.projectName}}
      </el-form-item>
      <el-form-item label="变更说明" prop="explains" :rules="{ required: true, message: '请输入变更说明', trigger: 'blur' }">
        <el-input v-model="form.explains" type="textarea" placeholder="请输入变更说明" :rows="3" v-trim maxlength="500"/>
      </el-form-item>

      <h1>变更内容</h1>
      <div v-if="form.type==1">
        <!-- 项目变更 -->

        <h2>基础信息</h2>

        <div class="content">
          <div class="item">
            <div class="left">变更项</div>
            <div class="middle">变更前</div>
            <div class="right">变更后</div>
          </div>
          <div class="item" >
            <div class="left">项目名称</div>
            <div class="middle">{{oldData.projectName}}</div>
            <div class="right">{{oldData.projectName}}</div>
          </div>
          <div class="item">
            <div class="left">项目级别</div>
            <div class="middle">{{oldData.projectLevelName}}</div>
            <div class="right">
              <el-select v-model="newData.projectLevelName" placeholder="请选择">
                <el-option
                  v-for="item in setting.projectLevels"
                  :key="item.id"
                  :label="item.content"
                  :value="item.content">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="item">
            <div class="left">关联公司</div>
            <div class="middle">{{oldData.companyName}}</div>
            <div class="right">
              <CompanySelect v-model="newData.companyId" placeholder="请选择用户关联子公司" :inline="false" clearable/>
            </div>
          </div>
    
          <div class="item">
            <div class="left">计划开始</div>
            <div class="middle">{{oldData.planBegin}}</div>
            <div class="right">
              <el-date-picker style="width:100% !important" v-model="newData.planBegin" type="date"  value-format="yyyy-MM-dd" placeholder="开始日期"></el-date-picker>
              <!-- <el-input v-model="newData.explains" placeholder="请输入" v-trim maxlength="50"/> -->
            </div>
          </div>
          <div class="item">
            <div class="left">计划结束</div>
            <div class="middle">{{oldData.planEnd}}</div>
            <div class="right">
              <el-date-picker style="width:100% !important" v-model="newData.planEnd" type="date"  value-format="yyyy-MM-dd" placeholder="结束日期"></el-date-picker>
              <!-- <el-input v-model="newData.explains" placeholder="请输入" v-trim maxlength="50"/> -->
            </div>
          </div>

          <div class="item">
            <div class="left">项目说明</div>
            <div class="middle">{{oldData.explains}}</div>
            <div class="right">
              <el-input v-model="newData.explains" placeholder="请输入" v-trim maxlength="50"/>
            </div>
          </div>
          <div class="item">
            <div class="left">备注</div>
            <div class="middle">{{oldData.remark}}</div>
            <div class="right">
              <el-input v-model="newData.remark" placeholder="请输入" v-trim maxlength="50"/>
            </div>
          </div>
        </div>

        <h2>项目预算</h2>
        <div class="content">
          <div class="item">
            <div class="left">变更项</div>
            <div class="middle">变更前</div>
            <div class="right">变更后</div>
          </div>
          <div class="item" v-for="(item ,index) in oldData.expenditure">
            <div class="left">{{item.name}}</div>
            <div class="middle">{{item.plan}}</div>
            <div class="right">
              <!-- :min="newData.actualExpenditures[index].plan" -->
              <el-input-number  controls-position="right" v-model="newData.expenditure[index].plan" :min="newData.expenditure[index].actual" placeholder="请输入预算值" ></el-input-number>
               <!-- <el-input type="number" v-model.trim="newData.expenditure[index].plan" placeholder="请输入"   v-trim maxlength="50"/> -->
              </div>
          </div>
        </div>
        <h2>附件信息</h2>
         <FileUpload v-model='newData.enclosures'></FileUpload>
          <FileUploadTable :data='newData.enclosures' @delete="(e)=>{this.newData.enclosures.splice(e.index,1)}"></FileUploadTable>
      </div>
      <div v-else>

        <div class="content">
          <div class="item">
            <div class="left">变更项</div>
            <div class="middle">变更前</div>
            <div class="right">变更后</div>
          </div>
          <div class="item" >
            <div class="left">项目负责人</div>
            <div class="middle">{{oldData.directorName}}</div>
            <div class="right">
              <LeaderSelect v-model="newData.directorId"  @row="getLeaderInfo"></LeaderSelect>
              <!-- <el-input type="number" v-model.trim="newData.expenditure[index].plan" placeholder="请输入"   v-trim maxlength="50"/> -->
            </div>
          </div>
        </div>
      </div>
      

      <section>
        <el-button type="primary" @click="confirm">提交</el-button>
        
      </section>
     
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import CompanySelect from '../../common/CompanySelect'
import LeaderSelect from '../../common/LeaderSelectProject'
import FileUpload from '../../common/FileUpload'
import FileUploadTable from '../../common/FileUploadTable'


import{projectDetailQuery , createModify} from '@/api/bussiness/project'
// import{projectDetailQuery} from '@/api/bussiness/project'

import { mapState, mapMutations } from 'vuex'

export default {
  name: 'OperaRoleWindow',
  extends: BaseOpera,
  components: { GlobalWindow,LeaderSelect , CompanySelect , FileUploadTable , FileUpload},
  computed:{
   
     ...mapState({
      setting: state=>state.setting
    }),
    

  },
  watch:{
    'setting':{
      handler(val){
        console.log(val,'--ala-',this.setting);
        if(val.tableShow.estTtExpendAndType){
          console.log(val.expenditureTypes,'-----支出类型');
          if(val.expenditureTypes){
            val.expenditureTypes.map(item=>{
              this.form.expenditures.push({
                type:item.id,
                label:item.content,
                plan:null,
              })
            })
          }
          
          // 
        }
        
        console.log(this.form.expenditures,'------数据变换');
      },
      deep:true,
      immediate:true
    },
    form:{
      handler(val,old){
        
        let count = 0;
        if(this.form.expenditures){
          this.form.expenditures.map(i=>{
            count = count+Number(i.plan)
          })
        }
        
        this.form.estimateExpenditure = count

        if(val.projectLevelName!=old.projectLevelName){
          console.log('0a0s0a0s',this.setting.projectLevels);
          console.log('0a0s0a0s------',val.projectLevelName);
        }
        
      },
      deep:true
    },
    'newData.projectLevelName':{
      handler(val,old){
        if(val!=old){
          let index = this.setting.projectLevels.findIndex(i=>i.content==val)
          this.newData.projectLevel = this.setting.projectLevels[index].id
        }
      },

    },

  },
  data () {
    return {
      oldData:null,
      newData:null,
      dateArray:[],
      // 表单数据
      form: {
        baseInfos: [ //基本信息变更
          {
            after: "",
            before: "",
            itemId: 0,
            itemName: ""
          }
        ],
        directors: [ //负责人变更
          {
            after: "asa",
            before: "牟谋星",
            itemId: 0,
            itemName: "项目负责人"
          }
        ],
        expenditures: [ //项目预算变更
          {
            after: "",
            before: "",
            itemId: 0,
            itemName: ""
          }
        ],
        explains: "",
        ossPath: "", //变更附件
        projectId: 0,
        type: 1
      },
      // 验证规则
      rules: {
        code: [
          { required: true, message: '请输入角色编码' }
        ],
        name: [
          { required: true, message: '请输入角色名称' }
        ]
      }
    }
  },
  methods: {
    open (title, target) {
      this.title = title
      this.visible = true

      projectDetailQuery({projectId:target.id}).then(res=>{
        console.log(res,'变更前');
        this.oldData = res
        this.newData = JSON.parse(JSON.stringify(res)) 
        this.newData.enclosures = []
      }).catch(e=>{
        this.$tip.apiFailed(e)
      })
    },
    confirm () {
      let baseInfos = []
      let expenditures = []
      let directors = []
      if(this.form.type==1){
        
        Object.keys(this.oldData).map(item=>{
          if(this.oldData[item]!=null&&item!='expenditure'){
            // &&typeof this.oldData[item]=='object'
            // ,'planBegin','planEnd','planDays','planBegin','planEnd','planDays',
            let useData = ['projectName','projectLevel','projectLevelName','companyId','planBegin','planEnd','explains','remark']
            let showData = ['项目名称','项目级别编号','项目级别','关联公司','计划开始时间','计划结束时间','项目说明','备注']
            let index = useData.findIndex(i=>i==item)
            console.log(this.newData,'-sdsdhwu');
            if(index!=-1){ 
              baseInfos.push({
                after:this.newData[item],
                before:this.oldData[item],
                itemId:item,
                itemName:showData[index]
              })
            }
          }
          if(item=='expenditure'){
            this.oldData['expenditure'].map((item,index)=>{
              console.log('预算',item);
              expenditures.push({
                after:this.newData['expenditure'][index].plan,
                before:item.plan,
                itemId:item.type,
                itemName:item.name
              })
            })
          }
        })
      }else{
        directors = [{
          after:this.newData["directorId"],
          before:this.oldData["directorId"],
          itemId:'directorId',
          itemName:'项目负责人'
        }]
        
      }

      // console.log(baseInfos,'------基础信息');
      // console.log(expenditures,'--------支出信息');
      // console.log(directors,'----人员biang');
 
       this.$dialog.confirm('确定变更当前信息？', '提示', {
          confirmButtonText: '确认变更',
          type: 'warning'
        })
          .then(() => {
        createModify({
          baseInfos:baseInfos,
          directors:directors,
          expenditures:expenditures,
          explains: this.form.explains,
          type: this.form.type,
          projectId:this.$route.query.pid,
          enclosures:this.newData.enclosures

        }).then(res=>{
              this.$tip.apiSuccess('修改成功')
              this.$emit('success')
            }).catch(e=>{
          this.$tip.apiFailed(e)
        }).finally(()=>{
          this.visible = false
        })
          })
    }
  },
  created () {
    // this.config({
    //   api: '/system/role'
    // })
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-form-item{
  display: flex;
}
/deep/ .el-form-item__content{
  margin-left: 0 !important;
  flex: 1;
}
h2{
  font-size: 14px;
  font-family:'Microsoft YaHei';
  font-weight: 400;
  color: #333333;
  padding-bottom: 12px;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 12px;
  margin-top: 12px;
  font-weight: bold;
}
h1{
  font-size: 16px;
  font-family:'Microsoft YaHei';
  font-weight: 400;
  color: #333333;
  padding-bottom: 12px;
  margin-bottom: 12px;
}

.content{
  .item{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    border: 1px solid #ccc;
    border-top: 0;

    &:first-child{
    border: 1px solid #ccc;
    }
    .left{
      flex-shrink: 0;
      flex: 1;
      padding: 20px 0;
      width:calc(100% / 3);
      
      border-right: 1px solid #ccc;
    }
    .middle{
      flex-shrink: 0;
      flex:1;
      width:calc(100% / 3);

      padding: 20px 0;
      border-right: 1px solid #ccc;
    }
    .right{
      width:calc(100% / 3);
      flex-shrink: 0;
    }
  }
}
</style>