<template>
  <div class="page">
    <div class="subs">
      <span @click="()=>{this.$router.push('/bussiness/project')}">项目中心</span><span>/{{base.projectName}}</span>
    </div>
    <div class="detailTitle">
      <div class="info">
        <div class="top">
          <div>项目编号: {{base.projectNo}}</div>
          <div>项目名称:{{base.projectName}}</div>
          <div>状态：<span class="status">{{base.projectStatusName}}</span></div>
        </div>
        <div class="bottom">
          <div class="left">
            <img class="avator" :src="base.directorAvatar" alt="">
            <div class="other">
              <span class="name">{{base.directorName}}</span>
              <!-- <span class="dept">正常</span> -->
            </div>
          </div>
          <div class="right">
            <div>计划工期：{{base.planDuration}}</div>
            <div>实际工期：{{base.actualDuration}}</div>
          </div>
          <div style="margin-left:20px" >
            <div v-if="!selectEdit">
              <span v-if="base.projectSpeedStatus==1" style="color:#67C23A">正常</span>
              <span v-if="base.projectSpeedStatus==2" style="color:#E6A23C">预警</span>
              <span v-if="base.projectSpeedStatus==3" style="color:#F56C6C">异常</span>
              <span v-if="base.projectSpeedStatus==4" style="color:#CCCCCC">停滞</span>

              <i  class="el-icon-edit" v-if="base.editInfo" style="font-size:18px;margin-left:20px;cursor:pointer" @click="selectEdit=true"></i> 
            </div>
            <div v-if="selectEdit">
              <el-select v-model="base.projectSpeedStatus" placeholder="请选择" @change="updateSingleInfo">
                <el-option :key="1" label="正常" :value="1"></el-option>
                <el-option :key="2" label="预警" :value="2"></el-option>
                <el-option :key="3" label="异常" :value="3"></el-option>
                <el-option :key="4" label="停滞" :value="4"></el-option>
              </el-select>
            </div>
          </div>
        </div>

      </div>
      <div class="btns" >
        
        <div  style="display:inline-block;">
          <el-button style="margin-right:10px"  size='mini' @click="$router.push({path:'/gantE',query:{ pid:$route.query.pid}})">查看甘特图</el-button>
          <el-button v-if="base.operates&&base.operates.findIndex(i=>i==7)!=-1" @click="$refs.addProject.open('编辑项目',{id:base.id})" >编辑</el-button>
          <el-button v-if="base.operates&&base.operates.findIndex(i=>i==1)!=-1" size='mini' type="primary" @click="updateSpeedAndStatus(1,'执行成功')">执行</el-button>
          <el-button v-if="base.operates&&base.operates.findIndex(i=>i==0)!=-1" size='mini' type="primary" @click="$refs.showSpeed.open('自评进度',base.projectSpeed)"  >自评进度</el-button>
          <el-button v-if="base.operates&&base.operates.findIndex(i=>i==2)!=-1" size='mini' type="primary" @click="updateSpeedAndStatus(2,'暂停成功')">暂停</el-button>
          <el-button v-if="base.operates&&base.operates.findIndex(i=>i==4)!=-1" size='mini' type="primary" @click="updateSpeedAndStatus(3,'项目已完成')">完成</el-button>
          <el-button v-if="base.operates&&base.operates.findIndex(i=>i==5)!=-1" size='mini' type="primary" @click="$refs.addRecord.open('变更',{id:base.id})" >变更</el-button>
          <el-button v-if="base.operates&&base.operates.findIndex(i=>i==3)!=-1" size='mini' type="primary" @click="updateSpeedAndStatus(1,'重启成功')">重新启动</el-button>
          <el-button v-if="base.operates&&base.operates.findIndex(i=>i==6)!=-1" size='mini' type="danger" plain @click="updateSpeedAndStatus(4,'取消成功')">取消</el-button>
          <div style="display:inline;"  v-permissions="['business:project:delete']">
            <el-button  v-if="base.operates.findIndex(i=>i==9)!=-1" size='mini' type="danger" @click="deleteById">删除</el-button>
          </div>
          
        </div>
        
        


        <!-- <el-button v-buttons="[...base.operates,7]" size='mini' type="primary" @click="$refs.addProject.open('编辑项目',{id:base.id})" >编辑</el-button>
        <el-button v-buttons="[...base.operates,1]" size='mini' type="primary" @click="updateSpeedAndStatus(1,'执行成功')">执行</el-button>
        <el-button v-buttons="[...base.operates,0]" size='mini' type="primary" @click="$refs.showSpeed.open('自评进度',base.projectSpeed)"  >自评进度</el-button>
        <el-button v-buttons="[...base.operates,2]" size='mini' type="primary" @click="updateSpeedAndStatus(2,'暂停成功')">暂停</el-button>
        <el-button v-buttons="[...base.operates,4]" size='mini' type="primary" @click="updateSpeedAndStatus(3,'项目已完成')">完成</el-button>
        <el-button v-buttons="[...base.operates,5]" size='mini' type="primary" @click="$refs.addRecord.open('变更',{id:base.id})" >变更</el-button>
        <el-button v-buttons="[...base.operates,6]" size='mini' type="danger" plain @click="updateSpeedAndStatus(4,'取消成功')">取消</el-button>
        <el-button v-buttons="[...base.operates,3]" size='mini' type="primary" @click="updateSpeedAndStatus(1,'重启成功')">重新启动</el-button>
        <el-button v-buttons="[...base.operates,9]" size='mini' type="danger" @click="deleteById">删除</el-button> -->

      </div>
    </div>

    <div >
      <el-tabs  v-model="activeTab" @tab-click="tabClick" >
        <el-tab-pane label="综合信息" name="0">
          <SumInfo :data="base" @updateInfo="refresh" v-if="activeTab==0"></SumInfo>
        </el-tab-pane>
        <el-tab-pane label="人员" name="1">
          <PersonList :projectBtns="projectBtns"  v-if="activeTab==1"></PersonList>
        </el-tab-pane>
        <el-tab-pane label="进度" name="2">
          <Schedule :projectBtns="projectBtns" v-if="activeTab==2"></Schedule>
        </el-tab-pane>
        <el-tab-pane label="支出" name="3">
          <Spending :projectBtns="projectBtns" v-if="activeTab==3"></Spending>
        </el-tab-pane>
        <el-tab-pane label="变更" name="4">
          <ChangeRecords  v-if="activeTab==4"></ChangeRecords>
        </el-tab-pane>
        <el-tab-pane label="周报" name="5">
          <WeekLogs :projectBtns="projectBtns" v-if="activeTab==5"></WeekLogs>
        </el-tab-pane>
        <el-tab-pane label="问题" name="6">
          <Questions :projectBtns="projectBtns" v-if="activeTab==6" @refresh="refresh"></Questions>
        </el-tab-pane>
        <el-tab-pane label="资料" name="7">
          <ProjectData :projectBtns="projectBtns" v-if="activeTab==7"></ProjectData>
        </el-tab-pane>
        <el-tab-pane label="纪要" name="8">
          <Summary :projectBtns="projectBtns" v-if="activeTab==8"></Summary>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 变更 -->
    <AddRecord ref='addRecord' @success="btnChange"></AddRecord>
    <!-- 编辑项目 -->
    <AddProject   ref='addProject' @success="btnChange"></AddProject>
    <ShowSpeed  :speed="base.projectSpeed" :id="base.id" ref='showSpeed' @success="btnChange"></ShowSpeed>
 
  </div>
</template>

<script>
import SumInfo from '../../components/bussiness/project/SumInfo';
import PersonList from '../../components/bussiness/project/PersonList';

import Schedule from '../../components/bussiness/project/Schedule';
import Spending from '../../components/bussiness/project/Spending';
import ChangeRecords from '../../components/bussiness/project/ChangeRecords';
import WeekLogs from '../../components/bussiness/project/WeekLogs';
import Questions from '../../components/bussiness/project/Questions';
import ProjectData from '../../components/bussiness/project/ProjectData';
import Summary from '../../components/bussiness/project/Summary.vue'
import AddRecord from '@/components/bussiness/projectAddModal/AddRecord.vue';
import AddProject from '@/components/bussiness/projectAddModal/AddProject'

import ShowSpeed from '@/components/bussiness/projectAddModal/ShowSpeed'


import {projectDetailShow , updateSpeedAndStatus , deleteById} from '@/api/bussiness/project'
import {updateSingleInfo} from '../../api/common'

export default {
  name: '',
  components: {SumInfo ,Summary, PersonList , Schedule , Spending , ChangeRecords , WeekLogs , Questions , ProjectData , AddRecord , AddProject , ShowSpeed},
  data(){
     return{
       selectEdit:false,//是否选择
      active:0,
      activeTab:'0',//默认值tab
      checkList:[],
      itemOption:[
        {name:'项目',activated:true,status:[
            {name:'已超期',checked:true},
            {name:'预启动',checked:false},
            {name:'已暂停',checked:false},
          ]},
          {name:'任务',activated:false,status:[
            {name:'未发布',checked:true},
            {name:'待启动',checked:false},
            {name:'暂停中',checked:false},
            {name:'已超期',checked:false},
          ]},
          {name:'里程碑',activated:false,status:[
            {name:'已超期',checked:true},
          ]},
          {name:'问题',activated:false,status:[
            {name:'未处理',checked:true},
            {name:'处理中',checked:false},
          ]},
      ],
      // 搜索
      searchForm: {
        name: '',
        keywords:'',

      },
      base:null,
      form:{ //自评进度
        speed:null,
      },
      projectBtns:true,
 
    }
  },
  watch:{
    checkList:{
      handler(val){
        let checkedList = this.itemOption[this.active].status.filter(i=>i.checked)
        console.log(checkedList,'watch,checklist');
      }
    },
  },
  methods: {
    //修改状态
    updateSingleInfo(value){
      updateSingleInfo({
        id:this.$route.query.pid,
        projectSpeedStatus:value
      }).then(res=>{
        res && this.$message.success('状态修改成功')
      }).finally(()=>{
        this.selectEdit = false
      })
    },
    tabClick(tab, event){
      console.log(tab.name,'pppppppppppp----');
      this.activeTab = String(tab.name)
    },

    changeButtons(item,index){
      this.itemOption[this.active].activated = false
      this.itemOption[index].activated = true
      this.active = index
      this.checkList = []
    },
    handleCheckedStatus(value){
      console.log(value,'checked change status ');
      this.itemOption[this.active].status.map((item,index)=>{
        console.log();
        if(value.findIndex(item.name)>-1){
          item.checked = true
        }else{
          item.checked = false
        }
      })
    },
    showDetail(id){
      projectDetailShow({projectId:id}).then(res=>{
        console.log('项目详情',res);
        
        this.projectBtns = res.projectStatus!=null?((res.projectStatus==3 || res.projectStatus==4 ||res.projectStatus==17)?false:true):false
        // this.$nextTick(()=>{
        // this.base = JSON.parse(JSON.stringify(res))

        // })
        this.base = res
      }).catch(e=>{
        this.$tip.apiFailed(e)
      })
    },
    updateSpeedAndStatus(status,message="修改成功"){ //改变项目状态
    if(status){
      this.$dialog.confirm('是否进行项目状态变更？', '提示', {
        confirmButtonText: '确认',
        type: 'warning'
      }).then(() => {
          updateSpeedAndStatus({
        id:this.base.id,
        status:status
      }).then(res=>{
            this.$tip.apiSuccess(message)
            // this.base.operates = []
            this.showDetail(this.base.id)
            
            this.btnChange()
          }).catch(e=>{
            this.$tip.apiFailed(e)
          })
        })
        return ;
    }
      
    },
    btnChange(){
      let tab = this.activeTab
      console.log(this.activeTab,'-----');
      this.activeTab = ''
      this.$nextTick(()=>{
        this.activeTab = String(tab)
      })
      if(this.activeTab==0){
        this.showDetail(this.base.id)
      }
      
    },
    // deleteById(){
    //   this.$router.go(-1)
    // },
    deleteById(){
      this.$dialog.confirm('确定删除当前的项目吗？', '提示', {
        confirmButtonText: '删除',
        type: 'error'
      }).then(()=>{
        deleteById({projectId:this.base.id}).then(res=>{
          this.$router.go(-1)
        }).catch(e=>{
            this.$tip.apiFailed(e)
        })
      })
      
    },
    //问题新建、修改 触发show,
    refresh(){
      this.showDetail(this.base.id)
    }
  },
  created () {
    let pid = this.$route.query.pid;
    if(pid){
      this.showDetail(pid);
    }
  }
}
</script>

<style scoped lang="scss">
/deep/.el-tabs__nav-wrap::after{
  background: none;
}
/deep/ .el-tabs__item{
font-size: 18px;
font-family: 'Microsoft YaHei';
font-weight: bold;
}
.el-button{
  margin-top: 4px;
}

.page{
  color: #333333;
  font-size: 16px;
  font-family: 'Microsoft YaHei';
  margin:30px 20px;
}
.detailTitle{
  display: flex;
  margin-bottom: 37px;

  .info{
    flex: 3;
    .top{
      font-weight: bold;
      display: flex;
      margin:30px 0px 35px 0;
      div{
        margin-right: 20px;
        min-width: 162px;
      }

      .status{
        background: #5470C6;
        border-radius:1.5rem;
        color: #fff;
        padding:4px 33px;
        
      }
    }
    .bottom{
      display: flex;
      align-items: center;

      .left{
        display: flex;
        align-items: center;
        margin-right: 30px;
        .avator{
          width:60px;
          height:60px;
          border-radius: 50%;
        }
        .other{
        border-right: 2px solid #CCCCCC;
        display: flex;
        flex-direction: column;
        padding-left: 12px;
        padding-right: 30px;


          .name{
            font-size: 18px;
            font-weight: bold;
          }
          .dept{
            font-size: 14px;
          }
        }

      }
      .right{
        font-size: 14px;
        line-height: 1.4rem;
        font-weight: 400;
      }
    }
  }

  .btns{
    flex: 2;
    text-align: right;
    margin-right: 20px;
  }
}

</style>
