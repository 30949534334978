<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
    :withFooter='false'
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="120px" label-position="right" style="width:100%" >
      <h2>新建里程碑</h2>
      <!-- <el-form-item label="所属项目" prop="code" required>
        一堆堆项目名称
      </el-form-item> -->
      <el-form-item label="发布者" prop="creator" >
        <!-- <el-input v-model="form.creator" placeholder="请输入" v-trim maxlength="50" disabled/> -->
        {{username}}
      </el-form-item>
      <el-form-item label="里程碑名称" prop="milepostName" :rules="{ required: true, message: '请输入里程碑名称', trigger: 'blur' }">
        <el-input v-model="form.milepostName"  placeholder="请输入里程碑名称" :rows="3" v-trim maxlength="500"/>
      </el-form-item>
      <el-form-item label="里程碑说明" prop="milepostExplain" :rules="{ required: true, message: '请输入里程碑说明', trigger: 'blur' }">
        <el-input v-model="form.milepostExplain" type="textarea" placeholder="填写里程碑说明" :rows="3" v-trim maxlength="500"/>
      </el-form-item>
      <h2>计划安排</h2>

      <el-form-item label="里程碑负责人" prop="director" :rules="{ required: true, message: '请选择里程碑负责人', trigger: 'blur' }">
        <LeaderSelect v-model="form.director" placeholder="请选择报告对象" :fromObjId="form.projectId"></LeaderSelect>
      </el-form-item>
      <el-form-item label="计划达成日期" prop="planEnd" :rules="{ required: true, message: '输入计划达成日期', trigger: 'blur' }">
        <el-date-picker style="width:100% !important" type="date" value-format="yyyy-MM-dd" :disabledDate="(time)=>{return time.getTime()>new Date(form.planEnd).getTime()}" placeholder="选择日期" v-model="form.planEnd" ></el-date-picker>
      </el-form-item>

      <h2>附件资料</h2>

        <div>
          <FileUpload v-model='form.files'></FileUpload>
          <FileUploadTable :data='form.files' @delete="(e)=>{this.form.files.splice(e.index,1)}"></FileUploadTable>
        </div>

      <section>
        <el-button type="primary" @click="confirm">发布</el-button>
        
      </section>
     
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import LeaderSelect from '../../common/LeaderSelectProject'
import FileUpload from '../../common/FileUpload'
import FileUploadTable  from '../../common/FileUploadTable'
import { queryMilepostShowById } from '@/api/bussiness/milestone'

export default {
  name: 'OperaRoleWindow',
  extends: BaseOpera,
  components: { GlobalWindow  , LeaderSelect , FileUpload ,FileUploadTable },
  data () {
    return {
      // 原角色码
      originRoleCode: '',
      username:'',
      // 表单数据
      form: {
        creator: null,
        director: null,
        files: [],
        id: null,
        milepostExplain: "",
        milepostName: "",
        planEnd: "",
        projectId: this.$route.query.pid
      },
      // 验证规则
      rules: {
        code: [
          { required: true, message: '请输入角色编码' }
        ],
        name: [
          { required: true, message: '请输入角色名称' }
        ]
      }
    }
  },
  methods: {
    open (title, target) {
      this.title = title
      this.visible = true
      // 新建
      if (target == null) {
        this.$nextTick(() => {
          // this.$refs.form.resetFields()
          this.form= {
            creator: null,
            director: null,
            files: [],
            id: null,
            milepostExplain: "",
            milepostName: "",
            planEnd: "",
            projectId: this.$route.query.pid
          }
          this.form[this.configData['field.id']] = null
          this.form.creator = this.$store.state.init.userInfo.id
          this.username = this.$store.state.init.userInfo.username
          this.form.projectId = this.$route.query.pid
        })
        return
      }
      // 编辑
      this.$nextTick(() => {
        // console.log(target,'-------');
        // for (const key in this.form) {
        //   this.form[key] = target[key]
        // }
        // this.form.creator = this.$store.state.init.userInfo.id
        this.username = this.$store.state.init.userInfo.username
        // this.form.projectId = this.$route.query.pid
        // // this.form.director = 
        // console.log(this.form,'-------');
        queryMilepostShowById({
          milepostId: target.id
        }).then(res => {
          if (res) {
            this.$nextTick(() => {
              for (const key in this.form) {
                this.form[key] = res[key]
              }
              // this.username = res.creatorName
              if (res.files) {
                res.files.map(i => {
                  i.delete = true
                })
              }
              this.form.files = res.files
            })
          }
        }).catch(e => {
          this.$tip.apiFailed(e)
        })

      })
    },
    confirm () {
      if (this.form.id == null || this.form.id === '') {
        this.__confirmCreate()
        return
      }else{
        this.__confirmEdit()
      }
    }
  },
  created () {
    this.config({
      api: '/bussiness/milestone'
    })
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-form-item{
  display: flex;
}
/deep/ .el-form-item__content{
  margin-left: 0 !important;
  flex: 1;
}
h2{
  font-size: 14px;
  font-family:'Microsoft YaHei';
  font-weight: 400;
  color: #333333;
  padding-bottom: 12px;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 12px;
}
</style>